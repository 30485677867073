const recaptchaIsReady = () => typeof window !== 'undefined' &&
    typeof window.grecaptcha !== 'undefined' &&
    typeof window.grecaptcha.enterprise.execute !== 'undefined';

const recaptchaExecute = (action: string): Promise<string|null> => {
    return new Promise((resolve, reject) => {
        window.grecaptcha.enterprise.ready(async () => {
            try {
                // Execute the recaptcha
                const token = await window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action });
                resolve(token);
            } catch (error) {
                reject(error);
            }
        });
    });
};

export {
    recaptchaIsReady,
    recaptchaExecute,
}
