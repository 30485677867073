import React, {useState} from "react";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import {useField} from 'formik';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

// TODO need to check
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ShowPasswordField = (props: any) => {

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';

    return (
        <TextField
            {...props}
            {...field}
            helperText={errorText}
            error={!!errorText}
            type={showPassword ? 'text' : 'password'}
            onBlur={props.onBlur}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {!showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    );
}

export default ShowPasswordField;
