import React, {useEffect} from 'react';
import {Container} from '@mui/material';
import {useTypedDispatch, useTypedSelector} from '../store';
import UserRole from '../components/UserRole';
import ConfirmMail from '../components/ConfirmMail';
import SignUpForm from '../components/SignUpForm';
import Footer from "../components/Footer";
import {resetRegister} from "../store/slices/registerSlice";
import {medical} from "../theme";

const SignUp: React.FC = () => {

    const {registerStep} = useTypedSelector(state => state.registerReducer)
    const dispatch = useTypedDispatch()
    useEffect(() => {
        document.title = 'Create an Account | MedcoreMD';
        dispatch(resetRegister())
    }, []);

    return (
        <>
            <Container
                maxWidth={false}
                disableGutters={true}
                sx={{
                    mt: '100px',
                    mb: '100px',
                    flex: 1,
                    [medical.breakpoints.down('md')]: {
                        mt: '50px',
                        mb: '50px',
                    },
                    [medical.breakpoints.down('sm')]: {
                        mt: '30px',
                        mb: '30px',
                    }
                }}
            >
                {registerStep === 0 ? (
                    <UserRole/>
                ) : registerStep === 1 ? (
                    <SignUpForm/>
                ) : registerStep === 2 ? (
                    <ConfirmMail/>
                ) : (
                    <UserRole/>
                )}
            </Container>
            <Footer/>
        </>
    )
};

export default SignUp;
