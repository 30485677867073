import React from 'react';
import { Stack, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

const useStyles = makeStyles((medical: Theme) => ({
    icon: () => ({
        width: '100px',
        height: '105px',
        backgroundSize: 'contain',
        position: 'relative',
        margin: '0 auto',
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: '105px',
            height: '105px',
            borderRadius: '50%',
            backgroundColor: '#e8f6fc',
            zIndex: -1,
            top: '0px',
            right: '-30px',
            left: '0px',
            margin: '0 auto',
        },
        '& img': {
            marginTop: '25px',
        },
    }),
    title: () => ({
        '&.MuiTypography-root': {
            color: medical.colors.blue,
            marginTop: '20px',
        },
    }),
    desc: () => ({
        '&.MuiTypography-root': {
            maxWidth: '325px',
            color: medical.colors.grey,
            marginTop: '10px',
        },
    }),
}));

interface MedicalItems {
    title?: string,
    desc?: string,
    icon?: string,
}

const MedicalItem: React.FC<MedicalItems> = (props: MedicalItems) => {

    const classes = useStyles()

    return (
        <Stack alignItems={'center'} textAlign={'center'} mb={'40px'}>
            <div className={classes.icon}>
                <img src={props.icon} alt="feature-icon"/>
            </div>
            <Typography
                variant="h5"
                fontWeight={900}
                className={classes.title}
            >
                {props.title}
            </Typography>
            <Typography
                variant="body1"
                className={classes.desc}>
                {props.desc}
            </Typography>
        </Stack>
    );
};

export default MedicalItem;
