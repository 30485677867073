import React, {useEffect} from 'react';
import {Container, Typography} from '@mui/material';
import {useTheme} from '@mui/styles';
const NotFound: React.FC = () => {

  const medical = useTheme()
    useEffect(() => {
        document.title = 'Not Found | MedcoreMD';
    }, []);

  return (
      <Container
        maxWidth={false}
        disableGutters={true}
        sx={{m: '270px 0 150px', textAlign: 'center'}}
      >

        <Typography variant="h1">
          Page Is Not Found!<br/>
          <span style={{color: medical.colors.blue}}>404</span>
        </Typography>

      </Container>
  );
};

export default NotFound;
