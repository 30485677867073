import {combineReducers} from 'redux';
import registerSlice from './registerSlice';
import loginSlice from './loginSlice';
import verifyEmailSlice from './verifyEmailSlice';
import passwordForgotSlice from './passwordForgotSlice';
import invitationSlice from "./invitation.slice";
import popupSlice from "./popup.slice";

export const rootReducer = combineReducers({
    loginReducer: loginSlice,
    registerReducer: registerSlice,
    verifyEmailReducer: verifyEmailSlice,
    passwordForgotReducer: passwordForgotSlice,
    invitationReducer: invitationSlice,
    popupReducer: popupSlice,
})
