import React, {useState, useRef} from "react";
import {makeStyles} from "@mui/styles";
import {Stack} from "@mui/material";
import PlayButton from "../../assets/img/play-button.png";
import logo from '../../assets/img/logo.png';
import searchBar from '../../assets/img/search-bar.png';

type VideoPlayerProps = {
    videoUrl: string;
    posterUrl?: string;
};

const useStyles = makeStyles(() => ({
    videoPresentation: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%',
        background: 'linear-gradient(191deg, rgba(255,255,255,1) 0%, rgba(241,253,255,1) 100%)',

        padding: '15px 20px',
        borderRadius: '15px',
        position: 'relative',
        boxShadow: '5px 10px 20px #e4eaeb',
        '&>div': {
            position: 'relative',
            width: '100%',
            height: '100%',
        },
        '&>div>img': {
            height: '40px',
            objectFit: 'contain',
        },
        '&>div>img:first-child': {
            width: '20%',
        },
        '&>div>img:last-child': {
            width: '75%',
            float: 'right',
            objectPosition: 'right',
        },
        '&>div:first-child': {
            marginBottom: '15px',
        },
        '&>div:last-child': {
            borderRadius: '15px',
            '&:hover': {
                '& video::-webkit-media-controls-panel': {
                    opacity: 1,
                },
            },
        },
        '& video': {
            height: '100%',
            width: '100%',
            overflow: 'clip',
            borderRadius: '15px',
            '&::-webkit-media-controls-panel': {
                background: 'transparent',
                filter: 'brightness(0) invert(1)',
                opacity: 0,
            },
        },
    },
    play: {
        position: 'absolute',
        top: '46.5%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '10px 20px',
        borderRadius: '5px',
        transition: 'all 0.05s linear',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: '14px',
        '& button': {
            cursor: 'pointer',
            outline: 'none',
            border: 'none',
            background: `url(${PlayButton}) no-repeat center center`,
            backgroundSize: 'contain',
            width: '100px',
            height: '100px',
        },
    }
}));

function VideoPlayer(props: VideoPlayerProps): JSX.Element {
    const classes = useStyles()
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    const handlePlayClick = (): void => {
        setIsPlaying(true);
        videoRef.current?.play();
    };

    return (
        <Stack className={classes.videoPresentation}>
            <div>
                <img alt="MedcoreMD" src={logo} height={40}/>
                <img alt="Search" src={searchBar} height={40}/>
            </div>
            <div>
                <video onPlay={() => setIsPlaying(true)} onPause={() => setIsPlaying(false)} controls={true}
                       poster={props.posterUrl} ref={videoRef}>
                    <source src={props.videoUrl}/>
                </video>
                {!isPlaying && (
                    <div className={classes.play}>
                    <button onClick={handlePlayClick}/>
                        HOW IT WORKS?
                    </div>
                )}
            </div>
        </Stack>
    );
}

export default VideoPlayer;
