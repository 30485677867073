import React, {useEffect} from 'react';
import './App.css';
import {BrowserRouter, Route, Routes, useLocation} from 'react-router-dom';
import HomePage from './pages/HomePage';
import RepsPage from './pages/RepsPage';
import PractitionersPage from './pages/PractitionersPage';
import ContactUsPage from './pages/ContactUsPage';
import RequestDemoPage from './pages/RequestDemoPage';
import {ThemeProvider} from '@mui/material/styles';
import {medical} from './theme';
import {CssBaseline, Hidden} from '@mui/material';
import Navbar from './components/Navbar';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import NotFound from './pages/NotFound';
import CookiePolicy from './pages/CookiePolicy'
import NavbarMobile from './components/Navbar/NavbarMobile'
import InvitationPage from "./pages/InvitationPage";
import NotificationPopup from "./components/UI/NotificationPopup";
import Recaptcha from "./components/Recaptcha";
import ReactGA from "react-ga4";
import AdditionalTerms from "./pages/AdditionalTerms";

// Only if production
if(process.env.REACT_APP_NODE_ENV === 'production') {
  ReactGA.initialize('G-GYE32WEEJ7');
}

// TODO - Move this to a separate file, refactor
const ScrollToTopAndGA = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
    window.dispatchEvent(new Event('scroll'));

    if(process.env.REACT_APP_NODE_ENV === 'production') {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: document.title })
    }
  }, [pathname]);

  return null;
}

const App: React.FC = () => {
  return (
    <ThemeProvider theme={medical}>
      <CssBaseline/>
      <Recaptcha/>
      <BrowserRouter>
        <ScrollToTopAndGA />
        <Hidden mdDown>
          <Navbar/>
        </Hidden>
        <Hidden mdUp>
          <NavbarMobile/>
        </Hidden>
        <Routes>
          <Route element={<HomePage/>} path="/"/>
          <Route element={<RepsPage/>} path="/reps"/>
          <Route element={<PractitionersPage/>} path="/practitioners"/>
          <Route element={<ContactUsPage/>} path="/contact"/>
          <Route element={<RequestDemoPage/>} path="/demo"/>
          <Route element={<SignIn/>} path="/sign-in"/>
          <Route element={<SignUp/>} path="/sign-up"/>
          <Route element={<InvitationPage/>} path="/sign-up/invitation/:id"/>
          <Route element={<ForgotPassword/>} path="/forgot-password"/>
          <Route element={<ResetPassword/>} path="/reset-pass/:token"/>
          <Route element={<TermsOfService/>} path="/terms-and-conditions"/>
          <Route element={<AdditionalTerms/>} path="/additional-terms-for-subscribers"/>
          <Route element={<PrivacyPolicy/>} path="/privacy-policy"/>
          <Route element={<CookiePolicy/>} path="/cookie-policy"/>
          <Route element={<NotFound/>} path="*"/>
        </Routes>
        <NotificationPopup/>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
