import React, {useEffect} from 'react'
import {Container, Grid, Typography} from '@mui/material'
import MedicalItem from '../components/MedicalItem'
import Footer from '../components/Footer'
import HeadSection from '../components/HeadSection'
import {useTheme} from '@mui/styles'
import FeaturesSection from '../components/FeaturesSection'
import {FeaturesData, StepsData} from '../data'
import headImg from '../assets/img/header-mr-page.png'
import SignUpSection from '../components/Footer/SignUpSection'
import mobileFeature from "../assets/img/mobile-feature-mr.jpg";

const RepsPage: React.FC = () => {
    const medical = useTheme()
    useEffect(() => {
        document.title = 'Medical Reps | MedcoreMD';
    }, []);

    return (
        <>
            <Container maxWidth={false} disableGutters={true}>
                <HeadSection
                    subtitle="QUALIFIED MEETINGS"
                    title="Every Time"
                    desc="Promote your products directly to practices that have expressed interest in what you have to offer with Medcore MD."
                    descBold="Less time, more value."
                    img={headImg}
                    contentMaxWidth={'550px'}
                />
                <Container maxWidth="xl" sx={{
                    mt: '70px',
                    [medical.breakpoints.down('md')]: {
                        mt: '30px',
                    }
                }}>
                    <Grid item xl={12}>
                        <Typography variant="h2">
                            WORK SMARTER, NOT HARDER
                        </Typography>
                        <Typography variant="subtitle1" mt={'10px'}>Modernize your workflow, improve your communication capabilities, and make quicker
                            connections in four simple steps:</Typography>
                    </Grid>
                    <Grid container
                          justifyContent="space-between"
                          columnSpacing={10}
                          mt="75px"
                          mb={{
                                xs: '50px',
                                md: '120px'
                          }}
                          sx={{
                              [medical.breakpoints.down('md')]: {
                                  flexDirection: 'column'
                              },
                          }}
                    >
                        {StepsData.medicalReps.map((item, index) => (
                            <Grid key={index} item lg={3}>
                                <MedicalItem
                                    icon={item.icon}
                                    title={item.title}
                                    desc={item.desc}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Container>
                <FeaturesSection
                    reps={true}
                    subTitle="Medcore MD"
                    title="KEY PLATFORM FEATURES"
                    desc="Save time, optimize resources and increase your engagements with clinical practices. Communicate easily and effectively using video, message, and phone through one platform."
                    mobileFeature={mobileFeature}
                    data={FeaturesData.medicalReps}
                />
            </Container>
            <SignUpSection/>
            <Footer/>
        </>
    )
}

export default RepsPage
