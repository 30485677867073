import React, {useEffect} from 'react'
import {Container, Grid, Stack, Theme, Typography} from '@mui/material'
import {Link} from 'react-router-dom'
import {Form, Formik} from 'formik'
import {makeStyles, useTheme} from '@mui/styles'
import {validationSchemaContact} from '../helpers/validation'
import TextFieldForm from '../components/UI/TextFieldForm'
import CheckBoxForm from '../components/UI/CheckboxForm'
import axios from 'axios'
import {API_URL} from '../services/API'
import {endpoints} from '../services/API/endpoints'
import LoadingButton from '@mui/lab/LoadingButton'
import Footer from '../components/Footer'
import {recaptchaExecute, recaptchaIsReady} from "../helpers/recaptcha";
import {openPopup} from "../store/slices/popup.slice";
import {useTypedDispatch} from "../store";

const useStyles = makeStyles((medical: Theme) => ({
    fullInput: {
        '&.MuiFormControl-root': {
            width: '100%',
        },
    },
    fileInput: {
        '&.MuiTextField-root': {
            cursor: 'pointer',
            width: '100%',
            '& label': {
                fontSize: '18px',
                fontWeight: 400,
                color: '#93A1B0',
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: medical.colors.lightGrey,
                    borderWidth: '1px',
                    borderStyle: 'dashed',
                },
                '&.Mui-focused fieldset': {
                    borderColor: medical.colors.lightGrey,
                },
            },
        },
    },
}))

const ContactUsPage: React.FC = () => {
    const classes = useStyles()
    const medical = useTheme()
    const dispatch = useTypedDispatch();

    useEffect(() => {
        document.title = 'Contact Us | MedcoreMD';
    }, []);

    return (
        <>
            <Container
                maxWidth={false} disableGutters={true}
                sx={{
                    mt: '100px',
                    mb: '100px',
                    flex: 1,
                    [medical.breakpoints.down('md')]: {
                        mt: '50px',
                        mb: '50px',
                    },
                    [medical.breakpoints.down('sm')]: {
                        mt: '30px',
                        mb: '30px',
                    }
                }}
            >
                <Container maxWidth="xl">
                    <Grid container justifyContent="space-between" spacing={6}>
                        <Grid item xl={5}>
                            <Typography variant="h3">
                                Tell us what&#39;s on your mind
                            </Typography>
                            <Typography variant="h1">
                                Let&#39;s Connect
                                <span style={{color: medical.colors.blue}}>.</span>
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                color={medical.colors.grey}
                                fontWeight="300"
                                mt="20px"
                            >
                                Reach out anytime with questions or comments and we&#39;ll get
                                back to you ASAP.
                                <br/>
                                Existing Medcore MD members - be sure to
                                <Link to="/sign-in" style={{color: medical.colors.blue}}>
                                    {' '}
                                    sign in{' '}
                                </Link>
                                before submitting your inquiry.
                            </Typography>
                        </Grid>
                        <Grid item xl={6.3}>
                            <Formik
                                validateOnBlur={true}
                                initialValues={{
                                    first_name: '',
                                    last_name: '',
                                    job_title: '',
                                    company_name: '',
                                    email: '',
                                    phone: '',
                                    state: '',
                                    zip: '',
                                    file: null as unknown,
                                    message: '',
                                    conditions: false,
                                }}
                                validationSchema={validationSchemaContact}
                                onSubmit={async (
                                    values,
                                    {setSubmitting, resetForm}
                                ) => {
                                    setSubmitting(true)
                                    try {
                                        if (!recaptchaIsReady()) {
                                            dispatch(openPopup({
                                                message: 'reCAPTCHA is not ready yet. Please try again later.',
                                                type: 'error',
                                            }))
                                            return;
                                        }

                                        const recaptcha_token = await recaptchaExecute('CONTACT_US');
                                        if(!recaptcha_token) {
                                            dispatch(openPopup({
                                                message: 'reCaptcha token is not valid. Please try again later.',
                                                type: 'error',
                                            }))
                                            return;
                                        }

                                        const response = await axios.post(
                                            API_URL + endpoints.contactUs,
                                            {
                                                ...values,
                                                recaptcha_token,
                                            }
                                        )

                                        if(!response.data.success) {
                                            dispatch(openPopup({
                                                message: response?.data?.message,
                                                type: 'error',
                                            }))
                                            return;
                                        }

                                        if (response.data.success) {
                                            dispatch(openPopup({
                                                message: 'Your message was successfully sent. We will get back to you as soon as possible! Thanks!',
                                                type: 'sent',
                                            }))
                                            resetForm()
                                        }
                                    } catch (error) {
                                        dispatch(openPopup({
                                            message: error,
                                            type: 'error',
                                        }))
                                    }
                                    finally {
                                        setSubmitting(false)
                                    }
                                }}
                            >
                                {({
                                      isSubmitting,
                                      handleBlur,
                                      handleChange,
                                  }) => (
                                    <Form>
                                        <Grid container rowSpacing={6} columnSpacing={2}>
                                            <Grid item xl={6} lg={4} md={4} sm={6} xs={12}>
                                                <TextFieldForm
                                                    name="first_name"
                                                    label="FIRST NAME"
                                                    variant="standard"
                                                    className={classes.fullInput}
                                                />
                                            </Grid>
                                            <Grid item xl={6} lg={4} md={4} sm={6} xs={12}>
                                                <TextFieldForm
                                                    name="last_name"
                                                    label="LAST NAME"
                                                    variant="standard"
                                                    className={classes.fullInput}
                                                />
                                            </Grid>
                                            <Grid item xl={6} lg={4} md={4} sm={6} xs={12}>
                                                <TextFieldForm
                                                    name="job_title"
                                                    label="JOB TITLE"
                                                    variant="standard"
                                                    className={classes.fullInput}
                                                />
                                            </Grid>
                                            <Grid item xl={6} lg={4} md={4} sm={6} xs={12}>
                                                <TextFieldForm
                                                    name="company_name"
                                                    label="COMPANY NAME"
                                                    variant="standard"
                                                    className={classes.fullInput}
                                                />
                                            </Grid>
                                            <Grid item xl={6} lg={4} md={4} sm={6} xs={12}>
                                                <TextFieldForm
                                                    name="email"
                                                    label="EMAIL"
                                                    variant="standard"
                                                    className={classes.fullInput}
                                                    onFocus={handleBlur}
                                                />
                                            </Grid>
                                            <Grid item xl={6} lg={4} md={4} sm={6} xs={12}>
                                                <TextFieldForm
                                                    name="phone"
                                                    label="PHONE"
                                                    type="tel"
                                                    variant="standard"
                                                    className={classes.fullInput}
                                                    onKeyPress={(
                                                        event: React.KeyboardEvent<HTMLInputElement>
                                                    ) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault()
                                                        }
                                                    }}
                                                    onPaste={(
                                                        event: React.KeyboardEvent<HTMLInputElement>
                                                    ) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault()
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xl={6} lg={4} md={4} sm={6} xs={12}>
                                                <TextFieldForm
                                                    name="state"
                                                    label="STATE"
                                                    variant="standard"
                                                    className={classes.fullInput}
                                                />
                                            </Grid>
                                            <Grid item xl={6} lg={4} md={4} sm={6} xs={12}>
                                                <TextFieldForm
                                                    name="zip"
                                                    label="ZIP"
                                                    variant="standard"
                                                    className={classes.fullInput}
                                                    inputProps={{
                                                        maxLength: 5,
                                                    }}
                                                    onKeyPress={(
                                                        event: React.KeyboardEvent<HTMLInputElement>
                                                    ) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault()
                                                        }
                                                    }}
                                                    onPaste={(
                                                        event: React.KeyboardEvent<HTMLInputElement>
                                                    ) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault()
                                                        }
                                                    }}
                                                />
                                            </Grid>


                                            <Grid item xl={12} lg={8} md={8} sm={6} xs={12}>
                                                <TextFieldForm
                                                    name="message"
                                                    label="MESSAGE"
                                                    variant="standard"
                                                    multiline
                                                    rows={3}
                                                    sx={{pt: '10px'}}
                                                    className={classes.fullInput}
                                                    inputProps={{
                                                        maxLength: 1000,
                                                    }}
                                                />
                                            </Grid>
                                            <Stack
                                                direction="column"
                                                alignItems="start"
                                                ml="15px"
                                                mt="30px"
                                                width="100%"
                                            >
                                                <CheckBoxForm
                                                    name="conditions"
                                                    onChange={handleChange}
                                                    label={
                                                        <Typography
                                                            variant="body2"
                                                            textAlign="left"
                                                            color={medical.colors.lightGrey}
                                                        >
                                                            I agree to MedCore MD&apos;s
                                                            {' '}
                                                            <Typography
                                                                variant="body2"
                                                                component={Link}
                                                                to="/terms-and-conditions"
                                                                color={medical.colors.blue}
                                                            >
                                                                Terms of Service
                                                            </Typography>
                                                            ,
                                                            <Typography
                                                                variant="body2"
                                                                component={Link}
                                                                to="/additional-terms-for-subscribers"
                                                                color={medical.colors.blue}
                                                            >
                                                                {' '}
                                                                Additional Terms for Subscribers
                                                            </Typography>{' '},
                                                            <Typography
                                                                variant="body2"
                                                                component={Link}
                                                                to="/cookie-policy"
                                                                color={medical.colors.blue}
                                                            >
                                                                {' '}
                                                                Cookie Policy
                                                            </Typography>
                                                            {' '}
                                                            and
                                                            {' '}
                                                            <Typography
                                                                variant="body2"
                                                                component={Link}
                                                                to="/privacy-policy"
                                                                color={medical.colors.blue}
                                                            >
                                                                Privacy Policy
                                                            </Typography>
                                                        </Typography>
                                                    }
                                                />
                                            </Stack>
                                            <Typography variant={'body2'} color={medical.colors.lightGrey} mt={'10px'}
                                                        ml={'15px'}>
                                                This site is protected by reCAPTCHA and the Google
                                                <a style={{fontSize: '16px', color: medical.colors.blue}}
                                                   href={'https://policies.google.com/privacy'} target={'_blank'}
                                                   rel={"noreferrer"}> Privacy Policy</a> and
                                                <a style={{fontSize: '16px', color: medical.colors.blue}} href={'https://policies.google.com/terms'}
                                                   target={'_blank'} rel={"noreferrer"}> Terms of Service</a> apply.
                                            </Typography>
                                            <LoadingButton
                                                size={'large'}
                                                loading={isSubmitting}
                                                type="submit"
                                                sx={{
                                                    margin: '40px auto',
                                                    backgroundColor: medical.colors.blue,
                                                    '&:hover': {
                                                        backgroundColor: medical.colors.blue,
                                                        boxShadow: 'none',
                                                    },
                                                }}
                                            >
                                                Submit
                                            </LoadingButton>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Container>
            </Container>
            <Footer/>
        </>
    )
}

export default ContactUsPage
