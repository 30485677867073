import {createSlice} from '@reduxjs/toolkit';
import {passwordForgotState} from '../types';
import {forgotPassword, resetPassword} from '../actions/authActions/forgotPasswordAction';

const initialState: passwordForgotState = {
    isSent: false,
    error: null,
    status: null
}

const passwordForgotSlice = createSlice({
    name: 'password-forgot',
    initialState,
    reducers: {
        resetPasswordForgot: () => initialState,
        resetPasswordReset: () => initialState
    },
    extraReducers: builder => {
        builder
            .addCase(forgotPassword.pending, (state) => {
                state.isSent = false
                state.status = 'loading'
                state.error = null
            })
            .addCase(forgotPassword.fulfilled, (state) => {
                state.isSent = true
                state.status = 'resolved'
                state.error = null
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.isSent = false
                state.status = 'rejected'
                state.error = action.payload
            })
        builder
            .addCase(resetPassword.pending, (state) => {
                state.isSent = false
                state.status = 'loading'
                state.error = null
            })
            .addCase(resetPassword.fulfilled, (state) => {
                state.isSent = true
                state.status = 'resolved'
                // state.resetPasswordToken = action.payload.resetPasswordToken
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.isSent = false
                state.status = 'rejected'
                state.error = action.payload
            })
    }
})


export const {resetPasswordForgot} = passwordForgotSlice.actions
export default passwordForgotSlice.reducer