// in the file where you are creating the theme (invoking the function `createTheme()`)
import {createTheme} from '@mui/material/styles';
import './interfaces'

const colors = {
    golden: '#F2A83B',
    black: '#1D1D1D',
    grey: '#4A4A4A',
    lightGrey: '#93A1B0',
    blue: '#155B90',
    white: '#fff',
    orange: '#F26D21'
}

let  medical = createTheme({
    colors,
    palette: {
        primary: {
            main: colors.grey,
        },
        secondary: {
            main: colors.blue,
        },
        text: {
            primary: colors.grey,
            secondary: colors.blue,
        },
    },
    typography: {
        fontFamily: 'Lato, sans-serif',
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1640,
        },
    },
});

medical = createTheme(medical, {typography: {
        fontFamily: 'Lato, sans-serif',
        h1: {
            fontSize: 83,
            lineHeight: '85px',
            fontWeight: 900,
            color: colors.blue,
            [medical.breakpoints.down('xl')]: {
                fontSize: 79,
                lineHeight: '81px',
            },
            [medical.breakpoints.down('md')]: {
                textAlign: 'center',
            },
            [medical.breakpoints.down('sm')]: {
                fontSize: '48px',
                lineHeight: '51px'
            },
        },
        h2: {
            fontSize: 58,
            lineHeight: '66px',
            fontWeight: 900,
            color: colors.blue,
            [medical.breakpoints.down('md')]: {
                fontSize: '38px',
                lineHeight: '40px',
                textAlign: 'center',
            },
        },
        h3: {
            fontSize: 42,
            lineHeight: '50px',
            color: colors.blue,
            [medical.breakpoints.down('md')]: {
                textAlign: 'center',
            },
            [medical.breakpoints.down('xl')]: {
                fontSize: 38,
                lineHeight: '50px',
            },
            [medical.breakpoints.down('sm')]: {
                fontSize: '18px',
                lineHeight: '32px'
            },
        },
        h4: {
            fontSize: 32,
            lineHeight: '50px',
            fontWeight: 900,
            color: colors.blue,
            [medical.breakpoints.down('md')]: {
                fontSize: '24',
                textAlign: 'center',
            },
        },
        h5: {
            fontSize: 28,
            lineHeight: '30px',
            color: colors.blue,
            [medical.breakpoints.down('md')]: {
                fontSize: 16,
                lineHeight: '30px',
            },
        },
        h6: {
            fontSize: 26,
            lineHeight: '30px',
            color: colors.golden,
            textTransform: 'uppercase',
            letterSpacing: '2.85px',
            [medical.breakpoints.down('md')]: {
                fontWeight: 900,
                fontSize: 21,
                marginBottom: '10px',
                lineHeight: '30px',
                textAlign: 'center',
            },
        },
        body1: {
            fontSize: 18,
            lineHeight: '25px',
            fontWeight: 400,
            [medical.breakpoints.down('md')]: {
                fontSize: 16,
                lineHeight: '25px',
            },
        },
        body2: {
            fontSize: 16,
            lineHeight: '30px',
            fontWeight: 400,
            [medical.breakpoints.down('md')]: {
                fontSize: 16,
                lineHeight: '25px',
            },
        },
        subtitle1: {
            fontSize: 20,
            lineHeight: '30px',
            fontWeight: 300,
            [medical.breakpoints.down('md')]: {
                fontSize: '16px',
                lineHeight: '25px',
                textAlign: 'center',
            },
            [medical.breakpoints.up('lg')]: {
                fontSize: 20,
                lineHeight: '30px',
            },
        },
        subtitle2: {
            fontSize: 18,
            lineHeight: '30px',
            [medical.breakpoints.down('md')]: {
                fontSize: '16px',
                lineHeight: '25px'
            },
            [medical.breakpoints.up('lg')]: {
                fontSize: 18,
                lineHeight: '30px',
            },
        },
        button: {
            fontSize: 16,
            fontWeight: '900',
            lineHeight: 'inherit',
            textTransform: 'none',
            padding: '30px 40px',
            whiteSpace: 'nowrap',
            [medical.breakpoints.down('md')]: {
                fontSize: 16,
                padding: '30px 40px',
            },
            [medical.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '21px',
                padding: '15px 30px',
            },
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '*::-webkit-scrollbar': {
                    width: '.3em'
                },
                '*::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    backgroundColor: 'rgba(15,117,188,0.2)',
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: colors.blue,
                    borderRadius: '10px'
                },
                html: {
                    height: '100%',
                    // Font smoothing
                    WebkitFontSmoothing: 'auto!important',
                },
                body: {
                    height: '100%',
                    margin: 0,
                    padding: 0,
                    '& a': {
                        fontSize: '18px',
                        fontHeight: '30px',
                        fontWeight: 400,
                        color: colors.grey,
                        textDecoration: 'none',
                        transition: '.4s',
                        '& a:hover': {
                            color: colors.blue
                        }
                    }
                },
                '#root': {
                    minHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    color: colors.white,
                    border: 'none',
                    borderRadius: '10px',
                    boxShadow: 'none',
                    backgroundColor: colors.orange,
                    '&:hover': {
                        backgroundColor: colors.orange,
                        boxShadow: '6px 6px 20px #E99B4140'
                    },
                    '&.Mui-disabled': {
                        color: colors.white,
                        backgroundColor: colors.lightGrey,
                        border: 'none',
                    }
                },
            },
            variants: [
                {
                    props: {variant: 'outlined'},
                    style: {
                        color: colors.blue,
                        backgroundColor: 'transparent',
                        border: `1px solid ${colors.blue}`,
                        '&:hover': {
                            color: colors.white,
                            backgroundColor: colors.blue,
                            border: `1px solid ${colors.white}`,
                            boxShadow: 'none',
                        },
                    },
                },
                {
                    props: {variant: 'contained'},
                    style: {
                        color: colors.white,
                        backgroundColor: colors.blue,
                        border: `1px solid ${colors.blue}`,
                        '&:hover': {
                            color: colors.white,
                            backgroundColor: colors.blue,
                            boxShadow: 'none',
                        },
                    },
                },
                {
                    props: {size: 'large'},
                    style: {
                        width: '196px',
                        height: '54px',
                        padding: '16px',
                    },
                },
                {
                    props: {size: 'small'},
                    style: {
                        width: '130px',
                        height: '40px',
                        padding: '13px',
                    },
                },
            ],

        },
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                    fontWeight: 400,
                    color: colors.grey,
                    borderBottom: 'none',
                    transition: '.4s',
                    '&:hover': {
                        color: colors.blue,
                    },
                }
            }
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    width: '100%',
                }
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {}
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {}
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    fontWeight: 400,
                    color: colors.lightGrey,
                    '&.Mui-focused': {
                        fontSize: '14px',
                        color: colors.blue
                    },
                },
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    lineHeight: 'none',
                    fontWeight: 400,
                    color: colors.black,
                    padding: '0',
                    marginTop: '10px!important',
                    '& input': {
                        padding: '12px 6px'
                    },
                    '& :-webkit-autofill': {
                        transition: 'background-color 5000s ease-in-out 0s'
                    },
                    '&:before': {
                        borderBottom: `1px solid ${colors.lightGrey}`,
                    },
                    '&:hover:not(.Mui-disabled):before': {
                        borderBottom: `2px solid ${colors.lightGrey}`,
                    },
                    '&:after': {
                        borderBottom: `2px solid ${colors.blue}`,
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: 0,
                    marginTop: '3px',
                    marginRight: '5px',
                    color: colors.lightGrey
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    position: 'absolute',
                    bottom: '-20px',
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    textAlign: 'left',
                    margin: '20px 0',
                    '& .MuiAlert-icon': {
                        padding: '12px 0'
                    }
                }
            }
        }
    },
});

export {
    medical
};
