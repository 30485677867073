import React, {useEffect} from 'react'
import {Box, Divider, Hidden, Stack, Typography} from '@mui/material'
import Rocket from '../../assets/img/rocket.png'
import {Form, Formik} from 'formik'
import {validationSchemaSignup} from '../../helpers/validation'
import {register} from '../../store/actions/authActions/registerAction'
import TextFieldForm from '../UI/TextFieldForm'
import CheckBoxForm from '../UI/CheckboxForm'
import {Link} from 'react-router-dom'
import {useTypedDispatch, useTypedSelector} from '../../store'
import {useTheme} from '@mui/styles'
import PrevButton from '../UI/PrevButton'
import ShowPasswordField from '../UI/ShowPasswordField'
import {prevStep, resetRegister} from '../../store/slices/registerSlice'
import LoadingButton from '@mui/lab/LoadingButton'
import PasswordChecklist from "react-password-checklist"
import Popper from '@mui/material/Popper';


const SignUpForm: React.FC = () => {
    const medical = useTheme()
    const dispatch = useTypedDispatch()
    const {userRole, status, error} = useTypedSelector(
        state => state.registerReducer
    )

    useEffect(() => {
        window.scrollTo(0, 0)
        window.dispatchEvent(new Event('scroll'));
    }, []);

    const [passwordChecklistEl, setPasswordChecklistEl] = React.useState<HTMLButtonElement | null>(null);
    const [password, setPassword] = React.useState('');

    const MR = 1
    const MP = 2

    const prevStepActions = () => {
        dispatch(prevStep())
        dispatch(resetRegister())
    }

    const handleClickPasswordInput = (event: React.MouseEvent<HTMLButtonElement>) => {
        setPasswordChecklistEl(event.currentTarget);
    };

    const handleClickPasswordInputClose = () => {
        setPasswordChecklistEl(null);
    };

    const open = Boolean(passwordChecklistEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            maxWidth="1150px"
            m="0 auto"
            position="relative"
            sx={{
                boxShadow: '10px 10px 20px #0000001A',
                [medical.breakpoints.down('md')]: {maxWidth: '70%'},
                [medical.breakpoints.down('sm')]: {maxWidth: '100%'},
            }}
        >
            <PrevButton
                position="absolute"
                left="20px"
                top="20px"
                sx={{
                    [medical.breakpoints.down('md')]: {
                        left: '10px',
                        top: '10px',
                        width: '20px',
                        height: '20px',
                        '& .MuiSvgIcon-root': {
                            fontSize: 14,
                        },
                    },
                }}
                onClick={() => prevStepActions()}
            />
            <Hidden mdDown>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="#93A1B0"
                    borderRadius="10px"
                    width="50%"
                >
                    <Box
                        component="img"
                        alt="rocket"
                        src={Rocket}
                        sx={{
                            objectFit: 'contain',
                            width: '100%',
                            maxWidth: '470px',
                            height: 'auto',
                        }}
                    />
                </Stack>
            </Hidden>

            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                width="50%"
                p="60px 70px"
                sx={{
                    [medical.breakpoints.down('md')]: {width: '100%', p: '30px'},
                }}
            >
                <Typography variant="h4" mb="10px">
                    CREATE AN ACCOUNT
                </Typography>
                <Typography>
                    {userRole === MR
                        ? 'Showcase your products. Connect with Medical Practitioners. Improve your sales performance by working online.'
                        : userRole === MP
                            ? 'Welcome to the community! Submit your email below to receive your log-in credentials.'
                            : null}
                </Typography>
                <Formik
                    validateOnBlur={true}
                    initialValues={{
                        role: userRole,
                        email: '',
                        password: '',
                        conditions: false,
                    }}
                    validationSchema={validationSchemaSignup}
                    onSubmit={async (values) => {
                        dispatch(register(values))
                    }}
                >
                    {({handleChange}) => (
                        <Stack component={Form} width="100%">
                            <Stack
                                width="100%"
                                m="40px 0 25px"
                                sx={{
                                    [medical.breakpoints.down('md')]: {
                                        mt: '20px',
                                    },
                                }}
                            >
                                <TextFieldForm
                                    name="email"
                                    label="WORK EMAIL"
                                    variant="standard"
                                    sx={{
                                        mb: '50px',
                                        [medical.breakpoints.down('md')]: {mb: '25px'},
                                    }}
                                />
                                <ShowPasswordField
                                    name="password"
                                    label="PASSWORD"
                                    variant="standard"
                                    sx={{mb: '19px'}}
                                    helperText={error}
                                    onFocus={handleClickPasswordInput}
                                    onBlur={handleClickPasswordInputClose}
                                    onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                        setPassword((e.target as HTMLInputElement).value)
                                    }}
                                    autoComplete="on"
                                />

                                <Popper
                                    id={id}
                                    open={open}
                                    anchorEl={passwordChecklistEl}
                                    placement={'top-start'}
                                >
                                    <Box
                                        sx={{
                                            boxShadow: '5px 5px 20px #0000001A',
                                            marginY: 1,
                                            bgcolor: 'background.paper',
                                            borderRadius: '5px',
                                        }}
                                    >
                                        <Stack sx={{p: 2}}>
                                            <Box
                                                sx={{
                                                    marginBottom: 1,
                                                    fontSize: '16px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Password requirements
                                            </Box>
                                            <Divider sx={{marginBottom: 2}}/>
                                            <PasswordChecklist
                                                rules={[
                                                    'minLength',
                                                    'specialChar',
                                                    'number',
                                                    'capital',
                                                    'lowercase',
                                                ]}
                                                minLength={8}
                                                value={password}
                                                iconSize={12}
                                                messages={{
                                                    minLength: 'Have at least 8 characters',
                                                    specialChar:
                                                        'Have at least one special character [!@#$%^&*]',
                                                    number: 'Have at least one number [1, 2, 3, ...]',
                                                    capital:
                                                        'Have at least one capital letter [A, B, C, ...]',
                                                    lowercase: 'Have at least one lowercase letter [a, b, c, ...]'
                                                }}
                                                style={{
                                                    fontSize: '14px',
                                                }}
                                                className="passwordChecklist"
                                            />
                                        </Stack>
                                    </Box>
                                </Popper>

                                <Stack direction="row" alignItems="start" mb="50px">
                                    <CheckBoxForm
                                        name="conditions"
                                        onChange={handleChange}
                                        label={
                                            <Typography
                                                variant="body2"
                                                textAlign="left"
                                                color={medical.colors.lightGrey}
                                            >
                                                I agree to the
                                                <Typography
                                                    variant="body2"
                                                    component={Link}
                                                    to="/terms-and-conditions"
                                                    color={medical.colors.blue}
                                                >
                                                    {' '}
                                                    Terms of Service
                                                </Typography>{' '}
                                                ,
                                                <Typography
                                                    variant="body2"
                                                    component={Link}
                                                    to="/additional-terms-for-subscribers"
                                                    color={medical.colors.blue}
                                                >
                                                    {' '}
                                                    Additional Terms for Subscribers
                                                </Typography>{' '}
                                                ,
                                                <Typography
                                                    variant="body2"
                                                    component={Link}
                                                    to="/cookie-policy"
                                                    color={medical.colors.blue}
                                                >
                                                    {' '}
                                                    Cookie Policy
                                                </Typography>{' '}
                                                 and
                                                <Typography
                                                    variant="body2"
                                                    component={Link}
                                                    to="/privacy-policy"
                                                    color={medical.colors.blue}
                                                >
                                                    {' '}
                                                    Privacy Policy
                                                </Typography>
                                            </Typography>
                                        }
                                    />
                                </Stack>
                                <LoadingButton
                                    size="large"
                                    type="submit"
                                    loading={status === 'loading'}
                                    variant="contained"
                                    sx={{width: '100%', maxWidth: '250px', m: '0 auto'}}
                                >
                                    Sign Up
                                </LoadingButton>

                                {/*{status === 'rejected' && (
									<NotificationPopup error>{error}</NotificationPopup>
								)}*/}
                            </Stack>
                        </Stack>
                    )}
                </Formik>
                <Typography
                    component={Link}
                    variant="body2"
                    color={medical.colors.blue}
                    to="/sign-in"
                >
                    Sign In
                </Typography>

                <Typography variant={'body2'} color={medical.colors.lightGrey} mt={'25px'}
                            ml={'15px'}>
                    This site is protected by reCAPTCHA and the Google
                    <a style={{fontSize: '16px', color: medical.colors.blue}}
                       href={'https://policies.google.com/privacy'} target={'_blank'}
                       rel={"noreferrer"}> Privacy Policy</a> and
                    <a style={{fontSize: '16px', color: medical.colors.blue}}
                       href={'https://policies.google.com/terms'}
                       target={'_blank'} rel={"noreferrer"}> Terms of Service</a> apply.
                </Typography>
            </Stack>
        </Stack>
    )
}

export default SignUpForm
