import React from 'react'
import {Box, Container, Grid, Hidden, Stack, Theme, Typography,} from '@mui/material'
import {makeStyles, useTheme} from '@mui/styles'


interface FeatureData {
    title: string
    desc: string
    icon: string
}

interface FeaturesProps {
    reps: boolean
    title: string
    subTitle: string
    desc: string
    mobileFeature?: string
    data: FeatureData[]
}

const useStyles = makeStyles<Theme, FeaturesProps>(medical => ({
    features: {
        height: '100%',
        flexGrow: 1,
        justifyContent: 'space-between',
        [medical.breakpoints.down('md')]: {
            height: 'auto',
        },
    },
    feature: {
        width: '100%',
        borderRadius: '5px',
        boxShadow: '10px 10px 20px #0000000D',
        padding: '40px 30px',
        mb: '40px!important',
        display: 'flex',
        '& h5': {
            fontSize: '22px!important',
            lineHeight: '25px!important',
        },
        '& .icon': {
            position: 'relative',
            marginTop: '-10px',
            marginRight: '50px!important',
            '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                width: '90px',
                height: '90px',
                borderRadius: '50%',
                backgroundColor: '#e8f6fc',
                top: '0px',
                zIndex: -1,
            },
            '& img': {
                marginTop: '20px',
                marginLeft: '-5px',
            }
        },

        [medical.breakpoints.down('md')]: {
            flexDirection: 'column',
            textAlign: 'center',
            '& .icon': {
                margin: '0 auto!important',
                marginBottom: '20px!important',
            }
        }
    }
}));

const FeaturesSection: React.FC<FeaturesProps> = (props: FeaturesProps) => {
    const classes = useStyles(props)
    const medical = useTheme()

    return (
        <Container maxWidth="xl" sx={{mt: '40px', mb: '120px'}}>
            <Grid container spacing={0}>
                <Grid item xs={12}  mb={{md: '50px', xs: '10px'}}>
                    <Typography variant="h3" sx={{whiteSpace: 'nowrap'}} color={medical.colors.golden}>
                        {props.subTitle}
                    </Typography>
                    <Typography variant="h2">
                        {props.title}
                        <span style={{color: medical.colors.blue}}>.</span>
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        color={medical.colors.grey}
                        fontWeight="400"
                        maxWidth={'1000px'}
                        mt="15px"
                    >
                        {props.desc}
                    </Typography>
                </Grid>

                <Grid item container columnSpacing={{
                    lg: 10,
                    md: 5,
                }}>
                    <Grid item md={4.5} xs={12} mb={{lg: '50px'}} className={classes.features}>
                        <Stack direction={'column'} height={{
                            xs: 'auto',
                            md: '100%',
                        }} justifyContent={'space-around'}>
                            {props.data.slice(0, 3).map((item, index) => (
                                <Box key={index} className={classes.feature}>
                                    <div className={'icon'}>
                                        <img src={item.icon} alt="feature-icon"/>
                                    </div>
                                    <div>
                                        <Typography variant="h5" fontWeight="900" mb={'10px'}>
                                            {item.title}
                                        </Typography>
                                        <Typography>{item.desc}</Typography>
                                    </div>
                                </Box>
                            ))}
                        </Stack>
                    </Grid>

                    <Hidden mdDown>
                        <Grid item md={3}>
                            <img style={{
                                width: '100%',
                            }} src={props.mobileFeature} alt="mobile-feature"/>
                        </Grid>
                    </Hidden>

                    <Grid item md={4.5} xs={12} mb={{lg: '50px'}} className={classes.features}>
                        <Stack direction={'column'} height={{
                            xs: 'auto',
                            md: '100%',
                        }} justifyContent={'space-around'}>
                            {props.data.slice(3, 6).map((item, index) => (
                                <Box key={index} className={classes.feature}>
                                    <div className={'icon'}>
                                        <img src={item.icon} alt="feature-icon"/>
                                    </div>
                                    <div>
                                        <Typography variant="h5" fontWeight="900" mb={'10px'}>
                                            {item.title}
                                        </Typography>
                                        <Typography>{item.desc}</Typography>
                                    </div>
                                </Box>
                            ))}
                        </Stack>
                    </Grid>

                </Grid>
            </Grid>
        </Container>
    )
}

export default FeaturesSection
