import React, {useEffect} from 'react'
import {Button, Container, Grid, Stack, Typography} from '@mui/material'
import MedicalItem from '../components/MedicalItem'
import Footer from '../components/Footer'
import HeadSection from '../components/HeadSection'
import {useTheme} from '@mui/styles'
import FeaturesSection from '../components/FeaturesSection'
import {FeaturesData, StepsData} from '../data'
import headImg from '../assets/img/header-mp-page.svg'
import SignUpSection from '../components/Footer/SignUpSection'
import mobileFeature from "../assets/img/mobile-feature-mp.jpg";
import imgFooter from '../assets/img/footer_bg.jpg'

const PractitionersPage: React.FC = () => {
    const medical = useTheme()
    const coloredSquare = (color: string) => (
        <div style={{width: '10px', height: '10px', marginRight: '2px', marginLeft: '2px', backgroundColor: color}}/>
    );

    useEffect(() => {
        document.title = 'Medical Practitioners | MedcoreMD';
    }, []);

    return (
        <>
            <Container maxWidth={false} disableGutters={true}>
                <HeadSection
                    subtitle="COLLABORATION MADE EASY"
                    title="Streamlined Rep Management"
                    desc="As a medical practice, you&#39;ve got a lot on your plate - the user-friendly Medcore MD platform enables you to quickly and efficiently connect with sales reps on your schedule."
                    descBold="Completely free, forever."
                    img={headImg}
                    bg={medical.colors.grey}
                    contentMaxWidth={'630px'}
                />
                <Container maxWidth="xl" sx={{
                    mt: '70px',
                    [medical.breakpoints.down('md')]: {
                        mt: '30px',
                    }
                }}>
                    <Grid item xl={12}>
                        <Stack maxWidth="1050px">
                            <Typography variant="h2">
                                INNOVATIVE TOOLS FOR OPTIMIZED MEETING MANAGEMENT
                            </Typography>
                            <Typography variant="subtitle1" mt={'10px'}>Modernize your workflow, improve your communication capabilities, and make quicker
                                connections in four simple steps:</Typography>
                        </Stack>
                    </Grid>
                    <Grid container
                          justifyContent="space-between"
                          columnSpacing={10}
                          mt="75px"
                          sx={{
                              [medical.breakpoints.down('md')]: {
                                  flexDirection: 'column'
                              },
                          }}
                    >
                        {StepsData.medicalPractitioners.map((item, index) => (
                            <Grid key={index} item lg={3}>
                                <MedicalItem
                                    icon={item.icon}
                                    title={item.title}
                                    desc={item.desc}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Container>
                <Container
                    maxWidth={false}
                    sx={{
                        mt: '100px',
                        pt: '80px',
                        pb: '100px',
                        backgroundImage: `url(${imgFooter})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        mb: '120px',
                        [medical.breakpoints.down('md')]: {
                            mt: '50px',
                            mb: '50px',
                        }
                    }}
                >
                    <Container maxWidth="xl" >
                        <Stack
                            direction="column"
                            alignItems="center"
                            textAlign="center"
                            maxWidth="1000px"
                            margin="0 auto"
                        >
                            <Typography variant="h2" color={medical.colors.white} my={'15px'}>
                                Product Capabilities
                            </Typography>

                            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} mb={'15px'}>
                                {coloredSquare('#F26D21')}
                                {coloredSquare('#00ADEE')}
                                {coloredSquare('#1CC0C4')}
                            </Stack>

                            <Typography variant="h6" color={medical.colors.white} my={'15px'} sx={{
                                textTransform: 'none!important',
                                fontWeight: '300!important',
                            }}>
                                Learn more about our modern communication platform designed to meet the needs of
                                medical practices.
                            </Typography>

                            <Button
                                size="large"
                                href={`https://${process.env.REACT_APP_FILES_BUCKET_DOMAIN}/MedcoreMD-brochure.pdf`}
                                target={'_blank'}
                                sx={{
                                    marginTop: '20px',
                                }}
                            >
                                Download Brochure
                            </Button>
                        </Stack>
                    </Container>
                </Container>
                <FeaturesSection
                    reps={false}
                    subTitle="Medcore MD"
                    title="KEY PLATFORM FEATURES"
                    desc="Modern meetings management solutions for modern medical practitioners."
                    mobileFeature={mobileFeature}
                    data={FeaturesData.medicalPractitioners}
                />
            </Container>
            <SignUpSection/>
            <Footer/>
        </>
    )
}



export default PractitionersPage
