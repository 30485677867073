import React from 'react';
import {Box, BoxProps, SvgIcon, Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {ReactComponent as leftArrow} from '../../../assets/icons/arrow_left.svg';

const useStyles = makeStyles((medical: Theme) => ({
        prevButton: {
            width: '36px',
            height: '36px',
            borderRadius: '50%',
            background: medical.colors.white,
            boxShadow: '3px 3px 6px #00000026',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        prevArrow: {
            '&.MuiSvgIcon-root': {
                fontSize: '24px',
            }

        }
    })
)

// TODO need to check
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PrevButton: React.FC<BoxProps> = (props: any) => {

    const classes = useStyles()

    return (
        <Box {...props} className={classes.prevButton}>
            <SvgIcon
                className={classes.prevArrow}
                component={leftArrow}
                inheritViewBox
            >
            </SvgIcon>
        </Box>
    );
};

export default PrevButton;
