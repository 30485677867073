import authService from '../../../services/authService';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {loginData, loginForm} from '../../types';
import {setEmail, setTokens} from '../../slices/verifyEmailSlice';
import {userVerifyStatus} from '../../slices/loginSlice';
import {recaptchaExecute, recaptchaIsReady} from "../../../helpers/recaptcha";
import {openPopup} from "../../slices/popup.slice";

export const login = createAsyncThunk<loginData, loginForm, { rejectValue: string }>(
// action type string
    'auth/login',
// callback function

    async ({email, password}, thunkApi) => {
        try {
            if (!recaptchaIsReady()) {
                thunkApi.dispatch(openPopup({
                    message: 'reCAPTCHA is not ready yet. Please try again later.',
                    type: 'error',
                }))
                return;
            }

            const recaptcha_token = await recaptchaExecute('SIGN_IN');
            if (!recaptcha_token) {
                thunkApi.dispatch(openPopup({
                    message: 'reCaptcha token is not valid. Please try again later.',
                    type: 'error',
                }))
                return;
            }

            const response = await authService.login(email, password, recaptcha_token)
            const {success, message, data} = response.data

            if (!success) {
                thunkApi.dispatch(openPopup({
                    message: message,
                    type: 'error',
                }))
                return thunkApi.rejectWithValue(message)
            }

            thunkApi.dispatch(setEmail({email: email}))

            if (data.tokenVerify) {
                thunkApi.dispatch(setTokens({
                        tokenVerify: data.tokenVerify,
                        accessToken: data.accessToken,
                        refreshToken: data.refreshToken,
                    }
                ))
                thunkApi.dispatch(userVerifyStatus())
                return
            }

            if (data.isAdmin) {
                window.location.href = process.env.REACT_APP_ADMIN_URL || ''
            } else {
                window.location.href = process.env.REACT_APP_OFFICE_URL || ''
            }


            return data

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            thunkApi.dispatch(openPopup({
                message: error.message,
                type: 'error',
            }))
            return thunkApi.rejectWithValue(error.message)


        }
    }
)
