import React from 'react';
import {styled, SvgIcon as MuiSvgIcon} from '@mui/material';

const SvgIcon = styled(MuiSvgIcon, {
    name: 'FooterIcon',
})(({theme: medical}) => ({
    fontSize: '48px',
    cursor: 'pointer',
    '& g': {
        fill: 'none',
        stroke: medical.colors.blue,
        transition: '.4s',
        '&rect': {
            fill: 'none',
            stroke: 'none',
            transition: '.4s',
        }
    },
    '& path': {
        fill: medical.colors.blue,
        transition: '.4s',
    },
    '&:hover': {
        '& g': {
            fill: medical.colors.blue,
            stroke: 'none',
            transition: '.4s',
            '&rect': {
                fill: medical.colors.blue,
                stroke: medical.colors.blue,
                transition: '.4s',
            }
        },
        '& path': {
            fill: 'white',
            transition: '.4s',
        },
    }
}));

interface FooterIcon {
    children?: JSX.Element | JSX.Element[],
}

const FooterIcon: React.FC<FooterIcon> = (props: FooterIcon) => {
    return (
        <SvgIcon inheritViewBox>
            {props.children}
        </SvgIcon>
    )
};

export default FooterIcon;
