import authService from '../../../services/authService';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {verifyEmailData, verifyEmailForm, verifyRequestEmailData, verifyRequestEmailForm} from '../../types';
import {setTokens} from '../../slices/verifyEmailSlice';

export const verifyEmail = createAsyncThunk<verifyEmailData, verifyEmailForm, { rejectValue: string }>(
// action type string
    'verify/sent',
// callback function

    async ({verifyCode, tokenVerify}, thunkApi) => {
        try {
// make request to backend
            const response = await authService.verifyAccount(verifyCode, tokenVerify)
            const {success, message, data} = response.data

            if (!success) {
                return thunkApi.rejectWithValue(message)
            }

            thunkApi.dispatch(verifyEmail.fulfilled)

            window.location.href = process.env.REACT_APP_ON_BOARDING_URL || ''

            return data

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (error) {
                return thunkApi.rejectWithValue(error.message)
            }

        }
    }
)


export const verifyRequestEmail = createAsyncThunk<verifyRequestEmailData, verifyRequestEmailForm, { rejectValue: string }>(
    // action type string
    'verify/request',
    // callback function

    async ({accessToken}, thunkApi) => {
        try {
            console.log("accessToken", accessToken)
            // make request to backend
            const response = await authService.verifyRequest(accessToken)
            const {success, error, data} = response.data

            if (!success) {
                if (error.msg) {
                    const errorMessage = error.msg ? error.msg : 'Verify request is fail. Please try again later!'
                    return thunkApi.rejectWithValue(errorMessage)
                } else if (error.data.msg) {
                    return thunkApi.rejectWithValue(error.data.msg)
                }
            }

            thunkApi.dispatch(setTokens({tokenVerify: data.tokenVerify}))

            return data

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (error) {
                return thunkApi.rejectWithValue(error.message)
            }

        }
    }
)
