import React, {useEffect} from 'react';
import {Box, Container, Divider, Hidden, Stack, Typography} from '@mui/material';
import Rocket from '../assets/img/rocket.png';
import {Form, Formik} from 'formik';
import {validationSchemaResetPassword} from '../helpers/validation';
import {Link, useParams} from 'react-router-dom';
import {useTheme} from '@mui/styles';
import {useTypedDispatch, useTypedSelector} from '../store';
import ShowPasswordField from '../components/UI/ShowPasswordField';
import LoadingButton from '@mui/lab/LoadingButton';
import {resetPassword} from '../store/actions/authActions/forgotPasswordAction';
import Popper from "@mui/material/Popper";
import PasswordChecklist from "react-password-checklist";
import Footer from "../components/Footer";

const ResetPassword: React.FC = () => {

    const medical = useTheme()
    const dispatch = useTypedDispatch();
    const {error, status} = useTypedSelector(state => state.passwordForgotReducer)
    const {token} = useParams();
    const [passwordChecklistEl, setPasswordChecklistEl] = React.useState<HTMLButtonElement | null>(null);
    const [password, setPassword] = React.useState('');

    const handleClickPasswordInput = (event: React.MouseEvent<HTMLButtonElement>) => {
        setPasswordChecklistEl(event.currentTarget);
    };

    const handleClickPasswordInputClose = () => {
        setPasswordChecklistEl(null);
    };

    const open = Boolean(passwordChecklistEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        document.title = 'Reset Password | MedcoreMD';
    }, []);

    return (
        <>
            <Container
                maxWidth={false}
                disableGutters={true}
                sx={{
                    mt: '100px',
                    mb: '100px',
                    flex: 1,
                    [medical.breakpoints.down('md')]: {
                        mt: '50px',
                        mb: '50px',
                    },
                    [medical.breakpoints.down('sm')]: {
                        mt: '30px',
                        mb: '30px',
                    }
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    maxWidth="1090px"
                    m="0 auto"
                    sx={{
                        boxShadow: '10px 10px 20px #0000001A',
                        [medical.breakpoints.down('md')]: {maxWidth: '70%'},
                        [medical.breakpoints.down('sm')]: {maxWidth: '100%'},
                    }}
                >
                    <Hidden mdDown>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            bgcolor="#93A1B0"
                            borderRadius="10px"
                            width="50%"
                        >
                            <Box
                                component="img"
                                alt="rocket"
                                src={Rocket}
                                sx={{
                                    objectFit: 'contain',
                                    width: '100%',
                                    maxWidth: '470px',
                                    height: 'auto',
                                }}
                            />
                        </Stack>
                    </Hidden>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                        width="50%"
                        p="60px 70px"
                        sx={{
                            [medical.breakpoints.down('md')]: {width: '100%', p: '30px'},
                        }}
                    >
                        <Typography variant="h4" mb="10px">
                            ALL GOOD
                            <Typography
                                variant="h4"
                                component="span"
                                color={medical.colors.blue}
                            >
                                !
                            </Typography>
                        </Typography>

                        <Typography>Now Reset Your Password</Typography>
                        <Formik
                            validateOnBlur={true}
                            initialValues={{
                                resetPasswordToken: token,
                                password: '',
                                confirmPassword: '',
                            }}
                            validationSchema={validationSchemaResetPassword}
                            onSubmit={(values, {resetForm}) => {
                                dispatch(resetPassword(values))
                                resetForm()
                            }}
                        >
                            {() => (
                                <Stack component={Form} width="100%">
                                    <Stack
                                        width="100%"
                                        m="40px 0 25px"
                                        sx={{
                                            [medical.breakpoints.down('md')]: {
                                                mt: '20px',
                                            },
                                        }}
                                    >
                                        <ShowPasswordField
                                            name="password"
                                            label="NEW PASSWORD"
                                            variant="standard"
                                            sx={{mb: '19px'}}
                                            helperText={error}
                                            onFocus={handleClickPasswordInput}
                                            onBlur={handleClickPasswordInputClose}
                                            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                setPassword((e.target as HTMLInputElement).value)
                                            }}
                                        />
                                        <Popper
                                            id={id}
                                            open={open}
                                            anchorEl={passwordChecklistEl}
                                            placement={'top-start'}
                                        >
                                            <Box
                                                sx={{
                                                    boxShadow: '5px 5px 20px #0000001A',
                                                    marginY: 1,
                                                    bgcolor: 'background.paper',
                                                    borderRadius: '5px',
                                                }}
                                            >
                                                <Typography sx={{p: 2}}>
                                                    <Box
                                                        sx={{
                                                            marginBottom: 1,
                                                            fontSize: '16px',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Password requirements
                                                    </Box>
                                                    <Divider sx={{marginBottom: 2}}/>
                                                    <PasswordChecklist
                                                        rules={[
                                                            'minLength',
                                                            'specialChar',
                                                            'number',
                                                            'capital',
                                                        ]}
                                                        minLength={8}
                                                        value={password}
                                                        onChange={isValid => {
                                                            console.log(isValid)
                                                        }}
                                                        iconSize={12}
                                                        messages={{
                                                            minLength: 'Have at least 8 characters',
                                                            specialChar:
                                                                'Have at least one special character [!@#$%^&*]',
                                                            number: 'Have at least one number [1, 2, 3, ...]',
                                                            capital:
                                                                'Have at least one capital letter [A, B, C, ...]',
                                                        }}
                                                        style={{
                                                            fontSize: '14px',
                                                        }}
                                                        className="passwordChecklist"
                                                    />
                                                </Typography>
                                            </Box>
                                        </Popper>
                                        <ShowPasswordField
                                            name="confirmPassword"
                                            label="CONFIRM NEW PASSWORD"
                                            variant="standard"
                                            sx={{mb: '50px'}}
                                        />
                                        <LoadingButton
                                            size={'large'}
                                            type="submit"
                                            loading={status === 'loading'}
                                            variant="contained"
                                            sx={{width: '100%', maxWidth: '250px', m: '0 auto'}}
                                        >
                                            Reset password
                                        </LoadingButton>
                                       {/* {status === 'rejected' ? (
                                            <NotificationPopup error>{error}</NotificationPopup>
                                        ) : status === 'resolved' ? (
                                            <NotificationPopup sent>
                                                Your password has been successfully updated!
                                                <br/>
                                                <Typography
                                                    component={Link}
                                                    color={medical.colors.blue}
                                                    to="/sign-in"
                                                >
                                                    Sign In
                                                </Typography>
                                            </NotificationPopup>
                                        ) : null}*/}
                                    </Stack>
                                </Stack>
                            )}
                        </Formik>
                        <Typography
                            component={Link}
                            variant="body2"
                            color={medical.colors.blue}
                            to="/forgot-password"
                        >
                            Back
                        </Typography>
                    </Stack>
                </Stack>
            </Container>
            <Footer/>
        </>
    )
};

export default ResetPassword;
