import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    type: "success",
    message: "",
    show: false,
}

const popupSlice = createSlice({
    name: 'popup',
    initialState,
    reducers: {
        openPopup: (state, action) => {
            state.type = action.payload.type
            state.message = action.payload.message
            state.show = true
        },
        closePopup: (state) => {
            state.show = false
            state.message = ""
            state.type = "success"
        }
    }
});

export const {openPopup, closePopup} = popupSlice.actions

export default popupSlice.reducer
