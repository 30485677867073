import React from 'react';
import {Box, Stack, SvgIcon, Typography} from '@mui/material'
import {ReactComponent as beforeImg} from '../../../assets/icons/scheme_desktop_img.svg'
import {ReactComponent as repsIcon} from '../../../assets/icons/reps.svg'
import {ReactComponent as practIcon} from '../../../assets/icons/pract.svg'

const SchemeDesktop = () => {
    return (
        <Stack maxWidth="514px" maxHeight="511px" position="relative">
            <Box
                display="flex"
                alignItems="center"
                height="auto"
                p="15px 20px"
                boxShadow="10px 10px 20px #0F75BC33"
                borderRadius="5px"
                position="absolute"
                top="50%"
                left="-150px"
                sx={{
                    backgroundColor: '#fff',
                }}
            >
                <SvgIcon
                    component={repsIcon}
                    inheritViewBox
                    sx={{
                        width: '44px',
                        height: '44px',
                        mr: '18px',
                    }}
                />
                <Typography
                    variant="subtitle2"
                    fontSize="14px"
                    fontWeight="900"
                    lineHeight="17px"
                >
                    Medical
                    <br/>
                    Representative
                </Typography>
            </Box>
            <Box
                display="flex"
                alignItems="center"
                height="auto"
                p="15px 20px"
                boxShadow="10px 10px 20px #0F75BC33"
                borderRadius="5px"
                position="absolute"
                top="50%"
                right="-150px"
                sx={{
                    backgroundColor: '#fff',
                }}
            >
                <SvgIcon
                    component={practIcon}
                    inheritViewBox
                    sx={{
                        width: '44px',
                        height: '44px',
                        mr: '18px',
                    }}
                />
                <Typography
                    variant="subtitle2"
                    fontSize="14px"
                    fontWeight="900"
                    lineHeight="17px"
                >
                    Medical
                    <br/>
                    Practitioner
                </Typography>
            </Box>
            <SvgIcon
                component={beforeImg}
                inheritViewBox
                sx={{
                    width: '100%',
                    height: '100%',
                }}
            />
        </Stack>
    );
};

export default SchemeDesktop;
