import React, {useEffect} from 'react'
import {Container, List, ListItem, Typography} from '@mui/material'
import Footer from '../components/Footer'
import {medical} from "../theme";

const TermsOfService = () => {
    useEffect(() => {
        document.title = 'Terms and Conditions | MedcoreMD';
    }, []);

    return (
        <>
            <Container
                maxWidth={false}
                disableGutters={true}
                sx={{
                    mt: '100px',
                    mb: '100px',
                    flex: 1,
                    [medical.breakpoints.down('md')]: {
                        mt: '50px',
                        mb: '50px',
                    },
                    [medical.breakpoints.down('sm')]: {
                        mt: '30px',
                        mb: '30px',
                    }
                }}
            >
                <Container maxWidth="xl">
                    <Typography variant="h2">Terms and Conditions</Typography><br/>
                    <Typography variant="body1">Last updated: July 12, 2023</Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Your Acceptance</Typography><br/>
                    <Typography variant="body1">
                        Welcome to the Medcore User Terms. This is an agreement (&quot;Agreement&quot;) between
                        Medcore MD (&quot;Medcore&quot;), the owner and operator of www.medcoremd.com, the
                        Medcore software, mobile applications, components, and the Medcore Services
                        (defined below) (collectively the “Platform”) and you (“you”, “your”, “User”), a User of the
                        Platform. Any User that subscribes or pays to access the Platform or has a master
                        account registered with us shall be referred to within this Agreement as a “Subscriber”.
                        If you are a Subscriber, in addition to this Agreement you must agree to the Additional
                        Terms for Subscribers (“Additional Terms”) which are incorporated into this Agreement
                        by reference.<br/>
                        <p>
                            PLEASE BE AWARE THAT THERE ARE ARBITRATION AND CLASS ACTION
                            PROVISIONS THAT MAY AFFECT YOUR RIGHTS.
                        </p>

                        <p>
                            Throughout this Agreement, the words “Medcore”, “us”, “we”, and “our” refer to our
                            company, Medcore, as is appropriate in the context of the use of the words.
                        </p>

                        <p>
                            By clicking “Continue” or “I Agree” (or a similar button or link), subscribing to the
                            Platform, or accessing or using the Platform you agree to be bound by this Agreement
                            and the Privacy Policy. We may amend our Agreement or the Privacy Policy and may
                            notify you when we do so. If you do not agree with any changes, please cease using our
                            Platform immediately.
                        </p>
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Accounts</Typography><br/>
                    <Typography variant="body1">Users may be required to register prior to using or accessing the
                        Platform. During
                        registration please verify that you have created the proper account. Your information
                        will be collected and disclosed in accordance with our Privacy Policy. All users are
                        required to provide truthful, up-to-date, and accurate information when registering for
                        our Platform. We reserve the right to verify all user credentials and to reject any users.
                        You are entirely responsible for maintaining the confidentiality of password and account
                        and for any and all activities that occur under your account. You agree to notify Medcore
                        immediately of any unauthorized use of your account or any other breach of security.
                        Medcore will not be liable for any losses you incur as a result of someone else using
                        your password or account, either with or without your knowledge. If you are Subscriber
                        you are liable for any of your Authorized User’s (as defined within the Additional Terms)
                        accounts.</Typography><br/>


                    <Typography variant="h6" fontWeight={900}>Age Limits</Typography><br/>
                    <Typography variant="body1">Users must be over the age of 18 to create an account and use the
                        Platform.</Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Access</Typography><br/>
                    <Typography variant="body1">Upon accepted registration, we shall grant you access to the Platform as
                        permitted by
                        us and in accordance with this Agreement. Such access is granted on limited, non-
                        transferable, and non-exclusive basis. Each All rights not explicitly granted are reserved
                        for Medcore. If you breach this Agreement, your access to use our Platform may be
                        terminated at our discretion. Additionally, we may revoke your access to our Platform if
                        we believe that your actions may harm us, our business interests, or any third party
                        rights. Failure by us to revoke your access does not act as a waiver of your conduct.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Platform Ownership</Typography><br/>
                    <Typography variant="body1">You acknowledge that the structure, organization, and code of the
                        Platform, any hosted
                        services, and all related software components are proprietary to Medcore and/or
                        Medcore&apos; licensors and that Medcore and/or its licensors retains exclusive ownership of
                        the Platform, any documentation, information and any and all other intellectual property
                        rights relating to the Platform, including all modifications, copies, enhancements,
                        derivatives, and other software and materials developed hereunder by Medcore. You
                        shall not sell, copy, reproduce, transfer, publish, disclose, display or otherwise make
                        available the Platform, access to the Platform, or any portions of the Platform including
                        any modifications, enhancements, derivatives, and other software and materials
                        developed hereunder by Medcore to others in violation of this Agreement. You shall not
                        remove any proprietary, copyright, trademark, or service mark legend from any portion
                        of any of the Platform, including any modifications, enhancements, derivatives, and
                        other software and materials developed by Medcore.</Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Platform and Services</Typography><br/>
                    <Typography variant="body1">The Medcore Platform may offer services (“Medcore Services”) as made
                        available by us
                        which may include communications and organizational tools for medical industry sales
                        professionals. You may use the Platform and any associated Medcore Services solely
                        as permitted by Medcore. Please be aware that any Medcore Services are “as-is” and
                        “as-available” and are not error free. We reserve the right to alter, modify, update, or
                        remove the Platform or any Medcore Services, at any time at our discretion. We
                        reserve the right to discontinue previously offered features or functionality at our sole
                        discretion and without prior notice. We are not liable to you or to any third party for any
                        modification, suspension, or discontinuance of any feature or component of any portion
                        of the Platform or the Medcore Services. We reserve the right to determine the timing
                        and content of software updates, which may be automatically downloaded and installed
                        by the Platform without notice to you. We may conduct such modifications to our
                        Platform for security reasons, intellectual property, legal reasons, or various other
                        reasons at our discretion, and we are not required to explain such modifications or
                        provide you access to previous versions of our Platform.</Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Equipment</Typography><br/>
                    <Typography variant="body1">In order to access the Platform, you may be required to purchase
                        “Equipment” such as
                        a computer or other mobile device. You are solely responsible for purchasing and
                        properly connecting such Equipment to access the Platform. Please be aware that
                        standard data, calling, and message rates apply. Please consult your communications
                        provider for a full list of fees and charges. Medcore shall not be liable to you for any
                        failure or issue related to any Equipment you may use to interact with the
                        Platform.</Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Communications</Typography><br/>
                    <Typography variant="body1">You represent, warrant, and agree to abide by all relevant laws
                        including but not limited
                        to the Telephone Consumer Protection Act 47 USC § 227 (“TCPA”) and the Controlling
                        the Assault of Non-Solicited Pornography And Marketing of 2003 (“Can-Spam”) when
                        using the Platform to send communications. You shall solely send communications that
                        are considered informational or for business purposes only. As required by Can-Spam
                        and TCPA you agree to do the following: (i) maintain opt out lists and remove users who
                        do not wish to receive further communications; (ii) collect opt ins and consents where
                        required; and (iii) promptly respond to any complaints regarding TCPA or Can-Spam by
                        users of the Platform. Where we believe in our discretion that you have not complied
                        with this Section we may suspend your access to the Platform or your ability to send
                        communications immediately or at any time. You shall fully indemnify and hold
                        harmless Medcore for all claims related to TCPA and Can-Spam whether made by a
                        third party or us. You agree that no information sent shall be considered “protected
                        health information” as defined under The Health Insurance Portability and Accountability
                        Act of 1996.</Typography><br/>

                    <Typography variant="h6" fontWeight={900}>User Privacy</Typography><br/>
                    <Typography variant="body1">We value your privacy and understand your privacy concerns. Our Privacy
                        Policy is
                        incorporated into this Agreement, and it governs your submission of information to our
                        Platform. Please review our Privacy Policy so that you may understand our privacy
                        practices. All information we collect is subject to our Privacy Policy, and by using the
                        Platform you consent to all actions taken by us with respect to your information in
                        compliance with the Privacy Policy.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Use of the Platform</Typography><br/>
                    <Typography variant="body1">When using our Platform, you are responsible for your use of the
                        Platform.
                        You agree to
                        the following:
                        <List sx={{
                            listStyleType: 'disc',
                            pl: 2,
                            '& .MuiListItem-root': {
                                display: 'list-item',
                                paddingLeft: 0
                            }
                        }}
                        >
                            <ListItem>You way not copy, distribute, access, or disclose any part of the Platform in
                                any medium, including without limitation by any automated or non-
                                automated “scraping” or unauthorized use of the server API;</ListItem>
                            <ListItem>You may not attempt to interfere with, compromise the system integrity or
                                security, or decipher any transmissions to or from the servers running the
                                Platform or otherwise compromise the Platform using viruses, worms, or
                                other hacking methods;</ListItem>
                            <ListItem>You may not use any robot, spider, crawler, scraper or other automated
                                means or interface not provided by us to access the Platform or to extract
                                data;</ListItem>
                            <ListItem>You may not use automated bots or other software to send more messages
                                through our Platform than humanly possible;</ListItem>
                            <ListItem>You may not use the Platform on a computer that is used to operate nuclear
                                facilities, life support, or other mission critical applications where life or
                                property may be at stake;</ListItem>
                            <ListItem>You may not decompile, reverse engineer, disassemble, modify, rent, sell,
                                lease, loan, distribute, or create derivative works or improvements to the
                                Platform or any portion of it;</ListItem>
                            <ListItem>You may not access our Platform in an attempt to build a similar or other
                                competitive product;</ListItem>
                            <ListItem>You may not use the Platform in an unlawful manner;</ListItem>
                            <ListItem>You may not take any action that imposes, or may impose at our sole
                                discretion, an unreasonable or disproportionately large load on our
                                infrastructure;</ListItem>
                            <ListItem>You may not collect or harvest any personally identifiable information,
                                including account names, except where permitted, from the Platform;</ListItem>
                            <ListItem>You may not violate or infringe other people&apos;s intellectual property,
                                privacy,
                                or other contractual rights while using our Platform;</ListItem>
                            <ListItem>You may not violate any requirements, procedures, policies or regulations of
                                networks connected to Medcore;</ListItem>
                            <ListItem>You may not sell, lease, loan, distribute, transfer, or sublicense the Platform
                                or access to it or derive income from the use or provision of the Platform
                                unless enabled through the functionality of our Platform;</ListItem>
                            <ListItem>You may not interfere with or disrupt the Platform;</ListItem>
                            <ListItem>You agree not to use the Platform in any way that is: misleading, unlawful,
                                defamatory, obscene, invasive, threatening, or harassing;</ListItem>
                            <ListItem>You agree that you will not hold Medcore responsible for your use of our
                                Platform; and</ListItem>
                            <ListItem>You agree not to cause, or aid in, the disruption, destruction, manipulation,
                                removal, disabling, or impairment of any portion of our Platform, including
                                the de-indexing or de-caching of any portion of our Platform from a thirty
                                party’s website, such as by requesting its removal from a search engine;</ListItem>
                        </List>
                        <p>
                            If you are discovered to be undertaking any of the aforementioned actions your
                            privileges to use our Platform may at our discretion be terminated or suspended.
                            Additionally, if we believe that your actions may harm us or a third party we may
                            suspend or terminate your use of the Platform. Generally, we will provide an
                            explanation for any suspension or termination of your use of any of our Platform, but
                            Medcore reserves the right to suspend or terminate any account at any time without
                            notice or explanation.
                        </p>
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>User Content</Typography><br/>
                    <Typography variant="body1">A Users ability to submit or transmit any information through the
                        Platform including but
                        not limited to account information, logins, data, passwords, information, written content,
                        images, videos, or any other information will be referred to as “User Content” throughout
                        this Agreement. Please be aware that we are not required to host, display, migrate, or
                        distribute any of your User Content and we may refuse to accept or transmit any User
                        Content. You agree that you are solely responsible for any User Content submitted
                        and you release us from any liability associated with any User Content submitted. We
                        may provide industry standard security but we cannot guarantee the absolute safety
                        and security of any such User Content. Any User Content found to be in violation of this
                        Agreement or that we determine to be harmful to the Platform may be modified, edited,
                        or removed at our discretion.
                        <p>
                            When submitting any User Content to our Platform you represent and warrant that you
                            own all rights to the User Content, you have paid any fees to use or license the User
                            Content, or you otherwise have the permission and right to use any User Content.
                            Furthermore, you represent and warrant that all User Content is legal and the User
                            Content does not interfere with any third party rights or obligations.
                        </p>
                        <p>
                            When you submit any User Content to us, you grant Medcore, its partners, affiliates,
                            Users, representatives and assigns a non-exclusive, limited, fully-paid, royalty-free,
                            revocable, world-wide, universal, transferable, assignable license to display, distribute,
                            store, broadcast, transmit, reproduce, modify, prepare derivative works, or use and
                            reuse all or part of your User Content for the purposes of providing you any services
                            associated with the Platform for the duration of this Agreement. Please be aware that
                            User Content may be shared with third parties. Medcore has no liability to Users for any
                            User Content including all data, information, copy, images, URL names, and anything
                            else submitted by you or any third parties using the Platform. Specifically, Medcore
                            shall not be liable for any errors related to any User Content. We reserve the right to
                            remove, delete, modify, screen, edit, or refuse any User Content for any reason or no
                            reason, and with or without notice to you.
                        </p>
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Monitoring User Content</Typography><br/>
                    <Typography variant="body1">
                        Medcore shall have the right, but not the obligation, to monitor all User Content on the
                        Platform at all times, to determine compliance with this Agreement and any guidelines
                        established by us. Without limiting the foregoing, Medcore shall have the right, but not
                        the obligation, to remove any User Content at its sole discretion. For example, we may
                        remove User Content if we believe that any User Content may harm us or our business
                        interests or violate any United States laws or regulations. We have no obligation to
                        retain or provide you with copies of any User Content after your termination of this
                        Agreement.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Platform Availability</Typography><br/>
                    <Typography variant="body1">
                        Although we try to provide continuous availability to you, we do not guarantee that the
                        Platform along with any offered Medcore Services will always be available, work, or be
                        accessible at any particular time. Specifically, we do not guarantee any uptime or
                        specific availability of the Platform. You agree and acknowledge that the Platform uses
                        remote access and may not always be either 100% reliable or available. We reserve
                        the right to refuse service to the Platform or to terminate your access at any time. We
                        cannot guarantee that anything found on our Platform will work to the functionality
                        desired by you or give you any desired results.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Intellectual Property</Typography><br/>
                    <Typography variant="body1">
                        The name “Medcore” along with the design of the Medcore Platform and any text,
                        writings, images, templates, scripts, graphics, interactive features and any trademarks
                        or logos contained therein (&apos;Marks&apos;), are owned by or licensed to Medcore, subject to
                        copyright and other intellectual property rights under United States’ and foreign laws
                        and international conventions. Medcore reserves all rights not expressly granted in and
                        to the Platform. You agree to not engage in the use, copying, or distribution of anything
                        contained within the Platform unless we have given express written permission.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Idea Submission</Typography><br/>
                    <Typography variant="body1">
                        Medcore or any of its employees do not accept or consider unsolicited ideas, including
                        but not limited to ideas relating to processes, technologies, product enhancements, or
                        product names. Please do not submit any unsolicited ideas, content, artwork,
                        suggestions, or other works (“Submissions”) in any form to Medcore. The sole purpose
                        of this policy is to avoid potential misunderstandings or disputes when Medcore’
                        products might seem similar to ideas you submitted to Medcore. Where you submit any
                        Submission, whether solicited or unsolicited, you agree that: (1) your Submissions and
                        their contents will automatically become the property of Medcore, without any
                        compensation to you; (2) Medcore may use or redistribute the Submissions and their
                        contents for any purpose and in any way; (3) there is no obligation for Medcore to
                        review the Submission; and (4) there is no obligation to keep any Submissions
                        confidential.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Third Party Content</Typography><br/>
                    <Typography variant="body1">
                        Medcore does not endorse or warrant any Third Party Content including but not limited
                        to any materials, results, information, or other data provided by a third party (collectively
                        “Third Party Content”) and does not assume responsibility for any Third Party Content
                        encountered whether through the Platform or otherwise. If you use or access any Third
                        Party Content you do so at your own risk. We recommend that you independently
                        evaluate Third Party Content and you should not solely rely on the Third Party Content
                        displayed on the Platform to make any decisions. If you require more information
                        regarding any Third Party Content, please contact the owner of such Third Party
                        Content. Further, Medcore assumes no liability for inaccuracies or misstatements made
                        by such third parties responsible for such Third Party Content. Medcore does not
                        endorse, recommend, verify, or warrant any Third Party Content or any third parties
                        encountered on the Platform.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Pricing</Typography><br/>
                    <Typography variant="body1">
                        The pricing for any Medcore Services or purchases is stated within the Platform. We
                        reserve the right to increase or modify the pricing on our Platform at any time and at our
                        discretion and we have no obligation to offer any Medcore Services at prices previously
                        offered. We may, at any time change, modify or eliminate pricing or Medcore Services
                        with or without notice. We shall not be liable to you or to any third-party for any
                        modification, price change, suspension or discontinuance of any Medcore Services
                        offered. In the event of a price increase, Medcore may notify you and you will have the
                        chance to accept or reject any price increase prior to any renewal. Occasionally there
                        may be pricing errors on the Platform. We reserve the right to correct any errors,
                        inaccuracies or omissions, and to change or update such information as required
                        without notice.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Export Controls</Typography><br/>
                    <Typography variant="body1">
                        You represent and warrant that: (1) you are not located in a country that is subject to a U.S.,
                        E.U., or U.K. government embargo, that has been designated by the U.S. government as a
                        “terrorist supporting” country, or listed within a “Do Not Export” list; and (2) you are not
                        listed
                        on any U.S. government list of prohibited or restricted parties.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Disclaimer</Typography><br/>
                    <Typography variant="body1">
                        HE PLATFORM IS PROVIDED ON AN &apos;AS IS&apos;, &apos;AS AVAILABLE&apos; AND &apos;WITH ALL
                        FAULTS&apos; BASIS. TO THE FULLEST EXTENT PERMISSIBLE BY LAW, NEITHER
                        MEDCORE, NOR ANY OF OUR EMPLOYEES, DIRECTORS, OFFICERS, ASSIGNS,
                        AFFILIATES, OR AGENTS MAKE ANY REPRESENTATIONS OR WARRANTIES OR
                        ENDORSEMENTS OF ANY KIND WHATSOEVER, EXPRESS OR IMPLIED,
                        INCLUDING BUT NOT LIMITED TO: (1) THE PLATFORM OR ANY MEDCORE
                        SERVICES; (2) ANY INFORMATION OR CONTENT PROVIDED VIA THE
                        PLATFORM; OR (3) SECURITY ASSOCIATED WITH THE TRANSMISSION OF
                        INFORMATION TO MEDCORE, OR VIA THE PLATFORM. THE SERVICE IS SOLELY
                        INTENDED FOR EDUCATIONAL AND INFORMATIONAL PURPOSES. THE PLATFORM
                        CANNOT GUARANTEE THAT ANY ACTIONS YOU UNDERTAKE WHILE USING THE
                        PLATFORM WILL BE COMPLETED OR WILL BE COMPLETELY ERROR FREE. YOU
                        ARE SOLELY RESPONSIBLE FOR CONFIRMING ANY ACTIONS REQUESTED ARE
                        COMPLETED AS DESIRED BY YOU.
                        IN ADDITION, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
                        INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY,
                        FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, TITLE, CUSTOM,
                        TRADE, QUIET ENJOYMENT, SYSTEM INTEGRATION, AND FREEDOM FROM
                        COMPUTER VIRUS. MEDCORE DOES NOT REPRESENT OR WARRANT THAT THE
                        PLATFORM WILL BE ERROR-FREE OR UNINTERRUPTED; THAT DEFECTS WILL
                        BE CORRECTED; OR THAT THE PLATFORM OR THE SERVER THAT MAKES THE
                        PLATFORM AVAILABLE IS FREE FROM ANY HARMFUL COMPONENTS.
                        MEDCORE DOES NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT
                        THE INFORMATION (INCLUDING ANY MEDCORE CONTENT) ON THE PLATFORM
                        IS ACCURATE, COMPLETE, OR USEFUL. MEDCORE DOES NOT WARRANT THAT
                        YOUR USE OF THE PLATFORM IS LAWFUL IN ANY PARTICULAR JURISDICTION,
                        AND MEDCORE SPECIFICALLY DISCLAIMS ANY SUCH WARRANTIES. NO ADVICE
                        OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM MEDCORE OR
                        THROUGH THE SITE, APPLICATION, SERVICES OR COLLECTIVE CONTENT, WILL
                        CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Limitation of Liability</Typography><br/>
                    <Typography variant="body1">
                        BY ACCESSING AND/OR UTILIZING THE MEDCORE PLATFORM AND/OR THE
                        MEDCORE SERVICES, AND/OR BY REGISTERING AN ACCOUNT, YOU FULLY
                        RELEASE MEDCORE FROM ALL LIABILITY ARISING FROM, BUT NOT LIMITED TO,
                        PROPERTY DAMAGE, DEATH, PERSONAL INJURY, PERSONAL LOSS AND OTHER
                        CIRCUMSTANCES RESULTING FROM ERRORS, INTERACTIONS AND FAILURES
                        INVOLVING THE MEDCORE SERVICES, OR ERRORS IN THE MEDCORE SERVICES
                        OR CONTENT PROVIDED BY MEDCORE. IN NO EVENT SHALL MEDCORE, ITS
                        OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, OR AGENTS, BE LIABLE TO
                        YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR
                        CONSEQUENTIAL DAMAGES, WHETHER BASED ON WARRANTY, CONTRACT,
                        TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, AND
                        WHETHER OR NOT MEDCORE IS ADVISED OF THE POSSIBILITY OF SUCH
                        DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE
                        FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION. YOU
                        MAY ALSO HAVE OTHER LEGAL RIGHTS THAT VARY FROM STATE TO STATE.
                        SPECIFICALLY, IN THOSE JURISDICTIONS NOT ALLOWED, WE DO NOT
                        DISCLAIM LIABILITY FOR: (1) DEATH OR PERSONAL INJURY CAUSED BY
                        MEDCORE’S NEGLIGENCE OR THAT OF ANY OF ITS OFFICERS, EMPLOYEES OR
                        AGENTS; (2) FRAUDULENT MISREPRESENTATION; OR (3) ANY LIABILITY WHICH
                        IT IS NOT LAWFUL TO EXCLUDE EITHER NOW OR IN THE FUTURE. WHERE A
                        TOTAL DISCLAIMER OF LIABILITY IS DISALLOWED YOU AGREE THAT OUR
                        TOTAL LIABILITY TO YOU SHALL NOT EXCEED THE AMOUNTS YOU HAVE PAID
                        TO US IN THE PAST SIX (6) MONTHS OR ONE HUNDRED USD, WHICHEVER IS
                        GREATER.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Release</Typography><br/>
                    <Typography variant="body1">
                        In addition to the above, you specifically and expressly release Medcore, its respective
                        officers, directors, employees, agents, successors and assigns from all liability, direct and
                        indirect, related to any and all claims and demands you may assert against any third party
                        arising out of your use of the Medcore Platform. If you are a California resident, you expressly
                        agree to waive California Civil Code Section 1542, which states, “A general release does not
                        extend to claims which the creditor does not know or suspect to exist in his or her favor at the
                        time of executing the release, which if known by him or her must have materially affected his
                        or her settlement with the debtor.”
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Indemnity</Typography><br/>
                    <Typography variant="body1">
                        You agree to defend, indemnify and hold harmless Medcore, its officers, directors,
                        employees, affiliates, and agents, from and against any and all claims, damages,
                        obligations, losses, liabilities, costs or debt, and expenses (including but not limited to
                        attorney&apos;s fees) arising from:<br/>

                        <List sx={{
                            listStyleType: 'disc',
                            pl: 2,
                            '& .MuiListItem-root': {
                                display: 'list-item',
                                paddingLeft: 0
                            }
                        }}
                        >
                            <ListItem>your use of and access to the Medcore Platform or for Subscriber its Authorized
                                User’s use of the Platform;</ListItem>
                            <ListItem>your violation of any term of this Agreement or the Privacy Policy; or</ListItem>
                            <ListItem>your violation of any third party right, including without limitation any
                                copyright,
                                property, or contractual right.</ListItem>
                        </List>

                        <p>
                            This defense and indemnification obligation will survive this Agreement and your use of
                            the Medcore Platform. You also agree that you have a duty to defend us against such
                            claims and we may require you to pay for an attorney(s) of our choice in such cases.
                            You agree that this indemnity extends to requiring you to pay for our reasonable
                            attorneys’ fees, court costs, and disbursements. In the event of a claim such as one
                            described in this paragraph, we may elect to settle with the party/parties making the
                            claim and you shall be liable for the damages as though we had proceeded with a trial.
                            If you are a business entity these indemnification obligations extend to all of your
                            authorized users.
                        </p>
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Copyrights</Typography><br/>
                    <Typography variant="body1">
                        We take copyright infringement very seriously. If you believe that any content owned by
                        you has been infringed upon please send us a message which contains:<br/>

                        <List sx={{
                            listStyleType: 'disc',
                            pl: 2,
                            '& .MuiListItem-root': {
                                display: 'list-item',
                                paddingLeft: 0
                            }
                        }}
                        >
                            <ListItem>Your name.</ListItem>
                            <ListItem>The name of the party whose copyright has been infringed, if different from your
                                name.</ListItem>
                            <ListItem>The name and description of the work that is being infringed</ListItem>
                            <ListItem>The location on our Platform of the infringing copy.</ListItem>
                            <ListItem>A statement that you have a good faith belief that use of the copyrighted work
                                described above is not authorized by the copyright owner (or by a third party who
                                is legally entitled to do so on behalf of the copyright owner) and is not otherwise
                                permitted by law.</ListItem>
                            <ListItem>A statement that you swear, under penalty of perjury, that the information
                                contained in this notification is accurate and that you are the copyright owner or
                                have an exclusive right in law to bring infringement proceedings with respect to
                                its use.</ListItem>
                        </List>

                        <p>
                            You must sign this notification and send it to our Copyright Agent: Copyright Agent of
                            Medcore, info@medcoremd.com or Medcore MD, 527 Pine, Ave., Hermosa Beach, CA
                            90254.
                        </p>
                        <p><u>Counter Notice</u></p>
                        <p>In the event that you receive a notification from Medcore stating content posted by you
                            has been subject to a copyright takedown notice, you may respond by filing a counter-
                            notice pursuant to the DMCA. Your counter-notice must contain the following:
                        </p>
                        <List sx={{
                            listStyleType: 'disc',
                            pl: 2,
                            '& .MuiListItem-root': {
                                display: 'list-item',
                                paddingLeft: 0
                            }
                        }}
                        >
                            <ListItem>Your name, address, email and physical or electronic signature.</ListItem>
                            <ListItem>The notification reference number (if applicable).</ListItem>
                            <ListItem>Identification of the material and its location before it was removed.</ListItem>
                            <ListItem>A statement under penalty of perjury that the material was removed by mistake
                                or misidentification.
                            </ListItem>
                            <ListItem>Your consent to the jurisdiction of a federal court in the district where you live
                                (if
                                you are in the U.S.), or your consent to the jurisdiction of a federal court in the
                                district where the notice provider is located (if you are not in the US).</ListItem>
                            <ListItem>Your consent to accept service of process from the party who submitted the
                                takedown notice.
                            </ListItem>
                        </List>
                        <p>
                            Please be aware that we may not take any action regarding your counter-notice unless
                            your notification strictly complies with the foregoing requirements. Please send this
                            counter-notice in accordance with the takedown notice instructions above.
                        </p>

                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Choice of Law</Typography><br/>
                    <Typography variant="body1">
                        This Agreement shall be governed by the laws in force within the state of California. The
                        offer and acceptance of this contract is deemed to have occurred in California.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Disputes</Typography><br/>
                    <Typography variant="body1">
                        Any dispute relating in any way to your visit to the Platform or the Medcore Services
                        shall be submitted to confidential arbitration in Los Angeles, CA. Arbitration under this
                        Agreement for  Users shall be conducted pursuant to the applicable Commercial Rules
                        (“Rules”) then prevailing at the American Arbitration Association.  Arbitration shall be
                        conducted in English by one (1) arbitrator as selected pursuant to the Rules; the
                        arbitrator&apos;s award shall be final and binding and may be entered as a judgment in any
                        court of competent jurisdiction.  Each party shall be responsible for their own arbitration
                        fees and costs. To the fullest extent permitted by applicable law, no arbitration under
                        this Agreement shall be joined to an arbitration involving any other party subject to this
                        Agreement, whether through class action proceedings or otherwise. Where permitted by
                        the Rules, both parties may make any and all appearances telephonically, in written
                        briefs, or electronically. You agree that regardless of any statute or law to the contrary,
                        any claim or cause of action arising out of, related to or connected with the use of the
                        Platform or this Agreement must be filed within one (1) year after such claim or cause of
                        action arose or be forever banned.   Notwithstanding the foregoing, either party may
                        seek equitable relief to protect its interests (including but not limited to injunctive relief),
                        or make a claim for nonpayment, in a court of appropriate jurisdiction, and issues of
                        intellectual property ownership or infringement may be decided only by a court of
                        appropriate jurisdiction and not by arbitration. In the event that the law does not permit
                        the abovementioned dispute to be resolved through arbitration or if this arbitration
                        agreement is unenforceable, you agree that any actions and disputes shall be brought
                        solely in a court of competent jurisdiction located within Los Angeles, CA.<br/>
                        <p><u>Opt-Out</u></p>
                        <p>
                            You may opt-out of this dispute resolution provision by notifying Medcore within 30 days
                            of the date on which you entered into this Agreement or purchased a subscription,
                            whichever is earlier. You must do so by writing to Medcore MD, 527 Pine, Ave.,
                            Hermosa Beach, CA 90254, attn: Arbitration Opt-Out, and you must include your name,
                            address, account information, and a clear statement that you do not wish to resolve
                            disputes with Medcore through arbitration.  Where you opt out of arbitration, all disputes
                            shall be heard in a court of competent jurisdiction located within Los Angeles, CA.
                        </p>
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>PAGA and Class Action Waiver</Typography><br/>
                    <Typography variant="body1">
                        You and Medcore agree that any proceedings to resolve or litigate any dispute whether
                        through a court of law or arbitration shall be solely conducted on an individual basis.
                        You agree that you will not seek to have any dispute heard as a class action,
                        representative action, collective action, or private attorney general action.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Severability</Typography><br/>
                    <Typography variant="body1">
                        In the event that a provision of this Agreement is found to be unlawful, conflicting with
                        another provision of the Agreement, or otherwise unenforceable, the Agreement will
                        remain in force as though it had been entered into without that unenforceable provision
                        being included in it.<br/>

                        <p>
                            If two or more provisions of this Agreement or any other agreement you may have with
                            Medcore are deemed to conflict with each other’s operation, Medcore shall have the
                            sole right to elect which provision remains in force.
                        </p>
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Non-Waiver</Typography><br/>
                    <Typography variant="body1">
                        We reserve all rights permitted to us under this Agreement as well as under the
                        provisions of any applicable law.  Our non-enforcement of any particular provision or
                        provisions of this Agreement or any applicable law should not be construed as our
                        waiver of the right to enforce that same provision under the same or different
                        circumstances at any time in the future.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Assignment and Survival</Typography><br/>
                    <Typography variant="body1">
                        You may not assign your rights and/or obligations under this Agreement to any other
                        party without our prior written consent.  We may assign our rights and/or obligations
                        under this Agreement to any other party at our discretion.  All portions of this Agreement
                        that would reasonably be believed to survive termination shall survive and remain in full
                        force upon termination, including but not limited to the Limitation of Liabilities,
                        Representation and Warranties, Access, Payment, Indemnification, and Arbitration.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Termination</Typography><br/>
                    <Typography variant="body1">
                        You may terminate your access to the Platform or terminate this Agreement any time
                        via your Medcore dashboard or contacting us at info@medcoremd.com.  However,
                        termination does not relieve you from any obligations or payments required by this
                        Agreement. Please be aware that upon termination of your account or subscription,
                        access to all or portions of our Platform may become immediately disabled and any
                        User Content may become immediately inaccessible.  We may terminate this
                        Agreement or your access to the Platform at our discretion or if we determine that: (1)
                        you have violated any applicable laws while using our Platform; (2) if you have violated
                        any portion of this Agreement or any of our Platform policies; or (3) if we believe that
                        any of your current or future actions may legally harm Medcore, our business interests
                        or a third party, at our discretion.  In the event of termination, we will strive to provide
                        you with a timely explanation; however, we are not required to do so.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Entire Agreement</Typography><br/>
                    <Typography variant="body1">
                        This Agreement, the Additional Terms, and the Privacy Policy and any other
                        supporting agreements provided by Medcore constitute the complete and exclusive
                        understanding and agreement between the parties regarding the subject matter herein
                        and supersede all prior or contemporaneous agreements or understandings written or
                        oral, relating to its subject matter. Any waiver, modification or amendment of any
                        provision of this Agreement will be effective only if in writing and signed by a duly
                        authorized representative of each party.  Where any agreements directly conflict they
                        will control in the following order (from 1-3): (1) The Additional Terms; (2) this
                        Agreement; and (3) the Privacy Policy.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Amendments</Typography><br/>
                    <Typography variant="body1">
                        We may amend this Agreement from time to time.  When we amend this Agreement, we
                        will update this page and indicate the date that it was last modified or we may email
                        you.  You may refuse to agree to the amendments, but if you do, you must immediately
                        cease using our Platform.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Electronic Communications</Typography><br/>
                    <Typography variant="body1">
                        The communications between you and Medcore use electronic means, whether you
                        visit the Platform or send Medcore e-mails, or whether Medcore posts notices on the
                        Platform or communications with you via mobile notifications or e-mail.  For contractual
                        purposes, you (1) consent to receive communications from Medcore in an electronic
                        form; and (2) agree that all terms, conditions, agreements, notices, disclosures, and
                        other communications that Medcore provides to you electronically satisfy any legal
                        requirement that such communications would satisfy if it were to be in writing.  The
                        foregoing does not affect your statutory rights.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Notices</Typography><br/>
                    <Typography variant="body1">
                        Any notices required under this Agreement shall be delivered to Medcore MD, 527 Pine,
                        Ave., Hermosa Beach, CA 90254, delivery confirmation required.  Any notices to User
                        shall be delivered either electronically via email or to the User’s account within the
                        Platform.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Contact Us</Typography><br/>
                    <Typography variant="body1">
                        Where you have any questions, issues, or if you are having trouble accessing or using
                        the Platform, please contact us at info@medcoremd.com.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Acts of God</Typography><br/>
                    <Typography variant="body1">
                        All errors shall be at the sole responsibility and expense of the user. Additionally, we
                        shall not be liable for any inconvenience, loss, liability, or damage resulting from any
                        interruption of the Platform, directly or indirectly caused by, or proximately resulting
                        from, any circumstances beyond our control, including, but not limited to, causes
                        attributable to you, inability to access to the Platform, failure of a communications
                        satellite, strike, labor dispute, riot or insurrection, war, explosion, malicious mischief,
                        fire, flood, lightning, earthquake, wind, ice, extreme weather conditions, or other acts of
                        God, failure or reduction of power, or any court order, law, act or order of government
                        restricting or prohibiting the operation of or access to the Platform.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>California Users</Typography><br/>
                    <Typography variant="body1">
                        Pursuant to California Civil Code Section 1789.3, any questions about pricing,
                        complaints, or inquiries about Medcore must be sent to our agent for notice to:
                        info@medcoremd.com or Medcore MD, 527 Pine, Ave., Hermosa Beach, CA 90254.<br/>
                        <p>
                            California Users are also entitled to the following specific consumer rights notice: The
                            Complaint Assistance Unit of the Division of Consumer Services of the California
                            Department of Consumer Affairs may be contacted in writing at 1625 North Market
                            Blvd., Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.
                        </p>
                    </Typography><br/>

                </Container>

            </Container>
            <Footer/>
        </>
    );
};

export default TermsOfService
