import React, {useEffect, useState} from 'react'
import {Container, Stack, Typography} from '@mui/material'
import {useTheme} from '@mui/styles'
import CodeInput from '../UI/CodeInput'
import {useTypedDispatch, useTypedSelector} from '../../store'
import {
	verifyEmail,
	verifyRequestEmail,
} from '../../store/actions/authActions/verifyEmailAction'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'

const ConfirmMail: React.FC = () => {
	const medical = useTheme()
	const dispatch = useTypedDispatch()
	const {status, tokenVerify, accessToken} = useTypedSelector(
		state => state.verifyEmailReducer
	)

	const [seconds, setSeconds] = useState<number>(0)
	//
	useEffect(() => {
		window.scrollTo(0, 0)
		window.dispatchEvent(new Event('scroll'));
		codeIsSent()
	}, [])

	const codeIsSent = () => {
		if (seconds > 0) {
			return
		}

		setSeconds(60)
		let mySeconds = 60
		const intervalId = setInterval(() => {
			mySeconds = mySeconds - 1
			setSeconds(mySeconds)

			if (mySeconds === 0) {
				clearInterval(intervalId)
				setSeconds(0)
			}
		}, 1000)
	}

	const [codeValue, _setValue] = useState<string>('')

	// TODO need to check
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const setValue = (v: any) => {
		_setValue(v)
	}

	const handleSubmit = (codeValue: string) => {
		// Set event handler for the form submit event.

		// Dispatch the action.
		dispatch(verifyEmail({verifyCode: codeValue, tokenVerify}))
	}

	const verifyRequestSubmit = () => {
		dispatch(verifyRequestEmail({accessToken: accessToken}))
		codeIsSent()
	}

	const codeInput = <CodeInput value={codeValue} onChange={setValue} />

	// Set code is sent

	return (
		<Container maxWidth="lg">
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				maxWidth="680px"
				m="0 auto"
				p="45px"
				position="relative"
				sx={{
					boxShadow: '10px 10px 20px #0000001A',
					[medical.breakpoints.down('md')]: {maxWidth: '70%'},
					[medical.breakpoints.down('sm')]: {maxWidth: '100%'},
					p: '25px',
				}}
			>
				<Typography variant="h4" mb="10px" textAlign="center">
					AUTHENTICATE YOUR ACCOUNT
				</Typography>
				<Typography textAlign="center" mb="50px">
					To confirm your identity, authenticate your account using the one-time
					code sent to your email.
				</Typography>

				{codeInput}

				{/*{status === 'rejected' && <span style={{color: 'red', fontSize: '14px'}}>Code is wrong</span>}*/}
				<Typography
					variant="body2"
					color={medical.colors.lightGrey}
					textAlign="center"
				>
					It may take a minute to receive your code.
					<br />
				</Typography>

				<Typography
					variant="body2"
					textAlign="center"
					color={medical.colors.lightGrey}
				>
					{seconds > 0 ? (
						<span>
							Verification code can be resent after <b>{seconds}s</b>
						</span>
					) : (
						<span>
							Haven&apos;t reviewed it?
							<span
								onClick={() => {
									verifyRequestSubmit()
								}}
								style={{
									cursor: 'pointer',
									color: medical.colors.blue,
									fontWeight: 600,
								}}
							>
								{' '}
								Resend a new code.
							</span>
						</span>
					)}
				</Typography>
				<LoadingButton
					size={'large'}
					disabled={codeValue.length < 6}
					onClick={() => handleSubmit(codeValue)}
					loading={status === 'loading'}
					variant="contained"
					fullWidth
					sx={{
						width: '100%',
						maxWidth: '250px',
						marginTop: '80px',
					}}
				>
					Submit
				</LoadingButton>

				{/*{status === 'rejected' && (
					<NotificationPopup error>{error}</NotificationPopup>
				)}*/}
			</Stack>
		</Container>
	)
}

export default ConfirmMail
