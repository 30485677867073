import authService from '../../../services/authService';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {forgotPasswordForm, resetPasswordForm} from '../../types';
import {recaptchaExecute, recaptchaIsReady} from "../../../helpers/recaptcha";
import {openPopup} from "../../slices/popup.slice";

export const forgotPassword = createAsyncThunk<undefined, forgotPasswordForm, { rejectValue: string }>(
// action type string
    'password/request-reset',
// callback function

    async ({email}, thunkApi) => {
        try {
            if (!recaptchaIsReady()) {
                thunkApi.dispatch(openPopup({
                    message: 'reCAPTCHA is not ready yet. Please try again later.',
                    type: 'error',
                }))
                return;
            }

            const recaptcha_token = await recaptchaExecute('REQUEST_RESET_PASSWORD');
            if (!recaptcha_token) {
                thunkApi.dispatch(openPopup({
                    message: 'reCaptcha token is not valid. Please try again later.',
                    type: 'error',
                }))
                return;
            }

            const response = await authService.forgotPassword(email, recaptcha_token)
            const {success, message} = response.data

            if (!success) {
                thunkApi.dispatch(openPopup({
                    message: message,
                    type: 'error',
                }))
                return thunkApi.rejectWithValue(message)
            }

            thunkApi.dispatch(openPopup({
                message: 'A recovery link was sent to your mailbox. Please check!',
                type: 'sent',
            }))

            return success

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            thunkApi.dispatch(openPopup({
                message: error.message,
                type: 'error',
            }))
            return thunkApi.rejectWithValue(error.message)
        }
    }
)

export const resetPassword = createAsyncThunk<undefined, resetPasswordForm, { rejectValue: string }>(
// action type string
    'password/reset',
// callback function

    async ({resetPasswordToken, password, confirmPassword}, thunkApi) => {
        try {
// make request to backend
            const response = await authService.resetPassword(resetPasswordToken, password, confirmPassword)
            const {success, error} = response.data

            if (!success) {
                if (error.msg) {
                    const errorMessage = error.msg ? error.msg : 'Send is fail. Please try again later!'
                    return thunkApi.rejectWithValue(errorMessage)
                } else if (error.data.msg) {
                    return thunkApi.rejectWithValue(error.data.msg)
                }
            }

            return success

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (error) {
                return thunkApi.rejectWithValue(error.message)
            }
        }
    }
)
