import React, {useEffect} from 'react'
import {Container, Typography} from '@mui/material'
import Footer from '../components/Footer'
import {medical} from "../theme";

const AdditionalTerms = () => {
    useEffect(() => {
        document.title = 'Additional Terms for Subscribers | MedcoreMD';
    }, []);

    return (
        <>
            <Container
                maxWidth={false}
                disableGutters={true}
                sx={{
                    mt: '100px',
                    mb: '100px',
                    flex: 1,
                    [medical.breakpoints.down('md')]: {
                        mt: '50px',
                        mb: '50px',
                    },
                    [medical.breakpoints.down('sm')]: {
                        mt: '30px',
                        mb: '30px',
                    }
                }}
            >
                <Container maxWidth="xl">
                    <Typography variant="h2">Additional Terms for Subscribers </Typography><br/>
                    <Typography variant="body1">Last updated: July 14, 2023</Typography>
                    <p>
                        Welcome to the Additional Terms for Subscribers (“Additional Terms”). Each
                        Subscriber must agree to these Additional Terms. These Additional Terms are subject
                        to, integrated in, and incorporated into the Medcore User Terms (“User Terms”). Any
                        capitalized terms that are undefined in these Additional Terms shall have the meanings
                        as set forth within the User Terms
                    </p>

                    <Typography variant="h6" fontWeight={900}>Subscribers</Typography><br/>
                    <Typography variant="body1">Subscriber’s shall have additional obligations within this Agreement as
                        set forth,
                        Subscriber represents and warrants that: (1) Subscriber shall use the Platform solely for
                        Subscriber’s internal commercial purposes in accordance with this Agreement; (2) the
                        person entering into this Agreement on behalf of Subscriber is duly authorized and has
                        the power and authority to enter into this Agreement and bind Subscriber; (3) any User
                        Content posted shall be professional, lawful, accurate, and up-to-date; and (4)
                        Subscriber shall use the Platform only for legal and lawful purposes and agrees to abide
                        by all laws of its local jurisdiction when using the Platform including but not limited to
                        laws relating to marketing or communications. Subscriber shall indemnify and hold
                        Medcore harmless for a breach of any provisions of this section. Where you have
                        violated these Additional Terms or the User Terms we reserve the right to release your
                        information to law enforcement in order to assist with any investigation at our discretion.
                        Where required, Subscriber shall clearly post a privacy policy (in compliance with the
                        laws of Subscriber’s local jurisdiction) prior to collecting any information and shall
                        disclose to its Authorized Users how such personal information may be used.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Subscriber Payments</Typography><br/>
                    <Typography variant="body1">Subscriber agrees to pay for all costs, fees, and taxes listed when
                        purchasing access to
                        the Platform including any recurring subscription fees. Subscriber agrees to timely pay
                        such fees and understands that failure to pay such fees may result in termination or
                        suspension of the Platform and any Medcore Services. Medcore shall automatically bill
                        Subscriber on a recurring basis for access to the Medcore Services and Subscriber
                        shall timely pay all fees. Unless expressly stated otherwise, all prices are listed in
                        United States Dollars. Where Subscriber has failed to pay or where payments are
                        overdue, Medcore may suspend or terminate Subscriber’s access to the Platform,
                        without liability to us.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Credit Card Payments</Typography><br/>
                    <Typography variant="body1">Where Medcore accepts credit card payments, Subscriber must agree to our
                        third party
                        payment processors’ terms and conditions for processing payments. We reserve the
                        right to change our third party payment processor at our discretion. By providing
                        Medcore or its payment processor with Subscriber’s credit card information Subscriber
                        authorizes Medcore to charge Subscriber’s credit card for any invoiced amounts and
                        agrees no further authorization is required. All information that you provide in
                        connection with a payment or transaction must be accurate, complete, and current.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Automatic Payment and Renewal</Typography><br/>
                    <Typography variant="body1">IF SUBSCRIBER’S ACCOUNT HAS BEEN SET UP FOR AUTOMATIC BILLING,
                        MEDCORE MAY CHARGE SUBSCRIBER AUTOMATICALLY AND ON A RECURRING
                        BASIS (FOR THE SAME PERIOD OF SUBSCRIBER’S SUBSCRIPTION), UNLESS
                        SUBSCRIBER NOTIFIES US THAT SUBSCRIBER WANTS TO CANCEL
                        SUBSCRIBER’S AUTOMATIC PAYMENT. ADDITIONALLY, SUBSCRIBER
                        AUTHORIZES US TO BILL SUBSCRIBER ON A RECURRING BASIS UNTIL WE ARE
                        NOTIFIED OTHERWISE. SUBSCRIBER AGREES THAT NO ADDITIONAL CONSENT
                        IS REQUIRED BY SUBSCRIBER TO CHARGE SUBSCRIBER’S PREFERRED
                        PAYMENT METHOD AUTOMATICALLY AND ON A RECURRING BASIS FOR ANY
                        AMOUNTS CHARGED FOR SUBSCRIBER’S SUBSCRIPTION.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Refunds</Typography><br/>
                    <Typography variant="body1">We want Subscribers to be satisfied with their subscription; however
                        Medcore cannot
                        offer any refunds at this time. If you feel that you are entitled to a refund for any reason
                        please contact us.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Taxes</Typography><br/>
                    <Typography variant="body1">Where Medcore does not charge Subscriber taxes for any payments,
                        Subscriber agrees
                        to pay any and all applicable taxes and provide verification that it has done so.
                        Subscriber agrees to comply with any reasonable requests related to Subscriber’s tax
                        information or tax status.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Free Trials</Typography><br/>
                    <Typography variant="body1">Medcore may offer the Platform on a free trial basis. Medcore reserves
                        the right to
                        discontinue or modify any free trials (including any trial duration) at any time and without
                        liability. Where Subscriber has signed up for a free trial, Subscriber agrees that at the
                        expiration of Subscriber’s free trial Subscriber may be automatically charged for any
                        additional usage of the Platform in accordance with Medcore’ then current pricing.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Subscriber Holds</Typography><br/>
                    <Typography variant="body1">From time to time, Medcore may place a hold (“Hold”) on a Subscriber’s
                        account. Some
                        of the reasons that we may place a Hold on Subscriber’s account include but are not
                        limited to the following: (1) if we have reason that Subscriber’s actions have violated this
                        User Terms, may harm our business, are deceptive, misleading, unlawful, or have
                        harmed a third party or interfere with a third party contractual right; (2) at the request of
                        our payment processors or due to delayed payment; or (3) if required in order to comply
                        with a court order, subpoena, writ, injunction, or as otherwise required under applicable
                        laws and regulations. If Subscriber has questions about a Hold we may have placed on
                        Subscriber’s account, or need information about how to resolve the Hold, please contact
                        us. Additionally, we may suspend Subscriber’s access to the Platform while such Hold
                        is in place.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Support</Typography><br/>
                    <Typography variant="body1">Medcore may offer Subscribers standard support, in accordance with
                        Medcore’ internal
                        policies, when accessing, troubleshooting, or using the Platform. Support for the
                        Platform is offered on an as available basis and where a Subscriber requires any
                        support please contact us. Please be aware that Medcore reserves the right to charge
                        for any excess support required by Subscriber that is in excess of the standard support
                        offered by Medcore.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Authorized Users</Typography><br/>
                    <Typography variant="body1">The Platform may allow Subscriber to designate sub-accounts for its
                        authorized users
                        (“Authorized User(s)”) such as administrators, managers, employees, or contractors.
                        Please be aware that Subscriber is solely responsible for any interactions with any
                        users that it has authorized or interacts with through the Platform. THE SUBSCRIBER
                        AGREES TO HOLD MEDCORE FREE FROM RESPONSIBILITY FOR ANY LIABILITY
                        OR DAMAGE THAT MIGHT ARISE OUT OF SUBSCRIBER’S ACCESS TO OR
                        INTERACTIONS WITH ANY OF ITS AUTHORIZED USERS. MEDCORE IS NOT
                        RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY
                        AUTHORIZED USER, AND WILL NOT BE LIABLE FOR ANY CLAIM, INJURY OR
                        DAMAGE ARISING IN CONNECTION WITH ANY OTHER PARTY ENCOUNTERED
                        ON THE PLATFORM INCLUDING ANY AUTHORIZED USER. Subscriber is solely
                        responsible for its Authorized User including any opinions, advice, or information
                        communicated through the Platform. Any agreements entered into between Subscriber
                        and any party (including any Authorized User) are solely between the parties and
                        Medcore is not a party to such agreements. Subscriber shall indemnify and hold
                        harmless Medcore from any claims related to any Authorized Users in accordance with
                        this User Terms. Please be aware that any agreements entered into between an
                        Authorized User and any Subscriber may not abridge any contractual rights of Medcore
                        as set forth within this User Terms. Subscriber is solely responsible for resolving any
                        issues with any Authorized Users.
                    </Typography><br/>

                    <Typography variant="h6" fontWeight={900}>Subscriber Termination</Typography><br/>
                    <Typography variant="body1">Subscriber may terminate its subscription, account, or billing by
                        contacting us at
                        info@medcoremd.com. Any charges accrued up until the date of termination shall be
                        owed by Subscriber. We may terminate Subscribers subscription at our discretion and
                        may contact you when we do so.
                    </Typography><br/>


                </Container>

            </Container>
            <Footer/>
        </>
    );
};

export default AdditionalTerms
