import React, {useEffect, useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {AppBar, Box, Button, Container, Stack, Theme} from '@mui/material';
import {makeStyles, useTheme} from '@mui/styles';
import logo from '../../assets/img/logo.png';

const useStyles = makeStyles((medical: Theme) => ({
    nlink: {
        textDecoration: 'none',
        color: medical.colors.white,
        position: 'relative',
        fontSize: '18px',
        [medical.breakpoints.down('xl')]: {
            fontSize: '16px',
        },
        [medical.breakpoints.down('lg')]: {
            fontSize: '14px',
        },
        '&:hover': {
            color: medical.colors.golden,
        },
        '&:hover:after': {
            content: '""',
            position: 'absolute',
            width: '30px',
            height: '2px',
            backgroundColor: medical.colors.golden,
            bottom: '-10px',
            left: '50%',
            transform: 'translateX(-50%)'
        },
        '&.active': {
            color: medical.colors.golden,
            '&:after': {
                content: '""',
                position: 'absolute',
                width: '30px',
                height: '2px',
                backgroundColor: medical.colors.golden,
                bottom: '-10px',
                left: '50%',
                transform: 'translateX(-50%)'
            },
        },
    },
    scrolledNlink: {
        color: medical.colors.blue,
    },
}));

const Navbar: React.FC = () => {
    const classes = useStyles()
    const medical = useTheme()
    const [scrolled, setScrolled] = useState(false);
    const [offsetScrollPosition, setOffsetScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const headerElement = document.getElementById('header')
            if (!headerElement) {
                const scrollPosition = window.scrollY;

                if (scrollPosition >= 100) {
                    setOffsetScrollPosition(scrollPosition - 100)
                    setScrolled(true);
                } else {
                    setScrolled(false);
                }

                return;
            }

            const headerHeight = headerElement.offsetHeight;
            const scrollPosition = window.scrollY;

            if (scrollPosition >= headerHeight) {
                setOffsetScrollPosition(scrollPosition - headerHeight)
                setScrolled(true);
            } else {
                setScrolled(false);
            }

        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <AppBar position={scrolled ? 'sticky' : 'relative'}
                sx={{
                    boxShadow: scrolled ? '6px 6px 12px #0000001A' : 'none',
                    background: scrolled ? '#FFF' : '#155B90',
                    opacity: scrolled ? offsetScrollPosition * 0.02 : 1,
                }}
        >
            <Container
                maxWidth="xl"
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    alignItems: 'center',
                    pt: '15px',
                    pb: '15px',
                    zIndex: 1000,
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    width="80%"
                >
                    <Link title={"MedcoreMD"} to="/" style={{lineHeight: 0}}>
                        <Box
                            component="img"
                            alt="MedcoreMD"
                            src={logo}
                            sx={{
                                [medical.breakpoints.down('lg')]: {
                                    height: 40,
                                    width: 180,
                                },
                                height: 70,
                                width: 305,
                                filter: scrolled ? 'none' : 'brightness(0) invert(1)',
                            }}>
                        </Box>
                    </Link>
                    <NavLink
                        to="/reps"
                        title={'Medical Reps'}
                        className={scrolled ? [classes.nlink, classes.scrolledNlink].join(' ') : classes.nlink}
                        style={{marginLeft: '7.5%'}}
                    >
                        Medical Reps
                    </NavLink>
                    <NavLink
                        to="/practitioners"
                        title={'Medical Practitioners'}
                        className={scrolled ? [classes.nlink, classes.scrolledNlink].join(' ') : classes.nlink}
                        style={{marginLeft: '7.5%'}}
                    >
                        Medical Practitioners
                    </NavLink>
                    <NavLink
                        to="/contact"
                        title={'Contact Us'}
                        className={scrolled ? [classes.nlink, classes.scrolledNlink].join(' ') : classes.nlink}
                        style={{marginLeft: '7.5%'}}
                    >
                        Contact Us
                    </NavLink>
                </Stack>

                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="300px"
                >
                    <NavLink
                        to="/sign-in"
                        title={'Sign In'}
                        className={scrolled ? [classes.nlink, classes.scrolledNlink].join(' ') : classes.nlink}
                    >
                        Sign In
                    </NavLink>
                    <Button title="Request a Demo" size="large" component={Link} to="/demo" sx={{ml: '30px'}}>Request A Demo</Button>
                </Stack>
            </Container>
        </AppBar>
    );
};

export default Navbar;
