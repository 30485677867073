import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {Box, Container, Hidden, Stack, Typography} from '@mui/material'
import {useTheme} from '@mui/styles'
import Rocket from '../assets/img/rocket.png'
import {useTypedDispatch, useTypedSelector} from '../store'
import {validationSchemaForgotPassword} from '../helpers/validation'
import {forgotPassword} from '../store/actions/authActions/forgotPasswordAction'
import {Form, Formik} from 'formik'
import TextFieldForm from '../components/UI/TextFieldForm'
import LoadingButton from '@mui/lab/LoadingButton'
import Footer from "../components/Footer";

const ForgotPassword: React.FC = () => {
    const medical = useTheme()
    const dispatch = useTypedDispatch()
    const {status} = useTypedSelector(state => state.passwordForgotReducer)

    useEffect(() => {
        document.title = 'Password Recovery | MedcoreMD';
    }, []);

    return (
        <>
            <Container
                maxWidth={false}
                disableGutters={true}
                sx={{
                    mt: '100px',
                    mb: '100px',
                    flex: 1,
                    [medical.breakpoints.down('md')]: {
                        mt: '50px',
                        mb: '50px',
                    },
                    [medical.breakpoints.down('sm')]: {
                        mt: '30px',
                        mb: '30px',
                    }
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    maxWidth="1090px"
                    m="0 auto"
                    sx={{
                        boxShadow: '10px 10px 20px #0000001A',
                        [medical.breakpoints.down('md')]: {maxWidth: '70%'},
                        [medical.breakpoints.down('sm')]: {maxWidth: '100%'},
                    }}
                >
                    <Hidden mdDown>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            bgcolor="#93A1B0"
                            borderRadius="10px"
                            width="50%"
                        >
                            <Box
                                component="img"
                                alt="rocket"
                                src={Rocket}
                                sx={{
                                    objectFit: 'contain',
                                    width: '100%',
                                    maxWidth: '470px',
                                    height: 'auto',
                                }}
                            />
                        </Stack>
                    </Hidden>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                        width="50%"
                        p="60px 70px"
                        sx={{
                            [medical.breakpoints.down('md')]: {width: '100%', p: '30px'},
                        }}
                    >
                        <Typography variant="h4" mb="10px">
                            PASSWORD RECOVERY
                            <Typography
                                variant="h4"
                                component="span"
                                color={medical.colors.blue}
                            >
                                .
                            </Typography>
                        </Typography>

                        <Typography>
                            That’s okay, it happens! Enter your email address below, and we
                            will send you a one-time password to reset it.
                        </Typography>
                        <Formik
                            validateOnBlur={true}
                            initialValues={{
                                email: '',
                            }}
                            validationSchema={validationSchemaForgotPassword}
                            onSubmit={(values, {resetForm}) => {
                                dispatch(forgotPassword(values))
                                resetForm()
                            }}
                        >
                            {() => (
                                <Stack component={Form} width="100%">
                                    <Stack
                                        width="100%"
                                        m="40px 0 25px"
                                        sx={{
                                            [medical.breakpoints.down('md')]: {
                                                mt: '20px',
                                            },
                                        }}
                                    >
                                        <TextFieldForm
                                            name="email"
                                            label="WORK EMAIL"
                                            variant="standard"
                                            sx={{mb: '50px'}}
                                        />
                                        <LoadingButton
                                            size={'large'}
                                            type="submit"
                                            loading={status === 'loading'}
                                            variant="contained"
                                            sx={{width: '100%', maxWidth: '250px', m: '0 auto'}}
                                        >
                                            Send
                                        </LoadingButton>
                                    </Stack>
                                </Stack>
                            )}
                        </Formik>
                        <Typography
                            component={Link}
                            variant="body2"
                            color={medical.colors.blue}
                            to="/sign-in"
                        >
                            Back to Sign in
                        </Typography>
                        <Typography variant={'body2'} textAlign={'center'} color={medical.colors.lightGrey} mt={'25px'} ml={'15px'}>
                            This site is protected by reCAPTCHA and the Google
                            <a style={{fontSize: '16px', color: medical.colors.blue}}
                               href={'https://policies.google.com/privacy'} target={'_blank'}
                               rel={"noreferrer"}> Privacy Policy</a> and
                            <a style={{fontSize: '16px', color: medical.colors.blue}}
                               href={'https://policies.google.com/terms'}
                               target={'_blank'} rel={"noreferrer"}> Terms of Service</a> apply.
                        </Typography>
                    </Stack>
                </Stack>
            </Container>
            <Footer/>
        </>
    )
}

export default ForgotPassword
