import JoinIcon from '../assets/icons/join.svg'
import HandsIcon from '../assets/icons/hands.svg'

import CommunicateIcon from '../assets/icons/connect.svg'
import IconControl from '../assets/icons/control.svg'
import IconBook from '../assets/icons/book.svg'
import IconQuestion from '../assets/icons/question.svg'
import PcIcon from '../assets/icons/pc-blue-circle.svg'
import DatabaseIcon from '../assets/icons/database.svg'
import CalendarBlueCircle from '../assets/icons/calendar.svg'
import FilterBlueCircle from '../assets/icons/filter.svg'
import ClockIcon from '../assets/icons/clock.svg'
import AlertIcon from '../assets/icons/alert.svg'
import MagnifierIcon from '../assets/icons/magnifier.svg'

import ContactsIcon from '../assets/icons/contacts.svg'


export const FeaturesData = {
    medicalReps: [
        {
            icon: PcIcon,
            title: 'Create Custom Profile',
            desc: 'Create a custom company profile with product brochures, video links, and more.',
        },
        {
            icon: FilterBlueCircle,
            title: 'Generate New Leads',
            desc: 'Easily locate medical practitioners by local region and area of specialization.',
        },
        {
            icon: ClockIcon,
            title: 'Send Meeting Requests',
            desc: 'Invite practices or individual practitioners to meet in person or virtually.',
        },
        {
            icon: CalendarBlueCircle,
            title: 'Book Qualified Meetings',
            desc: 'Meet with qualified prospects that have expressed interest.',
        },
        {
            icon: AlertIcon,
            title: 'Receive Alert Notifications',
            desc: 'Get important alerts via SMS, email, or directly.',
        },
        {
            icon: MagnifierIcon,
            title: 'Powerful Search',
            desc: 'Get desired results quickly optimizing integrated search technology.',
        }
    ],
    medicalPractitioners: [
        {
            icon: PcIcon,
            title: 'Create Custom Profile',
            desc: 'Create a shared profile or individual profiles for each practitioner.',
        },
        {
            icon: DatabaseIcon,
            title: 'Access Rep Database',
            desc: 'View important info about reps and their products from any device.',
        },
        {
            icon: ClockIcon,
            title: 'Schedule Meetings',
            desc: 'Accept, reject or postpone in-person or virtual meetings.',
        },
        {
            icon: ContactsIcon,
            title: 'Message Contacts',
            desc: 'Send bulk office updates to the entire rep database in a matter of minutes.',
        },
        {
            icon: AlertIcon,
            title: 'Receive Alert Notifications',
            desc: 'Get important alerts via SMS, email, or directly.',
        },
        {
            icon: MagnifierIcon,
            title: 'Powerful Search',
            desc: 'Get desired results quickly optimizing integrated search technology.',
        },
    ]
}

export const StepsData = {
    medicalReps: [
        {
            icon: JoinIcon,
            title: 'Join',
            desc: 'To get started, create an account, customize your profile, and upload your product info.',
        },
        {
            icon: IconQuestion,
            title: 'Request',
            desc: 'Search medical practices by region and specialization, then send a custom meeting request.',
        },
        {
            icon: CommunicateIcon,
            title: 'Communicate',
            desc: 'Interact with interested practitioners virtually or in person to nurture and grow the relationship.',
        },
        {
            icon: HandsIcon,
            title: 'Close Deals',
            desc: 'Use Medcore MD to educate medical professionals about the benefits of your products and services.',
        }
    ],
    medicalPractitioners: [
        {
            icon: JoinIcon,
            title: 'Join',
            desc: 'To get started, create a free account for individual practitioners or an entire office.',
        },
        {
            icon: IconControl,
            title: 'Control',
            desc: 'Accept, decline, or postpone meeting based on your availability and level of interest.',
        },
        {
            icon: CommunicateIcon,
            title: 'Connect',
            desc: 'Interact with sales reps virtually or in person based on your preferences and availability.',
        },
        {
            icon: IconBook,
            title: 'Learn',
            desc: 'Learn about new products and services without drawing attention away from your patients.',
        }
    ]
}
