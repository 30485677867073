import {createSlice} from '@reduxjs/toolkit';
import {register} from '../actions/authActions/registerAction';
import {registerState} from '../types';

const initialState: registerState = {
    registerStep: 0,
    userRole: null,
    error: null,
    status: null,
}

const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        // Register steps
        nextStep: (state) => {
            state.registerStep += 1
        },
        prevStep: (state) => {
            state.registerStep -= 1
        },
        // Choose role (1 step)
        setUserRole: (state, action) => {
            state.userRole = action.payload.userRole
        },
        // Reset state
        resetRegister: () => initialState
    },
    extraReducers: builder => {
        builder
            .addCase(register.pending, (state) => {
                state.status = 'loading'
                state.error = null
            })
            .addCase(register.fulfilled, (state) => {
                state.status = 'resolved'
                state.error = null
            })
            .addCase(register.rejected, (state, action) => {
                state.status = 'rejected'
                state.error = action.payload
            })
    }
})

export const {nextStep, prevStep, setUserRole, resetRegister} = registerSlice.actions
export default registerSlice.reducer