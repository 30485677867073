import {createSlice} from '@reduxjs/toolkit';
import {checkInvitation} from '../actions/authActions/registerAction';
import {invitationState} from '../types';

const initialState: invitationState = {
    status: null,
    isValid: null,
    data: null,
}

const invitationSlice = createSlice({
    name: 'invitation',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(checkInvitation.fulfilled, (state, action) => {
                state.isValid = action.payload.isValid
                state.data = action.payload
            })
    }
})

export default invitationSlice.reducer
