import React, { useEffect } from 'react';
const Recaptcha: React.FC = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
        script.async = true;
        document.body.appendChild(script);

    }, []);

    return null;
};

export default Recaptcha;
