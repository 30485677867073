import React from 'react'
import {Box, Button, Container, Grid, Hidden, Typography} from '@mui/material'
import {Link} from 'react-router-dom'
import {useTheme} from '@mui/styles'

interface HeadItems {
    subtitle?: string
    title?: string
    desc?: string
    descBold?: string
    img?: string
    bg?: string
    bgImg?: string
    contentMaxWidth?: string
}


const HeadSection: React.FC<HeadItems> = (props: HeadItems) => {
    const medical = useTheme()

    return (
        <Container
            id={'header'}
            maxWidth={false}
            disableGutters={true}
            sx={{
                position: 'relative',
                marginTop: '-100px',
                paddingTop: '175px',
                background: props.bg ? props.bg : '#155B90',

                '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: '0px',
                    left: '0px',
                    background: props.bgImg && `url(${props.bgImg}) no-repeat right bottom`,
                    [medical.breakpoints.down('lg')]: {
                        opacity: '0.3',
                        backgroundPosition: 'center bottom',
                    },
                }
            }}
        >
            <Container maxWidth="xl">
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    marginTop={'0px'}
                    columnSpacing={10}
                >
                    <Grid item md={6} sx={{position: 'relative'}} mb={{xs: '70px', lg: '70px'}}
                          textAlign={{
                              xs: 'center',
                              md: 'left',
                          }}
                    >
                        <Box maxWidth={{
                            md: props.contentMaxWidth ? props.contentMaxWidth : '100%',
                            xs: '100%',
                        }} px={{
                            md: '0px',
                            sm: '20px',
                            xs: '5px',
                        }}>
                            <Typography variant="h3" sx={{
                                color: '#F2A83B',
                                fontWeight: '900',
                            }}>{props.subtitle}</Typography>
                            <Typography variant="h1" sx={{
                                color: '#fff',
                                fontWeight: '900',
                            }}>{props.title}</Typography>
                            <Typography
                                variant="subtitle1"
                                color={medical.colors.white}
                                fontWeight="300"
                                mt="20px"
                            >
                                {props.desc}
                                {props.descBold ? (
                                    <>
                                        <br/>
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                fontWeight: 900,
                                                marginTop: '20px',
                                            }}
                                        >
										{props.descBold}
									</span>
                                    </>
                                ) : null}
                            </Typography>

                            <Button sx={{mt: '30px'}} size="large" component={Link} to="/sign-up">
                                Get Started Today
                            </Button>
                        </Box>
                    </Grid>
                    <Hidden mdDown>
                        {props.img && (
                            <Grid item md={6} ml={'auto'} sx={{
                                maxWidth: '100%',
                                position: 'relative',
                                textAlign: 'right',
                            }}>
                                <Box
                                    sx={{
                                        mt: '-100px',
                                        maxWidth: '100%',
                                    }}
                                    component="img"
                                    src={props.img}
                                    alt="medical reps"
                                ></Box>
                            </Grid>
                        )}
                    </Hidden>
                </Grid>
            </Container>
        </Container>
    )
}

export default HeadSection
