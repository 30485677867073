import axios from 'axios';
import {API_URL} from './API';
import {endpoints} from './API/endpoints';

axios.defaults.withCredentials = true

/**
 * Register user
 * @param role
 * @param email
 * @param password
 * @param recaptcha_token
 */
const register = async (role: number | null, email: string, password: string, recaptcha_token: string) => {
    return await axios.post(API_URL + endpoints.register, {
        role,
        email,
        password,
        recaptcha_token
    });
};

/**
 * Verify email | Verify email link
 * @param verifyCode
 * @param tokenVerify
 */
const verifyAccount = async (verifyCode: string, tokenVerify: string | null) => {
    return await axios.put(API_URL + endpoints.verifyAccount, {
        otpCode: verifyCode,
        tokenVerify: tokenVerify
    });
};

/**
 * Verify email Request
 * @param accessToken
 */
 const verifyRequest = async (accessToken: string | null | undefined) => {
    return await axios.post(API_URL + endpoints.verifyRequest, {
        accessToken: accessToken,
    });
};


/**
 * Login
 * @param email
 * @param password
 * @param recaptcha_token
 */
const login = async (email: string, password: string, recaptcha_token: string) => {
    return await axios.post(API_URL + endpoints.login, {
        email,
        password,
        recaptcha_token
    })
};

/**
 * Logout
 */
const logout = () => {
    localStorage.removeItem("userToken");
};

/**
 * Forgot password
 * @param email
 * @param recaptcha_token
 */
const forgotPassword = async (email: string, recaptcha_token: string) => {
    return await axios.get(API_URL + endpoints.forgotPassword, {
        params: {email: email, recaptcha_token},
    })
};

/**
 * Reset password
 * @param resetPasswordToken
 * @param password
 * @param confirmPassword
 */
const resetPassword = async (resetPasswordToken: string | undefined, password: string, confirmPassword: string) => {
    return await axios.put(API_URL + endpoints.resetPassword, {
        resetPasswordToken,
        password,
        confirmPassword
    })
};

const checkInvitation = async (id: string | undefined) => {
    return await axios.get(API_URL + endpoints.checkInvitation + '/' + id)
};

const acceptInvite = async (id: string, password: string, confirmPassword: string) => {
    return await axios.post(API_URL + endpoints.acceptInvite, {
        id,
        password,
        confirmPassword
    })
};

export default {
    register,
    verifyAccount,
    verifyRequest,
    login,
    logout,
    forgotPassword,
    resetPassword,
    checkInvitation,
    acceptInvite
};
