import * as yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const lowercaseRegExp = /(?=.*[a-z])/
const uppercaseRegExp = /(?=.*[A-Z])/
const numericRegExp = /(?=.*[0-9])/
const specialRegExp = /(?=.*[!@#$%^&*])/
const fileSize = 25165824
const supportedFormats = [
  'image/jpg',
  'image/jpeg',
  'image/heic',
  'image/png'
];

export const validationSchemaContact = yup.object({
  email: yup
    .string()
    .email('The Email you entered is not a valid format!')
    .required('Please enter email address!'),
  first_name: yup.string().required('Please enter your First name!'),
  last_name: yup.string().required('Please enter your Last name!'),
  job_title: yup.string().required('Please enter your job title!'),
  company_name: yup.string().required('Please enter your company name!'),
  phone: yup
    .string()
    .required('Please enter your phone number!')
    .matches(phoneRegExp, 'Please enter your phone number!'),
  zip: yup
    .string()
    .required('Please enter your zip code!'),
  state: yup
    .string()
    .required('Please enter your state!'),
  file: yup
    .mixed()
    .nullable()
    .notRequired()
    .test(
      'fileSize',
      'Uploaded file over 24MB.',
      value => !value || (value && value.size <= fileSize)
    )
    .test(
      'fileFormat',
      'File should be of a .jp(e)g, .heic or .png format.',
      value => !value || (value && supportedFormats.includes(value.type))
    )
  ,
  message: yup.string().required('Please enter your message!'),
  conditions: yup.boolean().oneOf([true], 'Please agree to Terms of Service and Cookie & Privacy Policy'),
});

export const validationSchemaDemo = yup.object({
  email: yup
    .string()
    .email('The Email you entered is not a valid format!')
    .required('Please enter Email Address!'),
  first_name: yup.string().required('Please enter your First name!'),
  last_name: yup.string().required('Please enter your Last name!'),
  job_title: yup.string().required('Please enter your job title!'),
  company_name: yup.string().required('Please enter your company name!'),
  phone: yup
    .string()
    .matches(phoneRegExp, 'Please enter your phone number!')
    .required(),
  zip: yup
    .string()
    .required('Please enter your zip code!'),
  conditions: yup.boolean().oneOf([true], 'Please agree to Terms of Service and Cookie & Privacy Policy'),
});

export const validationSchemaSignup = yup.object({
  email: yup
    .string()
    .email('The Email you entered is not a valid format!')
    .required('Please enter your email address!'),
  password: yup
    .string()
    .matches(lowercaseRegExp, 'One lowercase required!')
    .matches(uppercaseRegExp, 'One uppercase required!')
    .matches(numericRegExp, 'One number required!')
    .matches(specialRegExp, 'One special character required!')
    .min(8, 'Minimum 8 characters required!')
    .required('Please enter your password!'),
  conditions: yup.boolean().oneOf([true], 'Please agree to Terms of Service and Cookie & Privacy Policy'),
});

export const validationSchemaSignin = yup.object({
  email: yup
    .string()
    .email('The Email you entered is not a valid format!')
    .required('Please enter your email address!'),
  password: yup
    .string()
    .required('Please enter your password!'),
});

export const validationSchemaForgotPassword = yup.object({
  email: yup
    .string()
    .email('The Email you entered is not a valid format!')
    .required('Please enter your email address!'),
});

export const validationSchemaResetPassword = yup.object({
  password: yup
    .string()
    .matches(lowercaseRegExp, 'One lowercase required!')
    .matches(uppercaseRegExp, 'One uppercase required!')
    .matches(numericRegExp, 'One number required!')
    .matches(specialRegExp, 'One special character required!')
    .min(8, 'Minimum 8 characters required!')
    .required('Please enter your password!'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Please enter your password!'),
});
