import React from 'react';
import {Theme} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import VerificationInput, {VerificationInputProps} from "react-verification-input";


const useStyles = makeStyles((medical: Theme) => ({
	container: {
		display: 'flex',
		width: '420px',
		height: '70px',
		marginBottom: '20px',
		[medical.breakpoints.down('md')]: {width: '270px', height: '30px'},
	},
	character: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 48,
		fontWeight: 'normal',
		color: medical.colors.blue,
		borderBottom: `2px solid ${medical.colors.blue}`,
		margin: '0 5px',
		cursor: 'text',
		[medical.breakpoints.down('md')]: {fontSize: 32},
	},
	characterInactive: {
		borderBottom: '2px solid #93A1B0',
	},
	characterSelected: {
		borderBottom: `2px solid ${medical.colors.blue}`,
	},
}))
// TODO need to check
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CodeInput: React.FC<VerificationInputProps> = (props: any) => {

    const classes = useStyles()

    return (
        <VerificationInput
            {...props}
            validChars='0-9'
            removeDefaultStyles={true}
            classNames={{
                container: classes.container,
                character: classes.character,
                characterInactive: classes.characterInactive,
                characterSelected: classes.characterSelected,
            }}
            placeholder=''
        />
    );
};

export default CodeInput;
