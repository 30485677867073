import React from 'react'
import {
	Box,
	Button,
	Container,
	List,
	ListItem,
	Stack,
	Typography,
} from '@mui/material'
import {makeStyles, useTheme} from '@mui/styles'
import {Theme} from '@mui/material/styles'
import classNames from 'classnames'
import {useTypedDispatch, useTypedSelector} from '../../store'
import repsIcon from '../../assets/icons/representative.svg'
import practIcon from '../../assets/icons/practitioner.svg'
import {nextStep, setUserRole} from '../../store/slices/registerSlice'

const useStyles = makeStyles((medical: Theme) => ({
	role: {
		padding: '30px',
		borderRadius: '10px',
		position: 'relative',
		cursor: 'pointer',
		border: `1px solid transparent`,
		[medical.breakpoints.down('md')]: {
			width: '100%',
		},
		'&:hover': {
			border: `1px solid ${medical.colors.blue}`,
		},
		'&.active': {
			backgroundColor: 'rgba(15, 117, 188, .05)',
			'& $icon': {
				boxShadow: '6px 6px 12px #0F75BC40',
			},
			'& $list': {
				opacity: 1,
				color: medical.colors.lightGrey,
			},
		},
	},
	circleBlueIcon: {
		width: '100px',
		height: '105px',
		backgroundSize: 'contain',
		position: 'relative',
		margin: '0 auto',
		'&:before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			width: '105px',
			height: '105px',
			borderRadius: '50%',
			backgroundColor: '#e8f6fc',
			zIndex: -1,
			top: '0px',
			right: '-30px',
			left: '0px',
			margin: '0 auto',
			[medical.breakpoints.down('sm')]: {
				height: '48px',
				width: '48px',
				right: '-20px',
			},
		},
		'& img': {
			marginTop: '25px',
			[medical.breakpoints.down('sm')]: {
				width: '32px',
				marginTop: '15px',
			},
		},
		[medical.breakpoints.down('sm')]: {
			height: '48px',
			width: '48px',
			marginRight: '30px',
		},
	},
	title: () => ({
		'&.MuiTypography-root': {
			fontSize: 28,
			color: medical.colors.black,
			marginTop: '25px',
			[medical.breakpoints.down('md')]: {
				marginTop: '0',
				fontSize: 20,
			},
		},
	}),
	list: () => ({
		'&.MuiList-root': {
			listStyle: 'outside',
			color: medical.colors.black,
			opacity: 0.6,
			marginLeft: '20px',
			'& li': {
				display: 'list-item',
				fontSize: '18px',
				marginTop: '20px',
				padding: 0,
				[medical.breakpoints.down('md')]: {
					fontSize: '16px',
					lineHeight: '18px',
				},
				'&::marker': {
					color: medical.colors.blue,
				},
			},
		},
	}),
}))

const UserRole: React.FC = () => {
	const classes = useStyles()
	const medical = useTheme()
	const dispatch = useTypedDispatch()
	const {userRole} = useTypedSelector(state => state.registerReducer)
	const MR = 1
	const MP = 2

	return (
		<Container maxWidth="lg">
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				maxWidth="900px"
				m="0 auto"
				p="45px"
				sx={{
					boxShadow: '10px 10px 20px #0000001A',
					[medical.breakpoints.down('md')]: {padding: '20px'},
				}}
			>
				<Typography variant="h6">{"WE CAN'T WAIT TO KNOW MORE"}</Typography>
				<Typography variant="h4" mb="10px">
					TELL US ABOUT YOURSELF
					<Typography variant="h4" component="span" color={medical.colors.blue}>
						.
					</Typography>
				</Typography>
				<Stack
					direction="row"
					spacing={{md: 8}}
					mt="30px"
					mb="40px"
					sx={{[medical.breakpoints.down('md')]: {flexDirection: 'column'}}}
				>
					<Stack
						className={classNames(classes.role, userRole === MR && 'active')}
						onClick={() => dispatch(setUserRole({userRole: MR}))}
						width="50%"
						sx={{[medical.breakpoints.down('md')]: {width: '100%'}}}
					>
						<Stack
							sx={{
								[medical.breakpoints.down('md')]: {
									flexDirection: 'row',
									alignItems: 'center',
								},
							}}
						>
							<Box className={classes.circleBlueIcon}>
								<img src={repsIcon} alt={"Representative"} />
							</Box>
							<Typography
								variant="h5"
								fontWeight={600}
								className={classes.title}
							>
								I’m a Sales Representative
							</Typography>
						</Stack>

						<List className={classes.list}>
							<ListItem>Build and create a custom profile.</ListItem>
							<ListItem>
								Upload product information and marketing materials.
							</ListItem>
							<ListItem>
								Send targeted meeting requests to relevant medical
								professionals.
							</ListItem>
						</List>
					</Stack>

					<Stack
						className={classNames(classes.role, userRole === MP && 'active')}
						onClick={() => dispatch(setUserRole({userRole: MP}))}
						width="50%"
						sx={{
							[medical.breakpoints.down('md')]: {
								width: '100%',
								marginTop: '20px',
							},
						}}
					>
						<Stack
							sx={{
								[medical.breakpoints.down('md')]: {
									flexDirection: 'row',
									alignItems: 'center',
								},
							}}
						>
							<Box className={classes.circleBlueIcon}>
								<img src={practIcon} alt={"Practitioner"}></img>
							</Box>
							<Typography
								variant="h5"
								fontWeight={600}
								className={classes.title}
							>
								I’m a Medical Professional
							</Typography>
						</Stack>

						<List className={classes.list}>
							<ListItem>
								Create a profile for your office and/or individual
								practitioners.
							</ListItem>
							<ListItem>
								Access rep data, set meeting preferences, and manage
								notifications.
							</ListItem>
							<ListItem>
								Connect directly with sales reps on your schedule.
							</ListItem>
						</List>
					</Stack>
				</Stack>

				<Button
					size="large"
					onClick={() => dispatch(nextStep())}
					disabled={!userRole}
					variant="contained"
					sx={{width: '100%', maxWidth: '300px'}}
				>
					Continue
				</Button>
			</Stack>
		</Container>
	)
}

export default UserRole
