import React, {useEffect} from 'react'
import {Container, Link, List, ListItem, Typography} from '@mui/material'
import Footer from '../components/Footer'
import {medical} from "../theme";

const CookiePolicy = () => {
  useEffect(() => {
    document.title = 'Cookie Policy | MedcoreMD';
  }, []);

  return (
    <>
      <Container
        maxWidth={false}
        disableGutters={true}
        sx={{
          mt: '100px',
          mb: '100px',
          flex: 1,
          [medical.breakpoints.down('md')]: {
            mt: '50px',
            mb: '50px',
          },
          [medical.breakpoints.down('sm')]: {
            mt: '30px',
            mb: '30px',
          }
        }}
      >
        <Container maxWidth="xl">
          <Typography variant="h2">Cookie Policy</Typography><br/>
          <Typography variant="body1">This is the Cookie Policy for Medcore MD, accessible from
            https://www.medcoremd.com/</Typography><br/>

          <Typography variant="h6" fontWeight={900}>What Are Cookies</Typography><br/>
          <Typography variant="body1">As is common practice with almost all professional websites this site uses
            cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page
            describes what information they gather, how we use it and why we sometimes need to store these cookies. We
            will also share how you can prevent these cookies from being stored however this may downgrade
            or &apos;break&apos;
            certain elements of the sites functionality.</Typography><br/>

          <Typography variant="h6" fontWeight={900}>How We Use Cookies</Typography><br/>
          <Typography variant="body1">We use cookies for a variety of reasons detailed below. Unfortunately in most
            cases there are no industry standard options for disabling cookies without completely disabling the
            functionality and features they add to this site. It is recommended that you leave on all cookies if you are
            not sure whether you need them or not in case they are used to provide a service that you
            use.</Typography><br/>

          <Typography variant="h6" fontWeight={900}>Disabling Cookies</Typography><br/>
          <Typography variant="body1">You can prevent the setting of cookies by adjusting the settings on your browser
            (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of
            this and many other websites that you visit. Disabling cookies will usually result in also disabling certain
            functionality and features of the this site. Therefore it is recommended that you do not disable
            cookies.</Typography><br/>

          <Typography variant="h6" fontWeight={900}>The Cookies We Set</Typography>
          <List sx={{
            listStyleType: 'disc',
            pl: 2,
            '& .MuiListItem-root': {
              display: 'list-item',
              paddingLeft: 0
            }
          }}
          >
            <ListItem>
              Account related cookies
              <br/><br/>
              If you create an account with us then we will use cookies for the management of the signup process and
              general administration. These cookies will usually be deleted when you log out however in some cases they
              may remain afterwards to remember your site preferences when logged out.
            </ListItem>
            <ListItem>
              Login related cookies
              <br/><br/>
              We use cookies when you are logged in so that we can remember this fact. This prevents you from having to
              log in every single time you visit a new page. These cookies are typically removed or cleared when you log
              out to ensure that you can only access restricted features and areas when logged in.
            </ListItem>
            <ListItem>
              Email newsletters related cookies
              <br/><br/>
              This site offers newsletter or email subscription services and cookies may be used to remember if you are
              already registered and whether to show certain notifications which might only be valid to
              subscribed/unsubscribed users.
            </ListItem>
            <ListItem>
              Forms related cookies
              <br/><br/>
              When you submit data to through a form such as those found on contact pages or comment forms cookies may
              be set to remember your user details for future correspondence.
            </ListItem>
            <ListItem>
              Site preferences cookies
              <br/><br/>
              In order to provide you with a great experience on this site we provide the functionality to set your
              preferences for how this site runs when you use it. In order to remember your preferences we need to set
              cookies so that this information can be called whenever you interact with a page is affected by your
              preferences.
            </ListItem>
          </List>

          <Typography variant="h6" fontWeight={900}>More Information</Typography><br/>
          <Typography variant="body1">Hopefully that has clarified things for you and as was previously mentioned if
            there is something that you aren&apos;t sure whether you need or not it&apos;s usually safer to leave cookies enabled
            in case it does interact with one of the features you use on our site.</Typography><br/>

          <Typography variant="body1">However if you are still looking for more information then you can contact us
            through one of our preferred contact methods:</Typography>
          <List sx={{
            listStyleType: 'disc',
            pl: 2,
            '& .MuiListItem-root': {
              display: 'list-item',
              paddingLeft: 0
            }
          }}
          >
            <ListItem>Email: <Link href='mailto:info@medcoremd.com'>info@medcoremd.com</Link></ListItem>
            <ListItem>Phone: <Link href='tel:+12133026209'>+ 1 213-302-6209</Link></ListItem>
            <ListItem>Address: 527 Pine St Hermosa Beach CA 90252</ListItem>

          </List>
        </Container>

      </Container>
      <Footer />
    </>
  );
};

export default CookiePolicy
