export const endpoints = {
    register: 'auth/sign-up',
    login: 'auth/sign-in',
    checkInvitation: 'auth/check-invitation',
    acceptInvite: 'auth/accept-invitation',
    // GET
    verifyRequest: 'auth/verify',
    // POST
    verifyAccount: 'auth/verify',

    // GET
    forgotPassword: 'auth/password',
    // PUT
    resetPassword: 'auth/password',
    contactUs: 'contact-us/send-email',
    demoRequest: 'demo/request'
}
