import React from 'react';
import {Box, Stack, SvgIcon, Typography} from '@mui/material'
import {ReactComponent as beforeImg} from '../../../assets/icons/scheme_mobile_img.svg'
import {ReactComponent as repsIcon} from '../../../assets/icons/reps.svg'
import {ReactComponent as practIcon} from '../../../assets/icons/pract.svg'

const SchemeMobile = () => {
    return (
        <Stack maxWidth="340px" position="relative" p="70px 0">
            <Box
                display="flex"
                alignItems="center"
                width="100%"
                maxWidth="max-content"
                height="fit-content"
                p="15px"
                boxShadow="10px 10px 20px #0F75BC33"
                borderRadius="5px"
                sx={{
                    position: 'absolute',
                    top: '60px',
                    right: '100px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                    maxWidth: '130px',
                    zIndex: 1
                }}
            >
                <SvgIcon
                    component={repsIcon}
                    inheritViewBox
                    sx={{
                        width: '44px',
                        height: '44px',
                    }}
                />
                <Typography
                    variant="subtitle2"
                    fontSize="14px"
                    fontWeight="900"
                    lineHeight="17px"
                    sx={{
                        mt: '10px',
                        lineHeight: '15px!important'
                    }}
                >
                    Medical
                    <br/>
                    Representative
                </Typography>
            </Box>
            <Box
                display="flex"
                alignItems="center"
                width="100%"
                maxWidth="max-content"
                height="fit-content"
                p="15px"
                boxShadow="10px 10px 20px #0F75BC33"
                borderRadius="5px"
                sx={{
                    position: 'absolute',
                    bottom: '90px',
                    left: '80px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                    maxWidth: '130px'
                }}
            >
                <SvgIcon
                    component={practIcon}
                    inheritViewBox
                    sx={{
                        width: '44px',
                        height: '44px',
                        mr: '0',
                    }}
                />
                <Typography
                    variant="subtitle2"
                    fontSize="14px"
                    fontWeight="900"
                    lineHeight="17px"
                    sx={{
                        mt: '10px',
                        lineHeight: '15px!important'

                    }}
                >
                    Medical
                    <br/>
                    Practitioner
                </Typography>
            </Box>
            <SvgIcon
                component={beforeImg}
                inheritViewBox
                sx={{
                    width: '100%',
                    height: '100%',
                    zIndex: 1
                }}
            />
        </Stack>
    );
};

export default SchemeMobile;