import React from 'react'
import {Box, Button, Container, Grid, Hidden, Stack, SvgIcon, Typography,} from '@mui/material'
import {makeStyles, useTheme} from '@mui/styles'
import Footer from '../components/Footer'
import HeadSection from '../components/HeadSection'
import {Theme} from '@mui/material/styles'
import {ReactComponent as doubleArrow} from '../assets/icons/arrow_double.svg'
import {ReactComponent as repsIcon} from '../assets/icons/reps.svg'
import {ReactComponent as practIcon} from '../assets/icons/pract.svg'

import RepresentativeIcon from '../assets/icons/representative.svg'
import PractitionerIcon from '../assets/icons/practitioner.svg'

import logo from '../assets/img/logo.png'
import poster from '../assets/img/poster.jpg'
import {Link} from 'react-router-dom'
import SignUpSection from '../components/Footer/SignUpSection'
import SchemeDesktop from '../components/HomePage/SchemeDesktop'
import SchemeMobile from '../components/HomePage/SchemeMobile'
import VideoPlayer from "../components/VideoPlayer";

import PcIcon from '../assets/icons/pc-blue-circle.svg'
import CalendarIcon from '../assets/icons/calendar.svg'
import HandsIcon from '../assets/icons/hands.svg'


import homeBubbles from '../assets/img/header-bubbles-group.svg'

const useStyles = makeStyles((medical: Theme) => ({
    circleBlueIcon: {
        width: '100px',
        height: '105px',
        backgroundSize: 'contain',
        position: 'relative',
        margin: '0 auto',
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: '105px',
            height: '105px',
            borderRadius: '50%',
            backgroundColor: '#e8f6fc',
            zIndex: -1,
            top: '0px',
            right: '-30px',
            left: '0px',
            margin: '0 auto',
        },
        '& img': {
            marginTop: '25px',
        },
    },
    list: {
        '&.MuiList-root': {
            listStyle: 'none',
            counterReset: 'item',
            color: medical.colors.lightGrey,
            marginLeft: '0',
            marginTop: '30px',
        },
        '& li': {
            counterIncrement: 'item',
            color: medical.colors.black,
            marginLeft: 0,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignContent: 'flex-start',
            alignItems: 'flex-start',
            [medical.breakpoints.up('xs')]: {
                padding: '0 0 0 65px',
            },
            [medical.breakpoints.up('lg')]: {
                padding: '0 65px 0',
            },
            marginBottom: '35px',
            '&::before': {
                content: 'counter(item)',
                position: 'absolute',
                left: 0,
                color: medical.colors.blue,
                fontSize: 20,
                fontWeight: 900,
                width: '51px',
                height: '51px',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '3px 3px 6px #0F75BC1A',
                marginRight: '15px',
            },
            '& h5': {
                fontWeight: 900,
            },
            '& p': {
                fontWeight: 300,
            },
        },
    },
    roleCard: {
        width: '100%',
        maxWidth: '590px',
        padding: '40px',
        boxShadow: '10px 10px 20px #0000000D',
        borderRadius: '5px',
        transition: '.2s',
        display: 'flex',
        alignItems: 'center',
    },
    arrow: {
        width: '136px',
        height: '120px',
        left: '0',
        right: '0',
        margin: '0 auto',
        bottom: '-220px',
        position: 'absolute',
        [medical.breakpoints.down('md')]: {
            bottom: '-90px',
        }
    }
}))


const HomePage: React.FC = () => {
    const classes = useStyles()
    const medical = useTheme()

    return (
        <div>
            <Container maxWidth={false} disableGutters={true}>
                <HeadSection
                    subtitle="THE EVOLUTION OF"
                    title="Healthcare Collaboration Made Easy"
                    desc="Medcore MD is an innovative digital platform that streamlines and simplifies communication between medical practitioners and sales reps."
                    bgImg={homeBubbles}
                    contentMaxWidth={'540px'}
                />

                <Container maxWidth="xl" sx={{
                    mt: '70px',
                    [medical.breakpoints.down('md')]: {
                        mt: '30px',
                    }
                }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={4}
                    >
                        <Grid item xl={12}>
                            <Typography variant="h2">HOW IT WORKS</Typography>
                            <Typography variant="subtitle1" mt={'10px'}>Modernize your workflow, improve your communication capabilities, and make quicker
                                connections in three simple steps:</Typography>
                        </Grid>

                        <Grid item container columnSpacing={5}>
                            <Grid item md={4} xs={12} textAlign={'center'} display={'flex'} justifyContent={'center'}>
                                <Stack direction={'column'} alignItems={'center'} maxWidth={'300px'} mb={{
                                    xs: '40px',
                                }}>
                                    <div className={classes.circleBlueIcon}>
                                        <img src={PcIcon} alt="Join the Medcore MD Community"/>
                                    </div>
                                    <Typography
                                        mt={'20px'}
                                        variant="h5"
                                        sx={{
                                            fontWeight: 900,
                                        }}
                                    >Join the Medcore MD Community</Typography>
                                    <p>
                                        Create a free Medcore MD account to get started on the
                                        platform.
                                    </p>
                                </Stack>
                            </Grid>

                            <Grid item md={4} xs={12} textAlign={'center'} display={'flex'} justifyContent={'center'}>
                                <Stack direction={'column'} alignItems={'center'} maxWidth={'300px'} mb={{
                                    xs: '40px',
                                }}>
                                    <div className={classes.circleBlueIcon}>
                                        <img src={CalendarIcon} alt="Customize Your Solution"/>
                                    </div>
                                    <Typography
                                        mt={'20px'}
                                        variant="h5"
                                        sx={{
                                            fontWeight: 900,
                                        }}>Customize Your Solution</Typography>
                                    <p>
                                        Tailor the platform to effect the exact needs of your
                                        business.
                                    </p>
                                </Stack>
                            </Grid>

                            <Grid item md={4} xs={12} textAlign={'center'} display={'flex'} justifyContent={'center'}>
                                <Stack direction={'column'} alignItems={'center'} maxWidth={'300px'} mb={{
                                    xs: '40px',
                                }}>
                                    <div className={classes.circleBlueIcon}>
                                        <img src={HandsIcon} alt="Make Quick, Efficient Connections"/>
                                    </div>
                                    <Typography
                                        mt={'20px'}
                                        variant="h5"
                                        sx={{
                                            fontWeight: 900,
                                        }}>Make Quick, Efficient Connections</Typography>
                                    <p>
                                        Medcore MD makes it easy for practitioners and sales to
                                        connect.
                                    </p>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>

                <Container maxWidth={false} disableGutters={true}
                           sx={{
                               backgroundColor: 'rgba(242, 109, 33, 0.05)',
                               mt: '40px'
                           }}
                >
                    <Container maxWidth="xl">
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            columnSpacing={14}
                        >
                            <Grid item md={6} xs={12} mt={'70px'} mb={'70px'} order={{  md: 1, xs: 2 }}>
                                <VideoPlayer posterUrl={poster}
                                             videoUrl={`https://${process.env.REACT_APP_FILES_BUCKET_DOMAIN}/presentation.mp4`}/>
                            </Grid>
                            <Grid item md={6} xs={12} order={{  md: 2, xs: 1 }} mt={{
                                md: '70px',
                                xs: '70px',
                            }} mb={{
                                xs: 0,
                                md: '70px',
                            }} >
                                <Typography variant="h6">GET TO KNOW</Typography>
                                <Typography variant="h2" maxWidth={'500px'} mb={'20px'}> MEDCORE
                                    MD</Typography>
                                <Typography variant='body1'>
                                    The Medcore MD Platform optimizes medical rep and clinical practice engagement. One
                                    central location to manage connections using video, advanced integrated search
                                    capabilities, and documented meeting notes. Improve the quality of your
                                    communication by using our intuitive platform.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Container>

                <Container
                    maxWidth={false}
                    disableGutters={true}
                    sx={{
                        mt: '85px',
                        position: 'relative',
                    }}
                >
                    <Container maxWidth="xl">
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            rowSpacing={{xl: '0'}}
                            spacing={4}
                        >
                            <Grid
                                item
                                xl={5}
                                lg={5}
                                mb={{lg: '50px'}}
                            >
                                <Typography variant="h6">REPLACE STRESS AND CHAOS WITH</Typography>
                                <Typography variant="h2">
                                    OPTIMIZED DAILY WORKFLOWS
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    color={medical.colors.grey}
                                    fontWeight="400"
                                    mt="15px"
                                >
                                    Under the old way of doing things, medical practitioners and
                                    sales reps spent a significant portion of each day
                                    attempting to access and relay information. By streamlining
                                    communication, Medcore MD enables you to accomplish more.
                                </Typography>

                            </Grid>

                            <Grid
                                item
                                container
                                xl={7}
                                lg={7}
                                display="flex"
                                direction="row"
                                justifyContent="center"
                            >
                                <Hidden mdDown>
                                    <SchemeDesktop/>
                                </Hidden>
                                <Hidden mdUp>
                                    <SchemeMobile/>
                                </Hidden>
                            </Grid>
                        </Grid>
                        <div className={classes.arrow}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="136px" height="120px">
                                <defs>
                                    <linearGradient id="PSgrad_0" x1="0%" x2="0%" y1="100%" y2="0%">
                                        <stop offset="0%" stopColor="rgb(21,91,144)" stopOpacity="1"/>
                                        <stop offset="100%" stopColor="rgb(242,247,251)" stopOpacity="0"/>
                                    </linearGradient>

                                </defs>
                                <path fillRule="evenodd" fill="rgb(242, 247, 251)"
                                      d="M75.38,115.254 C71.959,120.589 64.259,120.589 61.180,115.254 L2.50,12.811 C-1.27,7.478 2.821,0.812 8.979,0.812 L127.239,0.812 C133.397,0.812 137.246,7.478 134.167,12.811 L75.38,115.254 Z"/>
                                <path stroke="rgb(0, 0, 0)" strokeWidth="0px" strokeLinecap="butt"
                                      strokeLinejoin="miter"
                                      fill="url(#PSgrad_0)"
                                      d="M75.38,115.254 C71.959,120.589 64.259,120.589 61.180,115.254 L2.50,12.811 C-1.27,7.478 2.821,0.812 8.979,0.812 L127.239,0.812 C133.397,0.812 137.246,7.478 134.167,12.811 L75.38,115.254 Z"/>
                            </svg>
                        </div>
                    </Container>
                </Container>
            </Container>

            <Container
                maxWidth={false}
                disableGutters={true}
                sx={{
                    backgroundColor: '#f2f7fb',
                    py: '115px',
                    mt: '150px',
                    [medical.breakpoints.down('md')]: {
                        mt: '20px'
                    }
                }}
            >
                <Container maxWidth="xl" sx={{position: 'relative'}}>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="end"
                        spacing={2}
                    >
                        <Grid item xl={12} mb={{xl: 0, lg: '50px'}}>
                            <Typography variant="h6">TURN TECH INNOVATION INTO</Typography>
                            <Typography variant="h2">
                                OPERATIONAL EFFICIENCY
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                color={medical.colors.grey}
                                fontWeight="400"
                                mt="15px"
                            >
                                A tool to fill the communication gap between reps and medical
                                practitioners that can be accessible anytime, from any device.
                            </Typography>
                        </Grid>
                        <Grid item xl={12} xs={12}
                              sx={{
                                  [medical.breakpoints.down('xl')]: {
                                      mt: '20px'
                                  }
                              }}
                        >
                            <Stack
                                mt={{xl: '90px', lg: '50px'}}
                                direction={{md: 'row'}}
                                justifyContent={{xl: 'center', xs: 'center'}}
                                alignItems="center"
                                spacing={5}
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    width="100%"
                                    maxWidth="max-content"
                                    height="fit-content"
                                    p="15px 20px"
                                    boxShadow="10px 10px 20px #0F75BC33"
                                    borderRadius="5px"
                                    sx={{
                                        [medical.breakpoints.down('md')]: {
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            maxWidth: '130px',
                                        }
                                    }}
                                >
                                    <SvgIcon
                                        component={repsIcon}
                                        inheritViewBox
                                        sx={{
                                            width: '44px',
                                            height: '44px',
                                            mr: '18px',
                                            [medical.breakpoints.down('md')]: {
                                                mr: '0',
                                            }
                                        }}
                                    />
                                    <Typography
                                        variant="subtitle2"
                                        fontSize="14px"
                                        fontWeight="900"
                                        lineHeight="17px"
                                        sx={{
                                            [medical.breakpoints.down('md')]: {
                                                mt: '10px',
                                                lineHeight: '15px'
                                            }
                                        }}
                                    >
                                        Medical
                                        <br/>
                                        Representative
                                    </Typography>
                                </Box>
                                <SvgIcon
                                    component={doubleArrow}
                                    inheritViewBox
                                    sx={{
                                        width: '30px',
                                        height: '34px',
                                        [medical.breakpoints.down('md')]: {
                                            margin: '20px 0',
                                            transform: 'rotate(90deg)'
                                        }
                                    }}
                                />
                                <Box p="20px" border="1px solid #0F75BC" borderRadius="5px">
                                    <Box
                                        component="img"
                                        alt="img"
                                        src={logo}
                                        sx={{
                                            height: 50,
                                            width: 220,
                                            verticalAlign: 'middle',
                                        }}
                                    />
                                </Box>
                                <SvgIcon
                                    component={doubleArrow}
                                    inheritViewBox
                                    sx={{
                                        width: '30px',
                                        height: '34px',
                                        [medical.breakpoints.down('md')]: {
                                            margin: '20px 0',
                                            transform: 'rotate(90deg)'
                                        }
                                    }}
                                />
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    width="100%"
                                    maxWidth="max-content"
                                    height="fit-content"
                                    p="15px 20px"
                                    boxShadow="10px 10px 20px #0F75BC33"
                                    borderRadius="5px"
                                    sx={{
                                        [medical.breakpoints.down('md')]: {
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            maxWidth: '130px'
                                        }
                                    }}
                                >
                                    <SvgIcon
                                        component={practIcon}
                                        inheritViewBox
                                        sx={{
                                            width: '44px',
                                            height: '44px',
                                            mr: '18px',
                                            [medical.breakpoints.down('md')]: {
                                                mr: '0',
                                            }
                                        }}
                                    />
                                    <Typography
                                        variant="subtitle2"
                                        fontSize="14px"
                                        fontWeight="900"
                                        lineHeight="17px"
                                        sx={{
                                            [medical.breakpoints.down('md')]: {
                                                mt: '10px',
                                                lineHeight: '15px'
                                            }
                                        }}
                                    >
                                        Medical
                                        <br/>
                                        Practitioner
                                    </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Container>

            <Container
                maxWidth={false}
                disableGutters={true}
                sx={{
                    my: '70px',
                    [medical.breakpoints.down('md')]: {
                        my: '30px',
                    },
                    p: '40px 0',
                }}
            >
                <Container maxWidth="xl">
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item xl={12}>
                            <Typography variant="h2">KEY ADVANTAGES</Typography>
                            <Typography
                                variant="subtitle2"
                                color={medical.colors.grey}
                                fontWeight="400"
                                mt="15px"
                            >
                                Leveraging cutting-edge technology, Medcore MD bridges the
                                communication gap between practices and reps and simplifies
                                day-to-day operations.
                            </Typography>
                        </Grid>

                        <Grid item container xl={12} lg={12} columnSpacing={10} mt={8}>
                            <Grid item xl={6} lg={6} md={6} xs={12} textAlign={'center'} display={'flex'}
                                  justifyContent={'center'}>
                                <Stack className={classes.roleCard} direction="column" mb="20px">
                                    <div className={classes.circleBlueIcon}>
                                        <img alt={"Representative"} src={RepresentativeIcon}/>
                                    </div>

                                    <Typography variant="subtitle2" color={medical.colors.blue} fontWeight="900"
                                                mt="20px">
                                        Tools for Sales Reps
                                    </Typography>
                                    <Typography variant="body1" mt="10px">
                                        In addition to streamlined communication, Medcore MD helps
                                        sales reps generate leads and track sales metrics.
                                    </Typography>
                                    <Button
                                        title={"Medical Reps"}
                                        component={Link}
                                        to="/reps"
                                        size="large"
                                        sx={{mt: '20px'}}
                                    >
                                        Learn More
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} xs={12} textAlign={'center'} display={'flex'}
                                  justifyContent={'center'}>
                                <Stack className={classes.roleCard} direction="column">
                                    <div className={classes.circleBlueIcon}>
                                        <img alt="Practitioner" src={PractitionerIcon}/>
                                    </div>
                                    <Typography variant="subtitle2" color={medical.colors.blue} fontWeight="900"
                                                mt="20px">
                                        Tools for Practices
                                    </Typography>
                                    <Typography variant="body1" mt="10px">
                                        In today&#39;s fast-paced environment, Medcore MD makes it
                                        easy for practices to efficiently and accurately organize
                                        info.
                                    </Typography>
                                    <Button
                                        title={"Medical Practitioners"}
                                        component={Link}
                                        to="/practitioners"
                                        size="large"
                                        sx={{mt: '20px'}}
                                    >
                                        Learn More
                                    </Button>

                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Container>
            <SignUpSection/>
            <Footer/>
        </div>
    )
}

export default HomePage
