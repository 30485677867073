import React, {useEffect} from 'react';
import {Container, List, ListItem, Typography} from '@mui/material';
import Footer from '../components/Footer'
import {medical} from "../theme";

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = 'Privacy Policy | MedcoreMD';
  }, []);

  return (
    <>
      <Container
        maxWidth={false}
        disableGutters={true}
        sx={{
          mt: '100px',
          mb: '100px',
          flex: 1,
          [medical.breakpoints.down('md')]: {
            mt: '50px',
            mb: '50px',
          },
          [medical.breakpoints.down('sm')]: {
            mt: '30px',
            mb: '30px',
          }
        }}
      >
        <Container maxWidth="xl">
          <Typography variant="h2">Privacy Policy</Typography><br/>
          <Typography variant="body1">Last updated: July 14, 2023</Typography><br/>

          <Typography variant="h6" fontWeight={900}>Introduction</Typography><br/>
          <Typography variant="body1">
            Medcore is dedicated to protecting your personal information and informing you about
            how we use your personal information.  Medcore is the owner and operator of the
            Platform and collects information from you (“you”, “your”, or “user”) a user of the
            Platform.  This Privacy Policy should be read in conjunction with the User Terms and
            the Additional Terms and is integrated into those agreements.  All capitalized proper
            nouns not defined in this Privacy Policy will have the same definitions and meanings as
            defined by the User Terms and the Additional Terms. Please review this Privacy Policy
            periodically as we may revise it from time to time. If you do not agree with or accept our
            Privacy Policy in its entirety, you must not access or use the Platform. If you use the
            Platform following a change to the terms of this Privacy Policy you agree to accept the
            revised policies.
          </Typography><br/>

          <Typography variant="h6" fontWeight={900}>Information Collected</Typography><br/>
          <Typography variant="body1">At Construction Platform, we collect personally identifiable information (“PII”) and non-
            personally identifiable (“Non-PII”) information from you.  Personally identifiable
            information is information that can be used to identify you personally.  Non-personally
            identifiable information is information that must be combined with other information to
            identify you personally.</Typography><br/>
            <br/><u>PII Collected</u><br/>
          <Typography variant="body1">You will not be required to provide us any information when you visit our Platform.
            However, when you interact with our Platform we may collect your: name, email,
            address, billing information, account sign up, business information, Authorized User
            information, and other personal information.   Where we accept electronic payment,
            please be aware that all payment information shall be collected, stored, and processed
            by our third party payment processors.</Typography><br/>
          <br/><u>Non-PII Collected</u><br/>
          <Typography variant="body1">
            Whenever you use our Platform, we may collect Non-PII from you,  including but not
            limited to your IP address, zip code, browsing history, search history, and registration
            history,   interactions   with   the  Platform,   usage   information,   location,   referring   URL,
            browser, operating system, data usage, data transferred, and Internet service provider.</Typography><br/>

          <Typography variant="h6" fontWeight={900}>Use of Your Information</Typography><br/>
          <Typography variant="body1">Some of your information will be visible to other users of the Platform to facilitate
            communication. We will never sell your information without your permission; however
            you agree that we may use your information in the following ways:</Typography>
          <List sx={{
            listStyleType: 'disc',
            pl: 2,
            '& .MuiListItem-root': {
              display: 'list-item',
              paddingLeft: 0
            }
          }}
          >
            <ListItem>To provide any services offered and to operate the Medcore Platform and to
              share with other users of the Platform.</ListItem>
            <ListItem>To enhance or improve our users’ experiences.</ListItem>
            <ListItem>To contact you via email or other electronic communications when you have an
              inquiry or to update you about products and services offered by our Platform or
              our partners.</ListItem>
            <ListItem>To notify you of additional Medcore services and updates.</ListItem>
            <ListItem>To process your transactions</ListItem>
            <ListItem>To share your information with third party partners or third parties hired by us to
              perform functions and provide services to us subject to the obligations consistent
              with this Privacy Policy</ListItem>
          </List>

          <Typography variant="h6" fontWeight={900}>Anonymized Data</Typography><br/>
          <Typography variant="body1">Please be aware that we may collect and aggregate personally identifiable information
            from our Platform and may anonymize that information for our own research or internal
            purposes.  Once such data has been anonymized, it cannot be traced back to you, the
            user.</Typography><br/>

          <Typography variant="h6" fontWeight={900}>Accessing and Editing Your Information</Typography><br/>
          <Typography variant="body1">If you have any additional questions or wish to review, change, or access any of your
            information collected by us, please contact us at info@medcoremd.com. After you have
            cancelled your account your PII may become immediately inaccessible. Please be
            aware that we may keep inaccessible copies of your PII and non-PII subject to our data
            retention policies which are subject to change without notice.</Typography><br/>

          <Typography variant="h6" fontWeight={900}>Cookies and Tracking</Typography><br/>
          <Typography variant="body1">We use cookies for to remember you and to understand how you use the Platform.
            Additionally, some third party cookies integrated into our Platform may track your usage
            information and interactions with the Platform. If you disable cookies from your web
            browser some portions of our Platform may not work as expected.</Typography><br/>


          <Typography variant="h6" fontWeight={900}>Third Party Access to Your Information</Typography><br/>
          <Typography variant="body1">Although you are entering into an agreement with Medcore to disclose your information
            to us, we do use third party individuals and organizations to assist us, including
            contractors, web hosts, and others to allow you to access the Platform.  Specifically, we
            share information with third parties to assist us in running the Platform and operations.
            Further, we share information with third parties to assist in marketing, analytics,
            advertising, interacting with us, and sending you notifications<br/>
            <p>
            Throughout the course of our provision of our services to you, we may delegate our
            authority to collect, access, use, and disseminate your information.  It is therefore
            necessary that you grant the third parties we may use in the course of our business the
            same rights that you afford us under this Privacy Policy.  For this reason, you hereby
            agree that for every authorization which you grant to us in this Privacy Policy, you also
            grant to any third party that we may hire, contract, or otherwise retain the services of for
            the purpose of operating, maintaining, repairing, or otherwise improving or preserving
            our Platform or its underlying files or systems.  You agree not to hold us liable for the
            actions of any of these third parties, even if we would normally be held vicariously liable
            for their actions, and that you must take legal action against them directly should they
            commit any tort or other actionable wrong against you.
            </p>
          </Typography>


          <Typography variant="h6" fontWeight={900}>Law Enforcement</Typography><br/>
          <Typography variant="body1">
            You agree that we may disclose your information to authorities if compelled to by a
            court order.  Additionally, you agree that we may disclose your information if we
            reasonably believe that you have violated US laws, the terms of our Terms of Service or
            our Privacy Policy, or if we believe that a third party is at risk of bodily or economic
            harm.   In the event that we receive a subpoena affecting your privacy, we may elect to
            notify you to give you an opportunity to file a motion to quash the subpoena, or we may
            attempt to quash it ourselves, but we are not obligated to do either.  We may also
            proactively report you and release your information without receiving any request to
            third parties where we believe that it is proper to do so for legal reasons, where your
            actions violate any law of the United States or any other country having jurisdiction over
            us, our Platform, or our Terms of Service.  You release us from any damages that may
            arise from or relate to the release of your information to a request from law enforcement
            agencies or private litigants.  We may release your information under the conditions
            listed in this paragraph whether it is to individuals or entities and to any state or federal
            authorities, as required.</Typography><br/>

          <Typography variant="h6" fontWeight={900}>Opt Out of Commercial, Non-Commercial Communications and Do Not Track</Typography><br/>
          <Typography variant="body1"> you decide to provide us with your contact information, you agree that we may send
            you communications via text, automated calls, and emails.  However, you may
            unsubscribe from certain communications by notifying Medcore that you no longer wish
            to receive these communications, we will endeavour to promptly remove you from our
            list once we have received that request.   Some Internet browsers may be configured to
            send &apos;Do Not Track&apos; signals to the online services that you visit. We currently do not
            respond to &apos;Do Not Track&apos; or similar signals. To find out more about &apos;Do Not Track&apos;,
            please visit https://www.allaboutdnt.com. </Typography><br/>

          <Typography variant="h6" fontWeight={900}>Third Parties</Typography><br/>
          <Typography variant="body1">Medcore or other users may post links to or embed third party websites on the Platform,
            which may include information that we have no control over.  When accessing a third
            party website or embedded content through our Platform, you acknowledge that you are
            aware that these third party websites are not screened for privacy or security issues by
            us, and you release us from any liability for the conduct of these third party websites.<br/>
            <p>
              Please be aware that this Privacy Policy, and any other policies in place, in addition to
              any amendments, does not create rights enforceable by third parties.  Medcore bears
              no responsibility for the information collected or used by any advertiser or third party
              websites. You must review their policies to understand how their information collection
              practices work.
            </p>
          </Typography><br/>

          <Typography variant="h6" fontWeight={900}>Security Measures</Typography><br/>
          <Typography variant="body1">
            We make reasonable attempts to protect your information by using physical and
            electronic safeguards. However, as this is the Internet, we can make no guarantees as
            to the security or privacy of your information.  For this reason, we recommend that you
            use anti-virus software, perform routine credit checks, enable firewalls, and take other
            precautions to protect yourself from security and privacy threats.
          </Typography><br/>

          <Typography variant="h6" fontWeight={900}>Your California Privacy Rights</Typography><br/>
          <Typography variant="body1">
            Medcore permits residents of the State of California to use its Platform, and complies
            with the California Business and Professions Code §§ 22575-22579.  If you are a
            California resident you may request certain information regarding our disclosure of
            personal information to any third parties for their direct marketing purposes.  Various
            provisions throughout this Privacy Policy address requirements of the Californian
            privacy statutes. Although we do not disseminate your information to third parties
            without permission, you must presume that we collect electronic information from all
            visitors. You may contact us at info@medcoremd.com  with any questions.
          </Typography><br/>

          <Typography variant="h6" fontWeight={900}>Age Compliance</Typography><br/>
          <Typography variant="body1">
            We do not knowingly collect or process any information for any persons under the age
            of 18. Users must be over the age of 18 to submit any information through the Platform,
            if you are under 18 and using our Platform, please do not submit any information to us.
            In the event that we have inadvertently collected any information from users under the
            age of 18 please contact us immediately.
          </Typography><br/>

          <Typography variant="h6" fontWeight={900}>International Transfer</Typography><br/>
          <Typography variant="body1">
            Your information may be transferred to - and maintained on - computers located outside
            of your state, province, country, or other governmental jurisdiction where the privacy
            laws may not be as protective as those in your jurisdiction.  Your consent to this Privacy
            Policy followed by your submission of such information represents your agreement to
            that transfer.  PII and Non-PII submitted to Medcore will be collected, processed, stored,
            disclosed and disposed of in accordance with applicable U.S. law and this policy. If you
            are a non-U.S. member, you acknowledge and agree that Medcore may collect and use
            your Information and disclose it to other entities outside your resident jurisdiction. In
            addition, such information may be stored on servers located outside your resident
            jurisdiction. U.S. law may not provide the degree of protection for information that is
            available in other countries.
          </Typography><br/>

          <Typography variant="h6" fontWeight={900}>Merger and Acquisition</Typography><br/>
          <Typography variant="body1">
            In the event that Medcore is involved in a bankruptcy, merger, acquisition,
            reorganization or sale of assets, your information may be sold or transferred as part of
            that transaction. Please be aware that once the information is transferred your privacy
            rights may change.
          </Typography><br/>

        </Container>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
