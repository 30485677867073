import authService from '../../../services/authService';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {nextStep} from '../../slices/registerSlice';
import {acceptInviteForm, registerData, registerForm} from '../../types';
import {setEmail, setTokens} from '../../slices/verifyEmailSlice';
import {openPopup} from "../../slices/popup.slice";
import {recaptchaExecute, recaptchaIsReady} from "../../../helpers/recaptcha";

export const register = createAsyncThunk<registerData, registerForm, { rejectValue: string }>(
// action type string
    'auth/signup',
// callback function
    async ({role, email, password}, thunkApi) => {

        try {
            if (!recaptchaIsReady()) {
                thunkApi.dispatch(openPopup({
                    message: 'reCAPTCHA is not ready yet. Please try again later.',
                    type: 'error',
                }))
                return;
            }

            const recaptcha_token = await recaptchaExecute('SIGN_UP');
            if(!recaptcha_token) {
                thunkApi.dispatch(openPopup({
                    message: 'reCaptcha token is not valid. Please try again later.',
                    type: 'error',
                }))
                return;
            }

            // make request to backend
            const response = await authService.register(role, email, password, recaptcha_token)
            const {success, message, data} = response.data

            if (!success) {
                thunkApi.dispatch(openPopup({
                    message: message,
                    type: 'error',
                }))
                return thunkApi.rejectWithValue(message)
            }

            thunkApi.dispatch(setTokens({
                accessToken: data.accessToken,
                refreshToken: data.refreshToken,
                tokenVerify: data.tokenVerify,
            }))
            thunkApi.dispatch(setEmail({email: email}))
            thunkApi.dispatch(nextStep())

            return data

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            thunkApi.dispatch(openPopup({
                message: error.message,
                type: 'error',
            }))
            return thunkApi.rejectWithValue(error.message)
        }
    }
)

// eslint-disable-next-line
export const checkInvitation = createAsyncThunk<any, string | undefined, { rejectValue: string }>(
// action type string
    'auth/checkInvitation',
// callback function
    async (id, thunkApi) => {

            try {
                // make request to backend
                const response = await authService.checkInvitation(id)
                const {success, message, data} = response.data

                if (!success) {
                    return thunkApi.rejectWithValue(message)
                }

                return data

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                console.log(error)
                if (error) {
                    return thunkApi.rejectWithValue(error.message)
                }
            }
    }
)

export const acceptInvite = createAsyncThunk<registerData, acceptInviteForm, { rejectValue: string }>(
// action type string
    'auth/acceptInvite',
// callback function
    async ({id, password, confirmPassword}, thunkApi) => {

            try {
                // make request to backend
                const response = await authService.acceptInvite(id, password, confirmPassword)
                const {success, message, data} = response.data
                console.log(response.data, "RESPONSE DATA REGISTER")

                if (!success) {
                    return thunkApi.rejectWithValue(message)
                }

                thunkApi.dispatch(setTokens({
                    accessToken: data.accessToken,
                    refreshToken: data.refreshToken,
                    tokenVerify: data.tokenVerify,
                }))

                window.location.href = process.env.REACT_APP_OFFICE_URL || ''

                return data

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                console.log(error)
                if (error) {
                    return thunkApi.rejectWithValue(error.message)
                }
            }
    }
)
