import React from 'react';
import {Button, Container, Stack, SvgIcon, Theme, Typography} from '@mui/material';
import {makeStyles} from "@mui/styles";
import {ReactComponent as SuccessIcon} from '../../../assets/icons/success.svg';
import {ReactComponent as ErrorIcon} from '../../../assets/icons/error.svg';
import {ReactComponent as SentIcon} from '../../../assets/icons/sent.svg';
import {useTypedDispatch, useTypedSelector} from "../../../store";
import {closePopup} from "../../../store/slices/popup.slice";

const useStyles = makeStyles((medical: Theme) => ({
    popupWrapper: {
        '&.MuiContainer-root': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            zIndex: 9999
        }
    },
    popup: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        textAlign: 'center',
        width: '100%',
        maxWidth: '550px',
        padding: '40px 20px',
        backgroundColor: medical.colors.white,
        borderRadius: '10px',
        boxShadow: '10px 10px 20px #0000001A',
    }


}));

interface Props {
    isActive?: boolean
}

const NotificationPopup: React.FC<Props> = () => {
    const classes = useStyles()

    const {type, message, show} = useTypedSelector(state => state.popupReducer)
    const dispatch = useTypedDispatch()
    if (!show) return null

    return (
        <Container maxWidth={false} className={classes.popupWrapper}>
            <Stack className={classes.popup}>
                {type === 'success' && (
                    <>
                        <SvgIcon component={SuccessIcon} inheritViewBox
                                 sx={{
                                     width: '92px',
                                     height: '86px',
                                     mb: '20px'
                                 }}
                        />
                        <Typography variant="h4" mb="10px">SUCCESS</Typography>
                    </>)}

                {type === 'sent' && (
                    <>
                        <SvgIcon
                            component={SentIcon}
                            inheritViewBox
                            sx={{
                                width: '150px',
                                height: '86px',
                                mb: '20px'
                            }}
                        />
                        <Typography variant="h4" mb="10px">SUCCESS</Typography>
                    </>)}

                {type === 'error' && (
                    <>
                        <SvgIcon
                            component={ErrorIcon}
                            inheritViewBox
                            sx={{
                                width: '82px',
                                height: '82px',
                                mb: '20px'
                            }}
                        />
                        <Typography variant="h4" mb="10px">WE HAVE A PROBLEM...</Typography>
                    </>
                )}

                <Typography
                    maxWidth="400px"
                    marginBottom="40px"
                >
                    {message || 'Something went wrong. Please try again later.'}
                </Typography>

                <Button
                    size={'large'}
                    type="submit"
                    onClick={() => dispatch(closePopup())}
                    variant="contained"
                    sx={{maxWidth: '400px'}}
                >
                    {type === 'success' || type === 'sent' ? 'Continue' : 'Ok, i will try again'}
                </Button>
            </Stack>
        </Container>
    );
};

export default NotificationPopup;
