import React from "react";
import {TextField} from "@mui/material";
import {useField} from 'formik';

// TODO need to check
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TextFieldForm = (props: any) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';

    return (
        <TextField
            {...props}
            {...field}
            helperText={errorText}
            error={!!errorText}
        />
    );
}

export default TextFieldForm;