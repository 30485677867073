import React from "react";
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import { useField } from 'formik';

// TODO need to check
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CheckBoxForm = (props: any) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';

    return (
        <FormControl>
            <FormControlLabel
                {...props}
                {...field}
                control={<Checkbox sx={{marginLeft: '8px'}} />}
                sx={{
                    alignItems: 'start'
                }}
            />
            <FormHelperText error sx={{margin: '0'}}>{errorText}</FormHelperText>
        </FormControl>
    );
}

export default CheckBoxForm;