import {createSlice} from '@reduxjs/toolkit';
import {login} from '../actions/authActions/loginAction';
import {loginState} from '../types';

const initialState: loginState = {
    isLoggedIn: false,
    error: null,
    status: null,
    userID: null,
    tokenLogin: null,
    userVerify: false,
    isAuth: false,
    user: null
}

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        userVerifyStatus: (state) => {
            state.userVerify = true
        },
        setUser: (state, action) => {
            state.user = action.payload.email
            state.isAuth = true
        },
    },
    extraReducers: builder => {
        builder
            .addCase(login.pending, (state) => {
                state.isLoggedIn = false
                state.status = 'loading'
                state.error = null
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoggedIn = true
                state.status = 'resolved'
                state.userID = action.payload?.userID
                state.tokenLogin = action.payload?.tokenLogin
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoggedIn = false
                state.status = 'rejected'
                state.error = action.payload
            })
    }
})


export const {userVerifyStatus, setUser} = loginSlice.actions
export default loginSlice.reducer