import React from 'react'
import {Button, Container, Stack, Typography} from '@mui/material'
import imgFooter from '../../../assets/img/footer_bg.jpg'
import {Link as RouterLink} from 'react-router-dom'
import {useTheme} from '@mui/styles'

const SignUpSection = () => {

    const medical = useTheme()

    const coloredSquare = (color: string) => (
        <div style={{width: '10px', height: '10px', marginRight: '2px', marginLeft: '2px', backgroundColor: color}}/>
    );

    return (
        <Container
            maxWidth={false}
            sx={{
                pt: '80px',
                pb: '100px',
                position: 'relative',
                backgroundColor: medical.colors.blue,

                '&::before': {
                    content: '""',
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: '0px',
                    left: '0px',
                    right: '0px',
                    bottom: '0px',
                    backgroundImage: `url(${imgFooter})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    zIndex: 0,
                    [medical.breakpoints.down('md')]: {
                        opacity: '0.15',
                    }
                },

                [medical.breakpoints.down('md')]: {
                    '& .MuiTypography-h6': {
                        maxWidth: '90%!important',
                    }
                }
            }}
        >
            <Container maxWidth="xl" disableGutters={true} sx={{
                position: 'relative',
                zIndex: 1,
            }}>
                <Stack
                    direction="column"
                    alignItems="center"
                    textAlign="center"
                    maxWidth="800px"
                    margin="0 auto"
                >
                    <Typography variant="h6" color={medical.colors.white}>
                        MODERN MEETS MEDICINE
                    </Typography>

                    <Typography variant="h2" color={medical.colors.white} my={'15px'}>
                        TAKE COMMUNICATION TO THE NEXT LEVEL
                        <span style={{color: medical.colors.blue}}>.</span>
                    </Typography>

                    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} mb={'15px'}>
                        {coloredSquare('#F26D21')}
                        {coloredSquare('#00ADEE')}
                        {coloredSquare('#1CC0C4')}
                    </Stack>
                    <Typography variant="h6" color={medical.colors.white} mb={'10px'}>
                        Office Managers <span style={{color: '#1CC0C4'}}>love</span> Medcore MD
                    </Typography>
                    <Typography variant="h6" color={medical.colors.white} mb={'10px'}>
                        Medical Reps <span style={{color: '#00ADEE'}}>love</span> Medcore MD
                    </Typography>
                    <Typography variant="h6" color={medical.colors.white} mb={'10px'}>
                        Physicians <span style={{color: '#F26D21'}}>love</span> Medcore MD
                    </Typography>
                    <Button
                        title={"Sign Up"}
                        size="large"
                        component={RouterLink}
                        to="/sign-up"
                        sx={{
                            marginTop: '20px',
                        }}
                    >
                        Get Started Today
                    </Button>
                </Stack>
            </Container>
        </Container>
    );
};

export default SignUpSection
