import React, {useEffect} from 'react';
import {Container} from '@mui/material';
import {useTypedSelector} from '../store';
import ConfirmMail from '../components/ConfirmMail';
import SigInForm from '../components/SignInForm';
import Footer from "../components/Footer";
import {medical} from "../theme";

const SignIn: React.FC = () => {

    const {userVerify} = useTypedSelector(state => state.loginReducer)
    useEffect(() => {
        document.title = 'Sign In | MedcoreMD';
    }, []);

    return (
        <>
            <Container
                sx={{
                    mt: '100px',
                    mb: '100px',
                    flex: 1,
                    [medical.breakpoints.down('md')]: {
                        mt: '50px',
                        mb: '50px',
                    },
                    [medical.breakpoints.down('sm')]: {
                        mt: '30px',
                        mb: '30px',
                    }
                }}
                maxWidth={false}
                disableGutters={true}
            >
                {!userVerify ? <SigInForm/> : <ConfirmMail/>}
            </Container>
            <Footer/>
        </>
    )
};

export default SignIn;
