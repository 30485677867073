import React from 'react';
import {Box, Hidden, Stack, SvgIcon, Typography} from '@mui/material';
import Rocket from '../../assets/img/rocket.png';
import {Form, Formik} from 'formik';
import {validationSchemaSignin} from '../../helpers/validation';
import {login} from '../../store/actions/authActions/loginAction';
import TextFieldForm from '../UI/TextFieldForm';
import ShowPasswordField from '../UI/ShowPasswordField';
import {Link} from 'react-router-dom';
import {useTheme} from '@mui/styles';
import {useTypedDispatch, useTypedSelector} from '../../store';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from 'axios'

const SigInForm: React.FC = () => {

    const medical = useTheme()
    const dispatch = useTypedDispatch();
    const {status, isAuth, user} = useTypedSelector(state => state.loginReducer)
    const handleToDashboard = () => {
        window.open(`${process.env.REACT_APP_OFFICE_URL}`)
    }

    const handleLogout = () => {
        axios.get(`${process.env.REACT_APP_BASE_API_URL}auth/logout`).then(() => window.location.reload())
    }

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            maxWidth="1090px"
            m="0 auto"
            sx={{
                boxShadow: '10px 10px 20px #0000001A',
                [medical.breakpoints.down('md')]: {maxWidth: '70%'},
                [medical.breakpoints.down('sm')]: {maxWidth: '100%'},
            }}
        >
            <Hidden mdDown>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="#93A1B0"
                    borderRadius="10px"
                    width="50%"
                >
                    <Box
                        component="img"
                        alt="rocket"
                        src={Rocket}
                        sx={{
                            objectFit: 'contain',
                            width: '100%',
                            maxWidth: '470px',
                            height: 'auto',
                        }}
                    />
                </Stack>
            </Hidden>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width="50%"
                p="60px 70px"
                sx={{
                    [medical.breakpoints.down('md')]: {width: '100%', p: '30px'},
                }}
            >
                <Typography variant="h4">
                    {isAuth ? 'WELCOME BACK' : 'SIGN IN'}
                    <Typography variant="h4" component="span" color={medical.colors.blue}>
                        .
                    </Typography>
                </Typography>

                {isAuth ? (
                    <>
                        <Stack
                            width="100%"
                            direction="row"
                            justifyContent="space-between"
                            p="20px"
                            m="20px 0"
                            border={`1px solid ${medical.colors.lightGrey}`}
                            borderRadius="10px"
                        >
                            <Typography>{user}</Typography>
                            <SvgIcon
                                onClick={handleToDashboard}
                                component={ArrowForwardIosIcon}
                                inheritViewBox
                                cursor="pointer"
                            />
                        </Stack>
                        <Typography
                            variant="body2"
                            color={medical.colors.blue}
                            onClick={handleLogout}
                            sx={{cursor: 'pointer'}}
                        >
                            Logout
                        </Typography>
                    </>
                ) : (
                    <Formik
                        validateOnBlur={true}
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validationSchema={validationSchemaSignin}
                        onSubmit={values => {
                            dispatch(login(values))
                        }}
                    >
                        {() => (
                            <Stack component={Form} width="100%">
                                <Stack
                                    width="100%"
                                    m="40px 0 25px"
                                    sx={{
                                        [medical.breakpoints.down('md')]: {
                                            mt: '20px',
                                        },
                                    }}
                                >
                                    <TextFieldForm
                                        name="email"
                                        label="WORK EMAIL"
                                        variant="standard"
                                        sx={{
                                            mb: '50px',
                                            [medical.breakpoints.down('md')]: {mb: '25px'},
                                        }}
                                    />
                                    <ShowPasswordField
                                        autoComplete="on"
                                        name="password"
                                        label="PASSWORD"
                                        variant="standard"
                                        sx={{mb: '19px'}}
                                    />
                                    <Typography
                                        component={Link}
                                        variant="body2"
                                        color={medical.colors.lightGrey}
                                        width="fit-content"
                                        to="/forgot-password"
                                        sx={{mb: '50px', ml: 'auto'}}
                                    >
                                        Forgot Password?
                                    </Typography>
                                    <LoadingButton
                                        size={'large'}
                                        type="submit"
                                        loading={status === 'loading'}
                                        variant="contained"
                                        sx={{width: '100%', maxWidth: '250px', m: '0 auto'}}
                                    >
                                        Sign In
                                    </LoadingButton>
                                </Stack>
                            </Stack>
                        )}
                    </Formik>
                )}

                <Typography
                    component={Link}
                    variant="body2"
                    color={medical.colors.blue}
                    to="/sign-up"
                >
                    Create an account
                </Typography>
                <Typography variant={'body2'} textAlign={'center'} color={medical.colors.lightGrey} mt={'25px'} ml={'15px'}>
                    This site is protected by reCAPTCHA and the Google
                    <a style={{fontSize: '16px', color: medical.colors.blue}}
                       href={'https://policies.google.com/privacy'} target={'_blank'}
                       rel={"noreferrer"}> Privacy Policy</a> and
                    <a style={{fontSize: '16px', color: medical.colors.blue}}
                       href={'https://policies.google.com/terms'}
                       target={'_blank'} rel={"noreferrer"}> Terms of Service</a> apply.
                </Typography>
            </Stack>
        </Stack>
    )
};

export default SigInForm;
