import React, {useEffect} from 'react';
import {
    Box, CircularProgress,
    Container,
    Divider,
    Hidden,
    Stack,

    TextField,
    Typography
} from '@mui/material';
import Rocket from '../assets/img/rocket.png';
import {Form, Formik} from 'formik';
import {validationSchemaResetPassword} from '../helpers/validation';
import {useParams} from 'react-router-dom';
import {useTheme} from '@mui/styles';
import {useTypedDispatch, useTypedSelector} from '../store';
import ShowPasswordField from '../components/UI/ShowPasswordField';
import LoadingButton from '@mui/lab/LoadingButton';
import {checkInvitation} from '../store/actions/authActions/registerAction';
import Popper from "@mui/material/Popper";
import PasswordChecklist from "react-password-checklist";
import {acceptInvite} from "../store/actions/authActions/registerAction";
import Footer from "../components/Footer";

// eslint-disable-next-line
const ROLE_NAMES: any = {
    1: 'Medical Practitioner',
    2: 'Office Manager',
}
const InvitationPage: React.FC = () => {

    const medical = useTheme()
    const dispatch = useTypedDispatch();
    const {isValid, data: invitationData} = useTypedSelector(state => state.invitationReducer)
    const {id: invitationID} = useParams();
    const [passwordChecklistEl, setPasswordChecklistEl] = React.useState<HTMLButtonElement | null>(null);
    const [password, setPassword] = React.useState('');


    useEffect(() => {
        dispatch(checkInvitation(invitationID))
    }, [invitationID]);

    const handleClickPasswordInput = (event: React.MouseEvent<HTMLButtonElement>) => {
        setPasswordChecklistEl(event.currentTarget);
    };

    const handleClickPasswordInputClose = () => {
        setPasswordChecklistEl(null);
    };

    const open = Boolean(passwordChecklistEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        document.title = 'Invitation | MedcoreMD';
    }, []);

    if(isValid === null) {
        return (
            <>
                <Container
                    maxWidth={false}
                    disableGutters={true}
                    sx={{
                        mt: '100px',
                        mb: '100px',
                        flex: 1,
                        [medical.breakpoints.down('md')]: {
                            mt: '50px',
                            mb: '50px',
                        },
                        [medical.breakpoints.down('sm')]: {
                            mt: '30px',
                            mb: '30px',
                        }
                    }}
                >
                    <Stack
                        direction="column"
                        justifyContent="stretch"
                        alignItems="center"
                        maxWidth="1090px"
                        m="0 auto"
                        p={5}
                        sx={{
                            boxShadow: '10px 10px 20px #0000001A',
                            [medical.breakpoints.down('md')]: {maxWidth: '70%'},
                            [medical.breakpoints.down('sm')]: {maxWidth: '100%'},
                        }}
                    >
                        <Typography variant="h4" mb="10px">
                            One moment please...
                        </Typography>
                        <CircularProgress color={"secondary"}/>
                    </Stack>
                </Container>
                <Footer/>
            </>
        );
    }

    if(isValid !== true) {
        return (
            <>
                <Container
                    maxWidth={false}
                    disableGutters={true}
                    sx={{
                        flex: 1,
                        mt: '100px',
                        mb: '100px',
                    }}
                >
                    <Stack
                        direction="column"
                        justifyContent="stretch"
                        alignItems="center"
                        maxWidth="1090px"
                        textAlign={"center"}
                        m="0 auto"
                        p={5}
                        sx={{
                            boxShadow: '10px 10px 20px #0000001A',
                            [medical.breakpoints.down('md')]: {maxWidth: '70%'},
                            [medical.breakpoints.down('sm')]: {maxWidth: '100%'},
                        }}
                    >
                        <Typography variant="h4" mb="10px">
                            Invitation is invalid
                        </Typography>
                        <Typography variant="body1" mb="10px">
                            Unfortunately, the invitation is invalid or has expired. <br/>
                            Please contact your office manager, or try creating a new account.
                        </Typography>
                    </Stack>
                </Container>
                <Footer/>
            </>
        )
    }

    // eslint-disable-next-line
    const roles = invitationData.roles.map((role: any) => ROLE_NAMES[role]).join(', ');
    return (
        <>
            <Container
                maxWidth={false}
                disableGutters={true}
                sx={{
                    flex: 1,
                    mt: '100px',
                    mb: '100px',
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    maxWidth="1090px"
                    m="0 auto"
                    sx={{
                        boxShadow: '10px 10px 20px #0000001A',
                        [medical.breakpoints.down('md')]: {maxWidth: '70%'},
                        [medical.breakpoints.down('sm')]: {maxWidth: '100%'},
                    }}
                >
                    <Hidden mdDown>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            bgcolor="#93A1B0"
                            borderRadius="10px"
                            width="50%"
                        >
                            <Box
                                component="img"
                                alt="rocket"
                                src={Rocket}
                                sx={{
                                    objectFit: 'contain',
                                    width: '100%',
                                    maxWidth: '470px',
                                    height: 'auto',
                                }}
                            />
                        </Stack>
                    </Hidden>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                        width="50%"
                        p="60px 70px"
                        sx={{
                            [medical.breakpoints.down('md')]: {width: '100%', p: '30px'},
                        }}
                    >
                        <Typography variant="h4" mb="10px">
                            Invitation to join the team
                            <Typography
                                variant="h4"
                                component="span"
                                color={medical.colors.blue}
                            >
                                !
                            </Typography>
                        </Typography>

                        <Formik
                            validateOnBlur={true}
                            initialValues={{
                                id: invitationID || '',
                                password: '',
                                confirmPassword: '',
                            }}
                            validationSchema={validationSchemaResetPassword}
                            onSubmit={async (values, {setSubmitting }) => {
                                //dispatch(resetPassword(values))
                                setSubmitting(true);
                                await dispatch(acceptInvite(values))
                                setSubmitting(false);
                            }}
                        >
                            {({isSubmitting}) => (
                                <Stack component={Form} width="100%">
                                    <Stack
                                        width="100%"
                                        m="20px 0 25px"
                                        sx={{
                                            [medical.breakpoints.down('md')]: {
                                                mt: '20px',
                                            },
                                        }}
                                    >
                                        <TextField
                                            name="account_name"
                                            label="ACCOUNT NAME"
                                            variant="standard"
                                            sx={{mb: '19px'}}
                                            disabled
                                            value={invitationData?.account_name}
                                        />
                                        <TextField
                                            name="roles"
                                            label="Roles"
                                            variant="standard"
                                            sx={{mb: '19px'}}
                                            disabled
                                            value={roles}
                                        />
                                        <TextField
                                            name="email"
                                            label="EMAIL"
                                            variant="standard"
                                            sx={{mb: '19px'}}
                                            disabled
                                            value={invitationData?.email}
                                        />

                                        <ShowPasswordField
                                            name="password"
                                            label="PASSWORD"
                                            variant="standard"
                                            sx={{mb: '19px'}}
                                            onFocus={handleClickPasswordInput}
                                            onBlur={handleClickPasswordInputClose}
                                            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                setPassword((e.target as HTMLInputElement).value)
                                            }}
                                        />
                                        <Popper
                                            id={id}
                                            open={open}
                                            anchorEl={passwordChecklistEl}
                                            placement={'top-start'}
                                        >
                                            <Box
                                                sx={{
                                                    boxShadow: '5px 5px 20px #0000001A',
                                                    marginY: 1,
                                                    bgcolor: 'background.paper',
                                                    borderRadius: '5px',
                                                }}
                                            >
                                                <Stack sx={{p: 2}}>
                                                    <Box
                                                        sx={{
                                                            marginBottom: 1,
                                                            fontSize: '16px',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Password requirements
                                                    </Box>
                                                    <Divider sx={{marginBottom: 2}} />
                                                    <PasswordChecklist
                                                        rules={[
                                                            'minLength',
                                                            'specialChar',
                                                            'number',
                                                            'capital',
                                                            'lowercase'
                                                        ]}
                                                        minLength={8}
                                                        value={password}
                                                        iconSize={12}
                                                        messages={{
                                                            minLength: 'Have at least 8 characters',
                                                            specialChar:
                                                                'Have at least one special character [!@#$%^&*]',
                                                            number: 'Have at least one number [1, 2, 3, ...]',
                                                            capital:
                                                                'Have at least one capital letter [A, B, C, ...]',
                                                            lowercase: 'Have at least one lowercase letter [a, b, c, ...]'
                                                        }}
                                                        style={{
                                                            fontSize: '14px',
                                                        }}
                                                        className="passwordChecklist"
                                                    />
                                                </Stack>
                                            </Box>
                                        </Popper>
                                        <ShowPasswordField
                                            name="confirmPassword"
                                            label="CONFIRM PASSWORD"
                                            variant="standard"
                                            sx={{mb: '25px'}}
                                        />

                                        <Typography variant='body2' sx={{mb: '25px'}}>
                                            You can change data later in your profile settings.
                                            If you don&apos;t want to join the team, just ignore this form, after 24 hours the invitation will be canceled.
                                        </Typography>
                                        <LoadingButton
                                            size={'large'}
                                            type="submit"
                                            loading={isSubmitting}
                                            variant="contained"
                                            sx={{width: '100%', maxWidth: '250px', m: '0 auto'}}
                                        >
                                            Accept invitation
                                        </LoadingButton>
                                    </Stack>
                                </Stack>
                            )}
                        </Formik>
                    </Stack>
                </Stack>
            </Container>
            <Footer/>
        </>
    )
};

export default InvitationPage;
