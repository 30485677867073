import React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {Box, Container, Link, Stack, Typography} from '@mui/material'
import logo from '../../assets/img/logo.png'
import {makeStyles, useTheme} from '@mui/styles'
import {Theme} from '@mui/material/styles'
import {ReactComponent as FacebookIcon} from '../../assets/icons/facebook.svg'
import {ReactComponent as LinkedinIcon} from '../../assets/icons/linkedin.svg'
import {ReactComponent as InstagramIcon} from '../../assets/icons/instagram.svg'
import {ReactComponent as TwitterIcon} from '../../assets/icons/twitter.svg'
import FooterIcon from '../FooterIcon'

const useStyles = makeStyles((medical: Theme) => ({
    title: {
        '&.MuiTypography-root': {
            [medical.breakpoints.down('lg')]: {
                fontSize: 18
            },
            fontWeight: 900,
            color: medical.colors.grey,
            position: 'relative',
            marginBottom: '20px',
            '&:after': {
                content: '""',
                position: 'absolute',
                width: '30px',
                height: '3px',
                backgroundColor: medical.colors.blue,
                bottom: '-10px',
                left: 0,
            },
        },
    },
    flink: {
        fontSize: '18px',
        fontWeight: 400,
        color: medical.colors.grey,
        textDecoration: 'none',
        marginBottom: '20px',
        '&:hover': {
            color: medical.colors.blue,
        },
    },
}))

const Footer = () => {
    const classes = useStyles()
    const medical = useTheme()

    return (
        <Container maxWidth={false} sx={{backgroundColor: '#F1F7FB'}}>
            <Container
                maxWidth="xl"
                disableGutters={true}
                sx={{
                    pt: '40px',
                    pb: '50px',
                }}
            >
                <Stack
                    direction="row"
                    sx={{
                        [medical.breakpoints.down('md')]: {
                            flexDirection: 'column-reverse'
                        },
                    }}
                >
                    <Stack maxWidth="310px" mr="10vw">
                        <RouterLink to="/">
                            <Box
                                component="img"
                                alt="img"
                                src={logo}
                                sx={{
                                    height: 70,
                                    width: 307,
                                }}
                            />
                        </RouterLink>
                        <Typography variant="body1" color={medical.colors.grey} mt="10px">
                            A digital platform designed to simplify communication between
                            medical reps and medical practitioners.
                            <br/>
                            <span
                                style={{
                                    marginTop: '10px',
                                    display: 'block',
                                }}
                            >
									@ {new Date().getFullYear()} Medcore MD
								</span>
                        </Typography>
                    </Stack>

                  <Stack
                      width="100%"
                      direction="row"
                      justifyContent="space-between"
                      sx={{
                        [medical.breakpoints.down('md')]: {
                          flexDirection: 'column'
                        },
                      }}
                  >
                    <Stack
                        sx={{
                          [medical.breakpoints.down('md')]: {
                            mb: '20px'
                          },
                        }}
                    >
                      <Typography variant="subtitle2" className={classes.title}>
                        Menu
                      </Typography>
                      <RouterLink title={"Medical Reps"} to="/reps" className={classes.flink}>
                        Medical Reps
                      </RouterLink>
                      <RouterLink title={"Medical Practitioners"} to="/practitioners" className={classes.flink}>
                        Medical Practitioners
                      </RouterLink>
                      <RouterLink title={"Contact Us"} to="/contact" className={classes.flink}>
                        Contact Us
                      </RouterLink>
                    </Stack>

                    <Stack
                        sx={{
                          [medical.breakpoints.down('md')]: {
                            mb: '20px'
                          },
                        }}
                    >
                      <Typography variant="subtitle2" className={classes.title}>
                        User Information
                      </Typography>
                      <RouterLink title={"Terms of Service"} to="/terms-and-conditions" className={classes.flink}>
                        Terms of Service
                      </RouterLink>
                        <RouterLink title={"Additional Terms for Subscribers "} to="/additional-terms-for-subscribers" className={classes.flink}>
                            Additional Terms for Subscribers
                        </RouterLink>

                      <RouterLink title={"Privacy Policy"} to="/privacy-policy" className={classes.flink}>
                        Privacy Policy
                      </RouterLink>
                      <RouterLink title="Cookie Policy" to="/cookie-policy" className={classes.flink}>
                        Cookie Policy
                      </RouterLink>
                    </Stack>

                    <Stack
                        sx={{
                          [medical.breakpoints.down('md')]: {
                            mb: '30px'
                          },
                        }}
                    >
                      <Typography variant="subtitle2" className={classes.title}>
                        Social Media
                      </Typography>
                      <Stack direction="row" spacing="20px" mt="5px">
                        <Link href="https://www.facebook.com/Medcoremd/" target="_blank">
                          <FooterIcon>
                            <FacebookIcon/>
                          </FooterIcon>
                        </Link>
                        <Link href="https://www.linkedin.com/company/medcore-md" target="_blank">
                          <FooterIcon>
                            <LinkedinIcon/>
                          </FooterIcon>
                        </Link>
                        <Link href="https://www.instagram.com/medcoremd/" target="_blank">
                          <FooterIcon>
                            <InstagramIcon/>
                          </FooterIcon>
                        </Link>
                        <Link href="https://twitter.com/medcoremd" target="_blank">
                          <FooterIcon>
                            <TwitterIcon/>
                          </FooterIcon>
                        </Link>
                      </Stack>
                    </Stack>
                  </Stack>

                </Stack>
            </Container>
        </Container>
    )
}

export default Footer
