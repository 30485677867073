import React, {useEffect, useState} from 'react';
import {AppBar, Box, Button, Container, Drawer, Stack, SvgIcon, Theme} from '@mui/material'
import {makeStyles, useTheme} from '@mui/styles'
import {Link, NavLink} from 'react-router-dom'
import logo from '../../../assets/img/logo.png'
import {ReactComponent as MenuIcon} from '../../../assets/icons/menu-line.svg'
import CloseIcon from '@mui/icons-material/Close'

const useStyles = makeStyles((medical: Theme) => ({
    nlink: {
        fontSize: 22,
        color: medical.colors.blue,
        position: 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '30px',
            height: '2px',
            backgroundColor: '#0F75BC',
            bottom: '-10px',
            left: '50%',
            transform: 'translateX(-50%)'
        },
    },
}));
const NavbarMobile = () => {

    const medical = useTheme()
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <AppBar
            position="sticky"
            sx={{
                backgroundColor:  medical.colors.blue,
                boxShadow: scrolled ? '6px 6px 12px #0000001A' : 'none',
            }}
        >
            <Container
                maxWidth="xl"
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    alignItems: 'center',
                    pt: '10px',
                    pb: '10px'
                }}
            >
                <Link title={"MedcoreMD"} to="/" style={{lineHeight: 0}}>
                    <Box
                        component="img"
                        alt="img"
                        src={logo}
                        sx={{
                            height: 28,
                            width: 122,
                            filter: 'brightness(0) invert(1)',
                        }}>
                    </Box>
                </Link>
                {open ?
                    <Box
                        onClick={() => setOpen(false)}
                        sx={{cursor: 'pointer'}}
                    >
                        <SvgIcon
                            component={CloseIcon}
                            inheritViewBox
                            sx={{
                                '& path': {
                                    width: '25px',
                                    height: '16px',
                                },
                                color: medical.colors.white,
                            }}
                        />
                    </Box>
                    :
                    <Box
                        onClick={() => setOpen(true)}
                        sx={{cursor: 'pointer'}}
                    >
                        <SvgIcon
                            component={MenuIcon}
                            inheritViewBox
                            sx={{
                                width: '25px',
                                height: '16px',
                                color: medical.colors.white,
                                filter: 'brightness(0) invert(1)',
                            }}
                        />
                    </Box>
                }

            </Container>
            <Drawer
                anchor={'bottom'}
                open={open}
                sx={{
                    top: '48px',
                    '& .MuiPaper-root': {
                        top: '48px',
                        boxShadow: 'none'
                    },
                    '& .MuiBackdrop-root': {
                        display: 'none'
                    }
                }}
            >
                <Stack
                    mt="8vh"
                    mb="8vh"
                    justifyContent="space-between"
                    textAlign="center"
                    height="100vh"
                >
                    <NavLink
                        title={"MedcoreMD"}
                        to="/"
                        className={classes.nlink}
                        onClick={() => setOpen(false)}
                    >
                        Home
                    </NavLink>
                    <NavLink
                        title={"Medical Reps"}
                        to="/reps"
                        className={classes.nlink}
                        onClick={() => setOpen(false)}

                    >
                        Medical Reps
                    </NavLink>
                    <NavLink
                        title={"Medical Practitioners"}
                        to="/practitioners"
                        className={classes.nlink}
                        onClick={() => setOpen(false)}

                    >
                        Medical Practitioners
                    </NavLink>
                    <NavLink
                        title={"Sign In"}
                        to="/sign-in"
                        className={classes.nlink}
                        onClick={() => setOpen(false)}

                    >
                        Sign In
                    </NavLink>
                    <NavLink
                            title="Contact Us"
                        to="/contact"
                        className={classes.nlink}
                        onClick={() => setOpen(false)}

                    >
                        Contact Us
                    </NavLink>
                    <Stack direction="row" justifyContent="center" mt="50px" px={"15px"}>
                        <Button title="Request A Demo" size="large" component={Link} to="/demo" onClick={() => setOpen(false)}
                        >
                            Request A Demo
                        </Button>
                        <Button
                            component={Link}
                            title={"Sign Up"}
                            to="/sign-up"
                            size="large"
                            variant="outlined"
                            sx={{ml: '30px'}}
                            onClick={() => setOpen(false)}
                        >
                            Sign Up Free
                        </Button>
                    </Stack>
                </Stack>
            </Drawer>
        </AppBar>
    );
};

export default NavbarMobile;
