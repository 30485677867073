import {createSlice} from '@reduxjs/toolkit';
import {verifyEmail, verifyRequestEmail} from '../actions/authActions/verifyEmailAction';
import {verifyState} from '../types';

const initialState: verifyState = {
    error: null,
    status: null,
    isVerify: false,
    tokenLogin: null,
    tokenVerify: null,
    accessToken: null,
    userID: null,
    email: null,
}

const verifyEmailSlice = createSlice({
    name: 'verify',
    initialState,
    reducers: {
        setTokens: (state, action) => {
            state.tokenVerify = action.payload.tokenVerify
            state.accessToken = action.payload.accessToken
        },
        setEmail: (state, action) => {
            state.email = action.payload.email
        },
        resetEmailVerify: () => initialState
    },
    extraReducers: builder => {
        builder
            .addCase(verifyEmail.pending, (state) => {
                state.isVerify = false
                state.status = 'loading'
                state.error = null
            })
            .addCase(verifyEmail.fulfilled, (state, action) => {
                state.isVerify = true
                state.status = 'resolved'
                state.tokenLogin = action.payload.tokenLogin
                state.userID = action.payload.userID
            })
            .addCase(verifyEmail.rejected, (state, action) => {
                state.isVerify = false
                state.status = 'rejected'
                state.error = action.payload
            })
        builder
            .addCase(verifyRequestEmail.fulfilled, (state) => {
                state.isVerify = false
                state.status = 'resolved'
                state.error = null
            })
    }
})


export const {setTokens, setEmail, resetEmailVerify} = verifyEmailSlice.actions
export default verifyEmailSlice.reducer
